import { gql } from "apollo-boost";


export const GRADE_COURSES = gql`
query gradeCourses(
  $where: CustomGradeWhereInput
  $orderBy: GradeOrderByInput
  $skip: Int
  $after: String
  $before: String
  $first: Int
  $last: Int
  ) {
    grades(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      data{
        id
        faculty {
          id
          name
        }
        department {
          id
          name
        }
        major {
          id
          name
        }
        gradeType{
          name
          score
          maxScore
        }
        student{
          id
          userId
          firstNameL
          lastNameL
          firstNameE
          lastNameE
          role
          yearLevel
          assessmentYear
          email
          phoneMobile
        }
        sendingGradeStatus
        numbericGrade
      }
      total
    }
  }
`;

export const UPDATE_GRADE_COURSE = gql`
  mutation UpdateGradeCourse(
    $data: GradeCourseUpdateInput!
    $where: GradeCourseWhereUniqueInput!
  ) {
    updateGradeCourse(data: $data, where: $where) {
      id
    }
  }
`;

export const SEND_GRADE_COURSE = gql`
  mutation SendGradeCourse(
    $data: sendGradeCourseInput!
    $where: customSendGradeCourseWhereInput!
  ) {
    sendGradeCourse(data: $data, where: $where) {
      id
    }
  }
`;

export const GRADES = gql`
  query Grades(
    $where: CustomGradeWhereInput
    $orderBy: GradeOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    grades(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      data {
        id
        student {
          id
          studentNumber
          gender
          firstNameL
          lastNameL
          firstNameE
          lastNameE
        }
        course {
          id
          title
          courseCode
          detail
          year
          description
          department {
            id
            name
            enName
          }
          faculty {
            id
            name
            enName
            description
          }
          gradeType {
            name
            detail
            score
            maxScore
          }
        }
        numberTest
        gredeStatus
        sendingGradeStatus
        semester
        yearLevel
        numbericGrade
        letterGrade
        gradeType {
          name
          detail
          note
          score
          maxScore
        }
        note
        createdBy
        createdAt
        updatedAt
        updatedBy 
      }
      total
    }
  }
`;


export const GRADES_REQUESTING = gql`
  query Grades(
    $where: CustomGradeWhereInput
    $orderBy: GradeOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    grades(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      data {
        id
        yearLevel
        student {
          id
          userId
          firstNameL
          lastNameL
          firstNameE
          lastNameE
          yearLevel
          assessmentYear
        }
        course {
          id
          title
          unit
          year
          semester
          assessmentYear
          faculty{
            name
          }
          department{
            name
          }
          teacher {
            id
            firstname
            lastname
          }
        }
        gredeStatus
        sendingGradeStatus
        semester
        assessmentYear
        letterGrade
        note
      }
      total
    }
  }
`;

export const GRADE_LIST = gql`
  query Grades {
    grades {
      data {
        id
        registration
        student {
          id
          gender
          studentNumber
          firstNameL
          lastNameL
          firstNameE
          lastNameE
        }
        teacher {
          id
        }
        course {
          id
          title
          detail
          year
          description
          department {
            id
            name
            enName
            status
            faculty {
              id
              name
              enName
              description
            }
          }
          gradeType {
            name
            detail
            score
            maxScore
          }
        }
        semester
        year
        gredeStatus
        sendingGradeStatus
        numericGrade
        letterGrade
        gradeType {
          name
          detail
          note
          score
          maxScore
        }
        note
        status
        isFailed
        createdBy {
          id
        }
        createdAt
        updatedAt
        updatedBy {
          id
        }
      }
      total
    }
  }
`;
export const UPDATE_GRADE = gql`
  mutation UpdateGrade(
    $data: CustomGradeWhereInput!
    $where: GradeWhereUniqueInput!
  ) {
    updateGrade(data: $data, where: $where) {
      id
    }
  }
`;

export const APPROVE_GRADE = gql`
  mutation ApproveGrade(
    $where: GradeWhereUniqueInput!
  ) {
    approveGrade(where: $where) {
      id
    }
  }
`;

export const REJECT_GRADE = gql`
  mutation RejectGrade(
    $where: GradeWhereUniqueInput!
  ) {
    rejectGrade(where: $where) {
      id
    }
  }
`;

// Add new for grade
export const DEPARTMENT_NAME = gql`
  query Departments($where: CustomDepartmentWhereInput) {
    departments(where: $where) {
      id
      name
      enName
      description
    }
  }
`;
export const FACULTY_NAME = gql`
  query Faculty {
    faculties {
      id
      name
      description
      departments {
        id
        name
        enName
      }
    }
  }
`;

export const FACULTIES = gql`
  query Faculties(
    $where: FacultyWhereInput
    $orderBy: FacultyOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    faculties(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      id
      name
      departments {
        id
        name
      }
      description
      status
      startDateRegister
      endDateRegister
    }
  }
`;
export const FACULTIE = gql`
  query FacultiesQueryShow {
    facultyQueryShow {
      fac_id
      fac_name
    }
  }
`;
