import React from "react";
import { useLocation } from "react-router-dom";


function PDFDisplay() {
  const location = useLocation();
  const data = location.state;

  // Check if data exists and contains a link
  if (!data || !data.link) {
    return <div>No PDF link provided</div>;
  }

  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <object
        data={data.link}
        type="application/pdf"
        width="100%"
        height="500px"
      >
        <p>
          This browser does not support PDFs. Please download the PDF to view
          it: <a href={data.link}>Download PDF</a>
        </p>
      </object>
    </div>
  );
}

export default PDFDisplay;
