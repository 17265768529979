import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Form, Row, Col, ProgressBar } from "react-bootstrap";
import { useMutation, useLazyQuery, useQuery } from "@apollo/react-hooks";
import { useDropzone } from "react-dropzone";
import { USER_KEY } from "../../consts/user";
import { useSelector } from 'react-redux'
import axios from "axios";
// component ------------------------------>
// import Loading from "../../common/Loading";
// constance ------------------------------>
import Consts from "../../consts";
import { CREATE_FILE ,TEACHER_COURSES } from "../../apollo/file";
import { PRE_SIGNED_URL } from "../../apollo/filePDFUpload";
const CourseDocUpload = ({
  courseDocUploadModal,
  _handlCourseDocUploadModalClose,
  courseId,
}) => {
  const accessmentRedux = useSelector((state) => state.accessment.value);
  const user = JSON.parse(localStorage.getItem(USER_KEY));

  // state ----------------------------------------------------->
  const [errFile, setErrFile] = useState("");
  const [files, setFiles] = useState([]);
  const [fileUploadProgress, setFileUploadProgress] = useState(0);
  const [title, setTitle] = useState("");
  const [fileType, setFileType] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileNameErr, setFileNameErr] = useState(false);
  const [classRoom, setClassRoom] = useState([""]);
  const [classRoomes, setClassRoomes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // apollo ------------------------------------------->
  // const { data: teacherCourses } = useQuery(TEACHER_COURSES);
  const [loadClass, { data: classes }] = useLazyQuery(TEACHER_COURSES, {
    variables:{ where:{ assessmentYear: accessmentRedux } }
  });
  const [loadFile, { data }] = useLazyQuery(PRE_SIGNED_URL, {
    variables: { mimeType: fileType, fileName: fileName },
  });
  const [createFile] = useMutation(CREATE_FILE);
  // functional -------------------------------------------------->

  const onDrop = useCallback(async (acceptedFiles) => {
    // TODO: store files in state
    setFiles(acceptedFiles);
    let filePath = acceptedFiles[0].path.split(".");
    let _fileType;
    if (
      filePath[filePath.length - 1] === "jpg" ||
      filePath[filePath.length - 1] === "png"
    ) {
      _fileType = "image/" + filePath[filePath.length - 1];
    } else {
      _fileType = "application/" + filePath[filePath.length - 1];
    }
    setFileName(filePath[filePath.length - 2]);
    setFileType(_fileType);
  }, []);

  useEffect(() => {
    loadFile();
  }, [fileType, fileName]);

  useEffect(()=>{
    loadClass()
  },[])
  useEffect(()=> {
    if(classes?.teacherCourses){
      const unquie = removeDuplicates(classes?.teacherCourses, 'id');
      setClassRoomes(unquie)
    }
  }, [classes])

  function removeDuplicates(arr, key) {
    const seen = new Set();
    return arr.filter(item => {
      console.log("item: " , item)
      const itemKey = item[key];
      if (!seen.has(itemKey)) {
        seen.add(itemKey);
        return true;
      }
      return false;
    });
  }

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    onDrop,
  });

  const _fileUploaded = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} ຂະຫນາດ: {file.size} bytes
    </li>
  ));

  const _uploadFile = async (param) => {
    try {
      setIsLoading(true);
  
      if (title === "") {
        setFileNameErr(true);
      }
  
      const { preSignedUrl } = data;
  
      // Save files to S3
      if (acceptedFiles.length > 0 && preSignedUrl) {
        const uploadPromises = acceptedFiles.map(async (file) => {
          const response = await axios({
            method: "put",
            url: preSignedUrl.url,
            data: file,
            headers: {
              "Content-Type": file.type,
              // Add other headers if needed
            },
            onUploadProgress: function (progressEvent) {
              setFileUploadProgress(
                parseInt(
                  Math.round((progressEvent.loaded * 100) / progressEvent.total)
                )
              );
            },
          });
  
          let filename = response.config.url.split("?");
  
          let data = {
            file: filename[0],
            title: title,
            department: user?.data?.department?.id,
            faculty: user?.data?.faculty?.id,
            classRoom: classRoom,
          };
  
          let paramQL = {
            data: {
              title,
              ...data,
              type: "COURSE_FILE",
              course: courseId,
            },
          };
  
          return createFile({ variables: paramQL });
        });
  
        // Wait for all uploads to complete
        const results = await Promise.all(uploadPromises);
  
        // Check if all uploads were successful
        const allUploadsSuccessful = results.every((result) => result);
  
        if (allUploadsSuccessful) {
          setIsLoading(false);
          window.location.reload(true);
        }
      } else {
        setErrFile("ກະລຸນາຕື່ມໄຟລ!");
      }
    } catch (err) {
      console.log("err---->", err);
    }
  };
  

  const _onRemoveFile = () => {
    for (let i = 0; i < acceptedFiles.length; i++) {
      acceptedFiles.pop();
    }
    setFileName("");
    setFileType("");
  };

  const onKeyTitle = (e) => {
    let _title = e?.target?.value;
    setTitle(_title);
  };

  const handleCheck = (e)=>{
    let newArray = [];
    if(classRoom.length===0){
      newArray.push(e.target.value);
      setClassRoom(newArray);
    }else{
      const filter = classRoom.filter((el)=> el === e.target.value);
      if(filter.length===0){
        // console.log("No filter")
        setClassRoom([...classRoom, e.target.value]);
      }else{
        // console.log("Filter matched: " + filter)
        const filtered = classRoom.filter((el)=> el !== e.target.value);
        setClassRoom([...filtered]);
      }
      // console.log("Checking", e.target.value);
      // setClassRoom([...classRoom, e.target.value]);
      // console.log("Checking", classRoom);
    }
  }

  // if (isLoading) return <Loading />;

  return (
    <div>
      <Modal
        show={courseDocUploadModal}
        onHide={_handlCourseDocUploadModalClose}
        size="lg"
      >
        <Modal.Title style={{ textAlign: "center", paddingTop: 20 }}>
          ອັບໂຫລດບົດສອນ
        </Modal.Title>
        <Modal.Body style={{ marginLeft: 50, marginRight: 50, padding: 50 }}>
          {/* file updload box */}
          <div style={{ border: "1px solid #eee", padding: 20, width: "100%" }}>
            {/* ຫົວຂໍ້ */}
            <Form.Group
              as={Row}
              style={{
                margin: 0,
                marginBottom: 10,
                paddingLeft: 20,
                fontSize: 16,
              }}
            >
              <Form.Label column sm="4" className="text-left">
                ຫົວຂໍ້
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="text"
                  placeholder="ກະລຸນາປ້ອນ"
                  style={{ borderRadius: 0 }}
                  // value={title}
                  // onChange={(e) => setTitle(e.target.value)}
                  onChange={(e) => onKeyTitle(e)}
                />

                <div style={{ color: "red" }}>
                  <p>{!fileNameErr ? "" : "ກະລຸນາຂຽນຫົວຂໍ້"}</p>
                </div>
              </Col>
            </Form.Group>
            {/* ຫົວຂໍ້ */}
            <Form.Group
              as={Row}
              style={{
                margin: 0,
                marginBottom: 10,
                paddingLeft: 20,
                fontSize: 16,
              }}
            >
              <Form.Label column sm="4" className="text-left">
                ຫ້ອງຮຽນ
              </Form.Label>
              <Col sm="8">
              {classRoomes && classRoomes.length >0 && classRoomes.map((room,index)=>{
                  return <Form.Check
                    key={index}
                    type="checkbox"
                    className="text-left"
                    value={room.id}
                    label={ room.name }
                    checked={classRoom && classRoom.includes(room.id)}
                    onChange={handleCheck}
                  ></Form.Check>
                })}
              </Col>
            </Form.Group>

            {/* ອັບໂຫລດໄຟລ */}
            <Form.Group
              as={Row}
              style={{
                margin: 0,
                marginBottom: 10,
                paddingLeft: 20,
                fontSize: 16,
              }}
            >
              <Form.Label column sm="4" className="text-left">
                ອັບໂຫລດໄຟລ
              </Form.Label>
              <Col sm="8">
                <div
                  {...getRootProps()}
                  style={{
                    height: 100,
                    border: "1px solid #ddd",
                    outline: "none",
                    cursor: "pointer",
                    textAlign: "center",
                  }}
                >
                  <input {...getInputProps()} />

                  <div
                    style={{
                      backgroundColor: "#f3f3f3",
                      marginLeft: 20,
                      marginTop: 20,
                      marginRight: 20,
                      textAlign: "center",
                    }}
                    className="mb-4"
                  >
                    <img
                      alt="work right"
                      style={{ width: 50, height: 50 }}
                      src="/assets/download.png"
                    />
                  </div>
                  {/* <span>Drag and drop or Browse</span> */}
                  {acceptedFiles.length === 0 && errFile ? (
                    <p style={{ color: "red", fontSize: 14, marginTop: 10 }}>
                      {errFile}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <br />
                {acceptedFiles.length > 0 && (
                  <aside>
                    <h4>ໄຟລທີ່ຈະອັບໂຫລດ</h4>
                    <ul>{_fileUploaded}</ul>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button onClick={_onRemoveFile} variant={"danger"}>
                        ລົບໄຟລ
                      </Button>
                    </div>
                  </aside>
                )}
                {fileUploadProgress > 0 && fileUploadProgress < 100 && (
                  <div>
                    <h3>ກໍາລັງອັບໂຫລດໄຟລເອກະສານ....</h3>
                    <ProgressBar
                      animated
                      now={fileUploadProgress}
                      label={`${fileUploadProgress}%`}
                    />
                  </div>
                )}
              </Col>
            </Form.Group>
          </div>

          <div style={{ height: 20 }} />
          <div className="row" style={{ textAlign: "center" }}>
            <div style={{ padding: 15 }} className="col">
              <Button
                onClick={_handlCourseDocUploadModalClose}
                style={{
                  width: "60%",
                  backgroundColor: "#fff",
                  color: Consts.PRIMARY_COLOR,
                  borderColor: Consts.PRIMARY_COLOR,
                  borderRadius: 0,
                }}
              >
                ຍົກເລີກ
              </Button>
            </div>
            <div style={{ padding: 15 }} className="col">
              <Button
                style={{
                  width: "60%",
                  backgroundColor: Consts.PRIMARY_COLOR,
                  color: "#fff",
                  borderColor: Consts.PRIMARY_COLOR,
                  borderRadius: 0,
                }}
                onClick={_uploadFile}
              >
                ສ້າງ
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

CourseDocUpload.propTypes = {
  _handlCourseDocUploadModalClose: PropTypes.func.isRequired,
  courseDocUploadModal: PropTypes.bool.isRequired,
};
export default CourseDocUpload;
