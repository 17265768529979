import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Spinner, OverlayTrigger, Tooltip } from "react-bootstrap";

import { Breadcrumb, Pagination } from "react-bootstrap";
import Consts from "../../consts";
import { CustomContainer, Title, TableHeader, TableCell } from "../../common";
import Empty from "./Empty";

import { formatDateTime, noticeStatus, sc } from "../../common/super";
import { useLazyQuery } from "@apollo/react-hooks";
import { NOTICS } from "../../apollo/notic";

function NoticeList() {
  const { history, match } = useReactRouter();
  const numberPage = match?.params?.page ?? 1;
  const NUMBER_LIMIT_PAGE = 10;

  // state for search
  const [typeNotice, setTypeNotice] = useState("");
  const [isGrid, setIsGrid] = useState(false);


  const [loadNotic, { loading: noticLoading, data: noticData }] = useLazyQuery(NOTICS, {
    variables: {
      skip: NUMBER_LIMIT_PAGE * (numberPage - 1),
      first: NUMBER_LIMIT_PAGE,
      orderBy: "createdAt_DESC",
    },
  });

  useEffect(() => {
    loadNotic()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (typeNotice !== "") {
      loadNotic({
        variables: {
          where: {
            type: typeNotice
          },
          skip: NUMBER_LIMIT_PAGE * (numberPage - 1),
          first: NUMBER_LIMIT_PAGE,
          orderBy: "createdAt_DESC",
        },
      })
    }
    else {
      loadNotic({
        variables: {
          skip: NUMBER_LIMIT_PAGE * (numberPage - 1),
          first: NUMBER_LIMIT_PAGE,
          orderBy: "createdAt_DESC",
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeNotice])

  const docCount = noticData?.notics?.total;
  const countPage = [];
  for (var i = 1; i <= Math.ceil(docCount / NUMBER_LIMIT_PAGE); i++) {
    countPage.push(i);
  }
  const _onBack = () => {
    if (parseInt(numberPage) - 1 < 1) {
      history.push(`/notice-list/${1}`);
    } else {
      history.push(`/notice-list/${parseInt(numberPage) - 1}`);
    }
  };
  const _onNext = () => {
    if (parseInt(numberPage) + 1 > countPage.length) {
      history.push(`/notice-list/${countPage.length}`);
    } else {
      history.push(`/notice-list/${parseInt(numberPage) + 1}`);
    }
  };

  // set state menu item
  const _allType = (e) => {
    setTypeNotice(e);
  };
  const _notice = (e) => {
    setTypeNotice(e);
  };
  const _agreeMent = (e) => {
    setTypeNotice(e);
  };
  const _regulation = (e) => {
    setTypeNotice(e);
  };
  const _displayStyle = () => {
    setIsGrid(!isGrid);
  };
  return (
    <div>
      {/* Breadcrumb */}
      <Breadcrumb>
        <Breadcrumb.Item active>ການແຈ້ງການ ແລະ ເຜີຍແຜ່ລະບຽບການ</Breadcrumb.Item>
      </Breadcrumb>
      <CustomContainer>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Title
            text="ການແຈ້ງການ ແລະ ເຜີຍແຜ່ລະບຽບການ"
          />
          <OverlayTrigger
            overlay={
              <Tooltip id="tooltip-disabled">
                ປ່ຽນມູມມອງ
              </Tooltip>
            }
          >
            <FontAwesomeIcon
              style={{ cursor: "pointer" }}
              onClick={_displayStyle}
              icon={["fas", isGrid === true ? "list" : "th-large"]}
            />
          </OverlayTrigger>
        </div>

        {/* custom search button */}
        {/* <SearchBar title="ຄຳສັບ" onClick={() => _handleSearchViewShow()} /> */}
        {/* muad */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            marginTop: 30,
          }}
        >
          <div style={{ width: 80, color: "#057CAE", fontWeight: "bold" }}>
            ຫມວດ
          </div>
          <div
            className={typeNotice === "" ? "menuItem active-menu" : "menuItem"}
            onClick={() => _allType("")}
          >
            ທັງຫມົດ
          </div>
          <div
            className={
              typeNotice === "NOTIC" ? "menuItem active-menu" : "menuItem"
            }
            onClick={() => _notice("NOTIC")}
          >
            ແຈ້ງການ
          </div>
          <div
            className={
              typeNotice === "AGREEMENT" ? "menuItem active-menu" : "menuItem"
            }
            onClick={() => _agreeMent("AGREEMENT")}
          >
            ຂໍ້ຕົກລົງ
          </div>
          <div
            className={
              typeNotice === "REGULATION" ? "menuItem active-menu" : "menuItem"
            }
            onClick={() => _regulation("REGULATION")}
          >
            ລະບຽບການ
          </div>
          <div
            className={
              typeNotice === "NEWS" ? "menuItem active-menu" : "menuItem"
            }
            onClick={() => _regulation("NEWS")}
          >
            ໜັງສືພິມ
          </div>
        </div>

        {/* ເອກະສານທັງຫມົດ */}
        <div
          style={{
            marginTop: 24,
            marginBottom: 8,
            fontSize: 16,
            color: Consts.FONT_COLOR_SECONDARY,
          }}
        >
          ທັງຫມົດ {noticData?.notics_page?.total}
        </div>

        {/* Table list */}
        <div>
          {noticLoading ? (
            <Spinner animation="border" style={{ color: "#057CAE" }} />
          ) : (
            <div>
              <div style={{ display: isGrid === true ? "none" : "block" }}>
                <table border="1" bordercolor="#fff" style={{ width: "100%" }}>
                  <thead>
                    <TableHeader>
                      <th style={{ width: 50 }}>ລຳດັບ</th>
                      <th style={{ width: 95 }}>ຮູບພາບ</th>
                      <th>ຫົວຂໍ້</th>
                      <th style={{ width: 150 }}>ປະເພດ</th>
                      <th>ສ້າງ</th>
                      <th>ອັບເດດ</th>
                      <th style={{ width: 200 }}>ຈັດການ</th>
                    </TableHeader>
                  </thead>
                  <tbody>

                    {noticData?.notics?.total > 0 ? (noticData?.notics?.data?.map((x, index) => {
                      return (
                        <tr
                          style={{
                            borderBottom: "2px solid #ffff",
                            textAlign: "center",
                          }}
                          key={index}
                        >
                          <TableCell style={{ width: "10px" }}>
                            {index + 1}
                          </TableCell>
                          <TableCell>
                            <img
                              alt=""
                              src={
                                x.coverImage?.url
                                  ? x.coverImage?.url
                                  : "https://www.cakemandu.com.np/public/uploads/items/thumbs/default.png"
                              }
                              className="box-image"
                            />
                          </TableCell>
                          <TableCell
                            style={{ textAlign: "left", paddingLeft: 10 }}
                          >
                            <a className="title">{x?.title}</a>
                          </TableCell>
                          <TableCell>{noticeStatus(x.type)}</TableCell>
                          <TableCell style={{ width: 200 }}>
                            {formatDateTime(x.createdAt)}
                            <br />( ໂດຍ: {x?.createdBy?.firstname}{" "}
                            {x?.createdBy?.lastname
                              ? x?.createdBy?.lastname
                              : ""}{" "}
                            )
                          </TableCell>
                          <TableCell style={{ width: 200 }}>
                            {formatDateTime(x.updatedAt)}
                            <br />( ໂດຍ: {x?.updatedBy?.firstname}
                            {x?.updatedBy?.lastname
                              ? x?.updatedBy?.lastname
                              : ""}{" "}
                            )
                          </TableCell>
                          <TableCell style={{ width: 200 }}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-around",
                                width: 200,
                              }}
                            >
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    ເບິ່ງລາຍລະອຽດເພີ່ມເຕີມ
                                  </Tooltip>
                                }
                              >
                                <div
                                  onClick={() =>
                                    history.push(
                                      `/notice-detail/${x.id}/${x.type}`
                                    )
                                  }
                                  style={{
                                    cursor: "pointer",
                                    backgroundColor: "#FFFFFF",
                                    padding: 3,
                                    width: 64,
                                    borderRadius: 4,
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={["fas", "eye"]}
                                    color="#7BB500"
                                  />
                                </div>
                              </OverlayTrigger>
                            </div>
                          </TableCell>
                        </tr>
                      );
                    })) : (
                      <tr>
                        <td colSpan="7">
                          <Empty />
                        </td>
                      </tr>
                    )}


                  </tbody>
                </table>
              </div>
              <div
                style={{
                  display: isGrid === true ? "block" : "none",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    // justifyContent: "center",
                  }}
                >
                  {/* <div style={{ display: "flex", flexWrap: "wrap",backgroundColor:'red' }}> */}
                  {noticData?.notics?.data?.map((x, index) => {
                    return (
                      <div
                        onClick={() =>
                          history.push(`/notice-detail/${x.id}/${x.type}`)
                        }
                        key={index}
                        style={{
                          backgroundColor: "#ffffff",
                          width: 230,
                          border: "1px solid #ddd",
                          borderRadius: 5,
                          marginBottom: 10,
                          cursor: "pointer",
                          marginRight: 8,
                        }}
                      >
                        <div
                          style={{
                            height: 171,
                            overflow: "hidden",
                            display: "flex",
                            alignItems: "center",
                            backgroundColor: "#EAEAEA",
                          }}
                        >
                          <img
                            alt=""
                            style={{ width: "100%" }}
                            src={
                              x.coverImage?.url
                                ? x.coverImage?.url
                                : "https://www.cakemandu.com.np/public/uploads/items/thumbs/default.png"
                            }
                          />
                        </div>
                        <div style={{ padding: 10 }}>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                              fontSize: Consts.FONT_SIZE,
                            }}
                          >
                            <a>
                              ສ້າງໂດຍ:{x?.createdBy?.firstname}{" "}
                              {x?.createdBy?.lastname
                                ? x?.createdBy?.lastname
                                : ""}
                            </a>
                            <a>{noticeStatus(x.type)}</a>
                          </div>
                          <a
                            className="title"
                            style={{
                              color: Consts.FONT_COLOR_PRIMARY,
                              fontWeight: "bolder",
                            }}
                          >
                            {x?.title}
                          </a>
                          {/* <br /> */}
                          <a style={{ fontSize: Consts.FONT_SIZE }}>
                            {formatDateTime(x.createdAt)}
                          </a>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            // </div>
          )}
        </div>
        <Pagination
          style={{ display: "flex", justifyContent: "center", marginTop: 20 }}
        >
          <Pagination.Prev onClick={_onBack} />
          {countPage.map((Item, index) => {
            return (
              <Pagination.Item
                key={Item}
                active={Item === numberPage}
                onClick={() => history.push(`/notice-list/${Item}`)}
              >
                {Item}
              </Pagination.Item>
            );
          })}

          <Pagination.Next onClick={_onNext} />
        </Pagination>
      </CustomContainer>
    </div>
  );
}

export default NoticeList;
