import React, { useEffect, useState } from "react";
import { Typography, Menu, MenuItem } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { API_KEY, NEW_API_URL } from "../../common/constraint";
import RenameDialog from "./RenameDialog";
import AlertMessage from "../../common/AlertMessage";
import ManageAccessDialog from "./MangeAccessDialog";
import { USER_KEY } from "../../consts/user";
import DetailDialog from "./DetailDialog";

const Folder = ({ folderName, date, id, refetch, data }) => {
  const history = useHistory();
  const [contextMenuAnchor, setContextMenuAnchor] = useState(null);
  const [showRename, setShowRename] = useState(false);
  const [showManageAccess, setShowManageAccess] = useState(false);
  const [showDetail, setShowDetail] = useState(false);

  const user = localStorage.getItem(USER_KEY);
  const userObject = JSON.parse(user);
  const token = userObject?.accessToken;

  const [actionStatus, setActionStatus] = useState({
    open: false,
    message: "ປ່ຽນຊື່ສຳເລັດ",
    type: "success",
  });

  useEffect(() => {
    if (actionStatus.open) {
      setTimeout(() => {
        setActionStatus((predata) => ({
          ...predata,
          open: false,
        }));
      }, 2000);
    }
  }, [actionStatus.open]);

  const handleClick = () => {
    if (!contextMenuAnchor) {
      const currentPath = history.location.pathname;
      const newPath = `${currentPath}/${id}`;
      history.push(newPath);
    }
  };

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenuAnchor(event.currentTarget);
  };

  const handleCloseContextMenu = () => {
    setContextMenuAnchor(null);
  };

  const handleContextMenuAction = async (action) => {
    if (action === "DELETE") {
      await deleteFolder();
    }
    handleCloseContextMenu();
  };

  const deleteFolder = async () => {
    try {
      await axios.delete(NEW_API_URL + "filefolder/?folderId=" + id, {
        headers: {
          api_key: API_KEY,
          Authorization: `Bearer ${token}`,
        },
      });
      refetch();
    } catch (err) {}
  };

  const truncateFolderName = (name, maxLength) => {
    if (name.length > maxLength) {
      return name.substring(0, maxLength) + "...";
    }
    return name;
  };

  return (
    <div
      onClick={handleClick}
      onContextMenu={handleContextMenu}
      className="d-flex flex-column align-items-center col-lg-2 col-md-3 col-sm-4 folder"
      style={{ position: "relative" }}
    >
      <DetailDialog
        onCancel={() => {
          setShowDetail(false);
          handleCloseContextMenu();
        }}
        open={showDetail}
        data={data}
      />
      <ManageAccessDialog
        type={"FOLDER"}
        id={id}
        onCancel={() => {
          setShowManageAccess(false);
          setContextMenuAnchor(null);
        }}
        open={showManageAccess}
      />
      <AlertMessage
        open={actionStatus?.open}
        message={actionStatus?.message}
        type={actionStatus?.type}
      />

      <RenameDialog
        onSubmit={(success) => {
          if (success) {
            setActionStatus({
              open: true,
              message: "ປ່ຽນຊື່ສຳເລັດ",
              type: "success",
            });
            setShowRename(false);
            refetch();
          } else {
            setActionStatus({
              open: true,
              message: "ມີບັນຫາໃນການປ່ຽນຊື່​",
              type: "error",
            });
          }
          setContextMenuAnchor(null);
        }}
        onCancel={() => {
          handleCloseContextMenu(null);
          setShowRename(false);
        }}
        type={"FOLDER"}
        id={id}
        message={"ຊື່ Folder ໃຫມ່"}
        open={showRename}
      />

      <img
        width="48"
        height="48"
        src="https://img.icons8.com/color/48/folder-invoices--v1.png"
        alt="folder-invoices--v1"
      />

      <Typography>{truncateFolderName(folderName, 15)}</Typography>
      <Typography
        style={{
          fontSize: "14px",
        }}
      >
        {"ສ້າງວັນທີ : " + new Date(date).toLocaleDateString()}
      </Typography>

      <Menu
        anchorEl={contextMenuAnchor}
        open={Boolean(contextMenuAnchor)}
        onClose={handleCloseContextMenu}
      >
        <MenuItem onClick={() => setShowDetail(true)}>ລາຍລະອຽດ</MenuItem>
        <MenuItem onClick={() => setShowManageAccess(true)}>
          ຈັດການສິດການເຂົ້າເຖີງ
        </MenuItem>
        <MenuItem
          onClick={() => {
            setShowRename(true);
          }}
        >
          ປ່ຽນຊື່
        </MenuItem>
        <MenuItem onClick={() => handleContextMenuAction("DELETE")}>
          ລົບ
        </MenuItem>
      </Menu>
    </div>
  );
};

export default Folder;
