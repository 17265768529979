import { IconButton } from "@material-ui/core";
import React, { useEffect, useState, useRef } from "react";
import CreateFoloderDialog from "./CreateFoloderDialog";
import AlertMessage from "../../common/AlertMessage";
import axios from "axios";
import { NEW_API_URL, API_KEY } from "../../common/constraint";
import { useHistory } from "react-router-dom";
import LoadingDialog from "../../common/LoadingDialog";
import { USER_KEY } from "../../consts/user";

const TopBar = ({ refetch }) => {
  const [openAdd, setOpenAdd] = useState();
  const [actionStatus, setOnActionStatus] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const user = localStorage.getItem(USER_KEY);
  const userObject = JSON.parse(user);
  const token = userObject?.accessToken;

  useEffect(() => {
    if (actionStatus?.open) {
      setTimeout(() => {
        setOnActionStatus((preData) => ({
          ...preData,
          open: false,
        }));
      }, 3000);
    }
  }, [actionStatus]);

  const handleFileChange = (event) => {
    handleUpload(event.target.files);
  };

  const currentPath = history.location.pathname;
  const urlParts = currentPath.split("/");
  const folderId = urlParts[urlParts.length - 1];

  const handleUpload = async (selectedFiles) => {
    try {
      setIsLoading(true);

      const currentPath = history.location.pathname;
      const urlParts = currentPath.split("/");
      const folderId = urlParts[urlParts.length - 1];

      const formData = new FormData();
      formData.append("folderId", folderId);

      for (let i = 0; i < selectedFiles.length; i++) {
        formData.append("files", selectedFiles[i]);
        formData.append("fileNames", selectedFiles[i]?.name);
      }

      await axios.post(NEW_API_URL + "course/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          api_key: API_KEY,
          Authorization: `Bearer ${token}`,
        },
      });
      setIsLoading(false);
      refetch();
    } catch (error) {
      setIsLoading(false);
      console.error("Error uploading files:", error);
    }
  };

  const fileInputRef = useRef(null);

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="mb-1 d-flex  align-items-end justify-content-end bg-white pb-3">
      <LoadingDialog open={isLoading} message={"ກຳລັງອັບໂຫລດ..."} />
      <div className="col-1 d-flex  align-items-end text-end">
        <IconButton onClick={() => setOpenAdd(true)}>
          <img
            directory=""
            webkitdirectory=""
            width="40"
            height="40"
            style={{
              padding: "8px",
            }}
            src="https://img.icons8.com/fluency/48/add-folder.png"
            alt="add-folder"
          />
        </IconButton>
        {folderId != 1 && (
          <>
            <IconButton onClick={handleIconClick}>
              <img
                style={{
                  padding: "8px",
                }}
                width="40"
                height="40"
                src="https://img.icons8.com/fluency/48/add-file.png"
                alt="add-file"
              />
            </IconButton>
            <input
              type="file"
              onChange={handleFileChange}
              multiple
              ref={fileInputRef}
              style={{ display: "none" }}
            />
          </>
        )}
      </div>
      <AlertMessage
        type={actionStatus?.type}
        open={actionStatus?.open || false}
        message={actionStatus?.message || ""}
      />
      {openAdd && (
        <CreateFoloderDialog
          onSubmit={(success) => {
            if (success) {
              setOnActionStatus({
                message: "ສ້າງສຳເລັດ",
                type: "success",
                open: true,
              });
            } else {
              setOnActionStatus({
                message: "ເກີດຂໍ້ຜິດພາດໃນການດຳເນີນການ",
                type: "failed",
                open: true,
              });
            }
            // refetch folders or files after finished
            if (refetch) {
              refetch();
            }
            setOpenAdd(false);
          }}
          onCancel={() => {
            setOpenAdd(false);
          }}
        />
      )}
    </div>
  );
};

export default TopBar;
