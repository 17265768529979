import { gql } from "apollo-boost";

export const GRADES = gql`
query Grades(
  $where: CustomGradeWhereInput
  $skip: Int
  $first: Int
  $last: Int
) {
  grades(
    where: $where
    skip: $skip
    first: $first
    last: $last
  ) {
    data {
      id
      yearLevel
      semester
      gredeStatus
      student {
        id
        userId
        firstNameL
        lastNameL
        yearLevel
        assessmentYear
      }
      course {
        id
        title
        teacher {
          id
          firstname
          lastname
        }
      }
    }
    total
  }
}
`;

export const GRADES_FOR_TEACHER = gql`
query Grades(
  $where: CustomGradeWhereInput
  $skip: Int
  $first: Int
  $last: Int
) {
  grades(
    where: $where
    skip: $skip
    first: $first
    last: $last
  ) {
    data {
      id
      yearLevel
      semester
      gredeStatus
      student {
        id
        userId
        firstNameL
        lastNameL
        yearLevel
        assessmentYear
      }
      course {
        id
        title
      }
    }
    total
  }
}
`;

export const QUERY_FACULTY = gql`
  query Faculties(
    $where: CustomFacultyWhereInput
  ) {
    faculties(
      where: $where
    ) {
      id
      name
    }
  }
`;

export const QUERY_DEPARTMENT = gql`
  query Departments(
    $where: CustomDepartmentWhereInput
  ) {
    departments(
      where: $where
    ) {
      id
      name
    }
  }
`;

export const COURSES = gql`
  query Courses(
    $where: CourseWhereInput
  ) {
    courses(
      where: $where
    ) {
      id
      courseCode
      moodleCourseId
      title
      faculty{
            id
            name
        }
      department{
            id
            name
        }
    }
  }
`;