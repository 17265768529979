import { gql } from "apollo-boost";

export const CLASSROOM_MASTERS = gql`
  query ClassRooms(
    $where: ClassroomInput
    $orderBy: OrderDataInput
  ) {
    classRooms(
      where: $where
      orderBy: $orderBy
    ) {
      data {
        id
        name
        faculty{
          id
          name
        }
        department{
          id
          name
        }
        major{
          id
          name
        }
        year
        note
        createdAt
        updatedAt
      }
    }
  }
`;