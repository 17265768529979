import React from "react";
import File from "./File";

export const FileList = ({ fileList, refetch }) => {
  return (
    <div className="row">
      {fileList.map((file) => (
        <File
          refetch={() => {
            refetch();
          }}
          data={file}
          id={file?._id}
          fileName={file?.fileName}
          date={file?.createdAt}
          fileLink={file?.fileLink}
        />
      ))}
    </div>
  );
};

export default FileList;
