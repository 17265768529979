import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";
import { Form, Row, Pagination } from "react-bootstrap";
import Consts from "../../consts";
import { CustomContainer,TableHeader } from "../../common";
import { Formik } from "formik";
import Notiflix from "notiflix";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import {
  GRADES,
  FACULTY_NAME,
  DEPARTMENT_NAME,
  UPDATE_GRADE,
} from "../../apollo/grade/index";
import { USER_KEY } from "../../consts/user";
import { CALL_COURSE_DATA } from "../../apollo/course/index";
// import ExcelReader from './ExcelReader'
import Loading from "../../common/Loading";
import EditGrade from "./EditGrade";

import {
  setGenders,
  convertLetterGrade,
} from "../../common/super";

function AddGradeForStudent() {
  const { history, location, match } = useReactRouter();
  const data = location.state;
  const [newData, setNewData] = useState();
  // user data
  const [role, setRole] = useState(null);
  const [teacherId, setTeacherId] = useState("");
  const [teacherName, setTeacherName] = useState("");

  // pagination
  const pageNambers = match?.params?.page;
  const PAGE_LIMIT = 10;
  const [countPage, setCountPage] = useState();

  const [showEdit, setShowEdit] = useState(false);
  const [gradeData, setGradeData] = useState();
  const [checkStatus, setCheckStatus] = useState([]);
  const [whenClick, setWhenClick] = useState([]);

  const [confirmGrade, setConfirmGrdae] = useState(false);

  // Upudate grade
  const [updateGrade] = useMutation(UPDATE_GRADE);
  // check role
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem(USER_KEY));
    setRole(user?.data?.role);
    setTeacherId(user?.data?.id);
    setTeacherName(user?.data?.firstname);
  }, []);
  useEffect(() => {
    setNewData(data);
  }, [data]);
  // Query faculties
  const [ loadFaculties, { data: facultyData }] = useLazyQuery(FACULTY_NAME);

  // queryDepartment
  const [loadDepartment, { data: departmentData }] = useLazyQuery(
    DEPARTMENT_NAME,
    {
      variables: {
        where: { faculty: { id: data?.factId } },
      },
    }
  );

  // query course
  const [ loadCourse, { data: courseData }] = useLazyQuery(CALL_COURSE_DATA);

  // query grade
  const [ loadGrades, { loading: gradeLoading, data: gradeDataLoad }] = useLazyQuery(GRADES);

  useEffect(() => {
    if (gradeDataLoad?.grades?.data) {
      let _newData = [];
      for (var i = 0; i < gradeDataLoad?.grades?.data.length; i++) {
        if (
          gradeDataLoad?.grades?.data[i].sendingGradeStatus ==
            "TEACHER_SCORING" ||
          gradeDataLoad?.grades?.data[i].sendingGradeStatus ==
            "TEACHER_REJECTED"
        )
          _newData.push(gradeDataLoad?.grades?.data[i]);
      }
      setGradeData(_newData);
    }
  }, [gradeDataLoad]);

  // pagegination
  useEffect(() => {
    var gradeCount = gradeDataLoad?.grades?.total;
    const countPages = [];
    for (var i = 1; i <= Math.ceil(gradeCount / PAGE_LIMIT); i++) {
      countPages.push(i);
      setCountPage(countPages);
    }
  }, [gradeDataLoad]);

  const _onNext = () => {
    if (parseInt(pageNambers) + 1 < 1) {
      history.push(`/add-grade-for-student/${1}`);
    } else {
      history.push(`/add-grade-for-student/${parseInt(pageNambers) + 1}`);
    }
  };
  const _onPrevius = () => {
    if (parseInt(pageNambers) - 1 < 1) {
      history.push(`/add-grade-for-student/${1}`);
    } else {
      history.push(`/add-grade-for-student/${parseInt(pageNambers) - 1}`);
    }
  };

  // use effect
  useEffect(() => {
    loadFaculties();
    loadCourse();
    loadDepartment();
    // call all grade data
    let allWhere = {};

    if (parseInt(data?.yearLevel))
      allWhere = { ...allWhere, yearLevel: parseInt(data?.yearLevel) };
    if (parseInt(data?.semester))
      allWhere = { ...allWhere, semester: parseInt(data?.semester) };
    if (data?.accessMent)
      allWhere = { ...allWhere, assessmentYear: data?.accessMent };
    if (data?.teacherId)
      allWhere = {
        ...allWhere,
        course: { teacher_some: { id: data?.teacherId } },
      };

    if (data?.courseId) delete allWhere?.course;
    allWhere = {
      ...allWhere,
      course: {
        id: data?.courseId,
        teacher_some: { id: data?.teacherId },
        isDeleted_not: true,
      },
    };

    loadGrades({
      variables: {
        where: { AND: [allWhere] },
        first: PAGE_LIMIT,
        skip: (parseInt(pageNambers) - 1) * PAGE_LIMIT,
      },
    });
  }, []);

  // get facName
  const _renderFacName = (e) => {
    let result = facultyData?.faculties?.filter((item, index) => item.id === e);
    if (result?.length > 0) return result[0].name;
    else return "";
  };
  // get depName
  const _renderDepName = (e) => {
    let result = departmentData?.departments?.filter(
      (item, index) => item.id === e
    );
    if (result?.length > 0) return result[0].name;
    else return "";
  };

  // get depName
  const _renderCourseName = (e) => {
    let result = courseData?.courses?.filter((item) => item.id === e);
    if (result?.length > 0) return result[0].title;
    else return "";
  };

  const messageSuccess = (e) => {
    Notiflix.Notify.Init({ position: "right-bottom" });
    Notiflix.Notify.Success(e);
  };

  const messageError = (e) => {
    Notiflix.Notify.Init({ position: "right-bottom" });
    Notiflix.Notify.Failure(e);
  };
  const sendingLoad = (e) => {
    Notiflix.Loading.Standard(e);
  };

  //Save one grade
  const _saveOneGrade = (grade) => {
    updateGrade({
      variables: {
        data: {
          gredeStatus: "FINISHED",
          gradeType: {
            create: grade.gradeType,
          },
        },
        where: { id: grade.id },
      },
    })
      .then(() => {
        messageSuccess("ຢືນຢັນແລ້ວ");
      })
      .catch(() => {
        messageError("ບໍ່ສາມາດແກ້ໄຂໄດ້");
        window.location.reload();
      });
  };

  const _updateGrade = () => {
    sendingLoad("ການດຳເນີນຕ້ອງໃຊ້ເວລາ, ກະລຸນາລໍຖ້າ");
    gradeData.map(async (grade, index) => {
      await _grade(index, grade.id);
      await _saveOneGrade(grade);
    });
  };

  const _totalGrade = (grades) => {
    let result = 0;
    grades.map((_type, _index) => {
      result += parseInt(_type?.score ?? 0);
    });
    return result;
  };
  const _grade = (index, id) => {
    setConfirmGrdae(!confirmGrade);
  };

  return (
    <div>
      <div className="col-md-12">
        <a href="#">
          {" "}
          <strong>ການເບິ່ງຄະແນນ</strong> /
        </a>{" "}
        <a href="#" onClick={() => history.goBack()}>
          ຄະແນນທັງໝົດ /
        </a>
        &nbsp;ໃຫ້ຄະແນນນັກສຶກສາໃໝ່
      </div>
      <CustomContainer style={{ marginTop: 10 }}>
        <div style={{ display: "block", marginTop: 20 }}>
          <span
            style={{
              display: "inline-block",
              fontSize: 24,
              marginTop: 20,
              color: "#057CAE",
              fontWeight: "bold",
            }}
          >
            ໃຫ້ຄະແນນນັກສຶກສາໃໝ່
          </span>
        </div>
        <div
          style={{
            marginTop: 30,
            marginBottom: 8,
            fontSize: 16,
            color: Consts.FONT_COLOR_SECONDARY,
          }}
        >
          {/* custom search button */}
          <Row>
            <div className="col-sm-6 col-md-3">
              <div style={{ fontWeight: "bold", paddingBottom: 10 }}>ຄະນະ</div>
              <input
                type="text"
                className="grade-type"
                value={_renderFacName(data?.factId) || "ທັງໝົດ"}
                disabled
              />
            </div>
            <div className="col-sm-6 col-md-3">
              <div style={{ fontWeight: "bold", paddingBottom: 10 }}>
                ພາກວິຊາ
              </div>
              <input
                type="text"
                className="grade-type"
                value={_renderDepName(data?.departmentId) || "ທັງໝົດ"}
                disabled
              />
            </div>
            <div className="col-sm-6 col-md-3">
              <div style={{ fontWeight: "bold", paddingBottom: 10 }}>ປີ</div>
              <input
                type="text"
                className="grade-type"
                value={parseInt(data?.yearLevel) || "ທັງໝົດ"}
                disabled
              />
            </div>
            <div className="col-sm-6 col-md-3">
              <div style={{ fontWeight: "bold", paddingBottom: 10 }}>
                ພາກຮຽນ
              </div>
              <input
                type="text"
                className="grade-type"
                value={parseInt(data?.semester) || "ທັງໝົດ"}
                disabled
              />
            </div>
            <div className="col-sm-6 col-md-3">
              <div style={{ fontWeight: "bold", paddingBottom: 10 }}>
                ສົກຮຽນ
              </div>
              <input
                type="text"
                className="grade-type"
                value={data?.accessMent ? data?.accessMent : "ທັງໝົດ"}
                disabled
              />
            </div>
            <div className="col-sm-6 col-md-3">
              <div style={{ fontWeight: "bold", paddingBottom: 10 }}>ວິຊາ</div>
              <input
                type="text"
                className="grade-type"
                value={_renderCourseName(data?.courseId) || "ທັງໝົດ"}
                disabled
              />
            </div>
            <div className="col-sm-6 col-md-3">
              <div style={{ fontWeight: "bold", paddingBottom: 10 }}>
                ອາຈານສອນ
              </div>
              <select
                className="form-control"
                disabled={role === "TEACHER" ? true : false}
              >
                <option value={teacherId}>{teacherName}</option>
              </select>
            </div>
          </Row>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          ທັງຫມົດ {gradeData?.length} ຄົນ
        </div>
        {/* Table list */}
        {gradeData && (
          <Formik
            initialValues={{
              numericGrade: "",
              letterGrade: "",
            }}
            onSubmit={(values, { setSubmitting }) => {
              _updateGrade();
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              resetForm,
            }) => (
              <div>
                <table
                  className="table table-bordered table-hover"
                  style={{ width: "100%" }}
                >
                  <thead>
                    <TableHeader>
                      <th style={{ width: 70 }}>ລຳດັບ</th>
                      <th>ລະຫັດນັກສຶກສາ</th>
                      <th>ຊື່ ແລະ ນາມສະກຸນ</th>
                      {gradeData[0]?.gradeType?.map((grade, index) => {
                        return <th key={index}>{grade?.name}</th>;
                      })}
                      <th>ຄະແນນ</th>
                      <th>ເກຣດ</th>
                      <th>ຈັດການ</th>
                    </TableHeader>
                  </thead>
                  <tbody>
                    {gradeLoading ? (
                      <Loading />
                    ) : (
                      gradeData.map((grade, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              {(parseInt(pageNambers) - 1) * PAGE_LIMIT +
                                index +
                                1}
                            </td>
                            <td>{grade?.student?.studentNumber}</td>
                            <td>
                              {setGenders(grade?.student?.gender) ?? "-"} &nbsp;
                              {grade?.student?.firstNameL ?? "-"} &nbsp;{" "}
                              {grade?.student?.lastNameL}{" "}
                            </td>
                            {grade?.gradeType?.map((_type, _index) => {
                              return (
                                <td key={index}>
                                  <Form.Control
                                    type="number"
                                    style={{ textAlign: "center" }}
                                    value={_type?.score}
                                    disabled={
                                      whenClick[index] === index - 1
                                        ? true
                                        : checkStatus[index] === index
                                        ? false
                                        : true
                                    }
                                    onChange={async (e) => {
                                      let _total = 0;
                                      for (
                                        var i = 0;
                                        i < gradeData[index].gradeType.length;
                                        i++
                                      ) {
                                        if (
                                          gradeData[index].gradeType[i].name !=
                                          gradeData[index].gradeType[_index]
                                            .name
                                        ) {
                                          _total +=
                                            gradeData[index].gradeType[i].score;
                                        }
                                      }
                                      _total =
                                        _total + parseInt(e.target.value);

                                      gradeData[index].gradeType[_index].score =
                                        parseInt(e.target.value);
                                      let conn = [...checkStatus];
                                      conn[index] = index;
                                      setCheckStatus(conn);
                                      let setwhen = [...whenClick];
                                      setwhen[index] = index;
                                      setWhenClick(setwhen);
                                    }}
                                  />
                                </td>
                              );
                            })}
                            <td>
                              <Form.Control
                                disabled={true}
                                className="center"
                                type="text"
                                placeholder="100"
                                value={_totalGrade(grade?.gradeType ?? [])}
                              />
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <input
                                type="text"
                                disabled={true}
                                className="w-100 grade-type"
                                style={{ textAlign: "center" }}
                                name={gradeData?.letterGrade}
                                value={convertLetterGrade(
                                  _totalGrade(grade?.gradeType ?? [])
                                )}
                              />
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {whenClick[index] === index ? (
                                <button
                                  className="btn btn-outline-success"
                                  onClick={async (e) => {
                                    let conn = [...checkStatus];
                                    conn[index] = index - 1;
                                    setCheckStatus(conn);
                                    let setwhen = [...whenClick];
                                    setwhen[index] = index - 1;
                                    setWhenClick(setwhen);
                                    await _grade(index, grade.id);
                                    await _saveOneGrade(grade);
                                    resetForm({});
                                  }}
                                  style={{ fontWeight: "bold" }}
                                >
                                  <i className="fa fa-check-circle"></i>
                                  &nbsp;&nbsp; ຢືນຢັນ
                                </button>
                              ) : grade?.numbericGrade !== null &&
                                checkStatus[index] === index ? (
                                <button
                                  className="btn btn-outline-success"
                                  onClick={async (e) => {
                                    let conn = [...checkStatus];
                                    conn[index] = index - 1;
                                    setCheckStatus(conn);
                                    let setwhen = [...whenClick];
                                    setwhen[index] = index - 1;
                                    setWhenClick(setwhen);
                                    await _grade(index, grade.id);
                                    await _saveOneGrade(grade);
                                    resetForm({});
                                  }}
                                  style={{ fontWeight: "bold" }}
                                >
                                  <i className="fa fa-check-circle"></i>
                                  &nbsp;&nbsp; ຢືນຢັນ
                                </button>
                              ) : (
                                ""
                              )}
                              {
                                whenClick[index] === index ||
                                grade?.sendingGradeStatus ==
                                  "TEACHER_SUBMITED" ||
                                grade?.sendingGradeStatus ==
                                  "DEPARTMENT_CHECKING" ||
                                grade?.sendingGradeStatus ==
                                  "DEPARTMENT_SUBMITED" ||
                                grade?.sendingGradeStatus ==
                                  "DEPARTMENT_REJECTED" ||
                                grade?.sendingGradeStatus ==
                                  "FACULTY_CHECKING" ||
                                grade?.sendingGradeStatus ==
                                  "FACULTY_SUBMITED" ||
                                grade?.sendingGradeStatus ==
                                  "FACULTY_REJECTED" ||
                                grade?.sendingGradeStatus === "ADMIN_CHECKING" ||
                                grade?.sendingGradeStatus === "ADMIN_APPROVED" ? (
                                  ""
                                ) : (
                                  // <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">ແກ້ໄຂຄະແນນ</Tooltip>}>
                                  <button
                                    className="btn btn-outline-info"
                                    onClick={() => {
                                      let conn = [...checkStatus];
                                      conn[index] = index;
                                      setCheckStatus(conn);
                                      let setwhen = [...whenClick];
                                      setwhen[index] = index;
                                      setWhenClick(setwhen);
                                    }}
                                  >
                                    ແກ້ໄຂ
                                  </button>
                                )
                                // </OverlayTrigger>
                              }
                            </td>
                          </tr>
                        );
                      })
                    )}

                    {/* })} */}
                  </tbody>
                </table>
                <Pagination
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 20,
                  }}
                >
                  <Pagination.Prev onClick={_onPrevius} />
                  {countPage?.slice(0, 5).map((Item, index) => {
                    return (
                      <Pagination.Item
                        key={"page" + index}
                        active={Item === pageNambers}
                        onClick={() =>
                          history.push(`/add-grade-for-student/${index + 1}`)
                        }
                      >
                        {Item}
                      </Pagination.Item>
                    );
                  })}
                  {countPage && countPage > 3 ? (
                    <Pagination.Item style={{ pointerEvents: "none" }}>
                      {"..."}
                    </Pagination.Item>
                  ) : (
                    " "
                  )}
                  {countPage && countPage > 3 ? (
                    <Pagination.Item
                      onClick={() =>
                        history.push(`/add-grade-for-student/${countPage}`)
                      }
                    >
                      {countPage}
                    </Pagination.Item>
                  ) : (
                    " "
                  )}
                  <Pagination.Next onClick={_onNext} />
                </Pagination>
                <div className="col-md-12 center">
                  <button
                    type="submit"
                    className="btn btn-outline-secondary"
                    onClick={() => history.goBack()}
                  >
                    ຍົກເລີກ
                  </button>
                  &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    className="btn btn-info"
                  >
                    ສົ່ງຄະແນນ
                  </button>
                </div>
              </div>
            )}
          </Formik>
        )}
      </CustomContainer>
      <EditGrade
        show={showEdit}
        hide={() => setShowEdit(!showEdit)}
      />
    </div>
  );
}

export default AddGradeForStudent;
