import React, { useEffect, useState } from "react";
import { Typography, Menu, MenuItem } from "@material-ui/core";
import FileIconOption from "./FileIconOption";
import axios from "axios";
import { API_KEY, NEW_API_URL } from "../../common/constraint";
import RenameDialog from "./RenameDialog";
import AlertMessage from "../../common/AlertMessage";
import ManageAccessDialog from "./MangeAccessDialog";
import { USER_KEY } from "../../consts/user";
import { ExcelRenderer } from "react-excel-renderer";
import useReactRouter from "use-react-router";

const File = ({ fileName, date, id, fileLink, refetch, data }) => {
  const { history } = useReactRouter();
  const [contextMenuAnchor, setContextMenuAnchor] = useState(null);
  const fileExtension = fileLink.split(".").pop();
  const [showRename, setShowRename] = useState(false);
  const [actionStatus, setActionStatus] = useState({
    open: false,
    message: "ປ່ຽນຊື່ສຳເລັດ",
    type: "success",
  });
  const currentPath = history.location.pathname;
  const [showExcel, setShowExcel] = useState(false);

  const user = localStorage.getItem(USER_KEY);
  const userObject = JSON.parse(user);
  const token = userObject?.accessToken;

  const [showManageAccess, setShowManageAccess] = useState(false);

  const handleFileClick = async () => {
    if (fileExtension === "xlsx" || fileExtension === "xls") {
      try {
        const response = await fetch(fileLink);
        const blob = await response.blob();
        ExcelRenderer(blob, (err, resp) => {
          if (err) {
            console.error(err);
          } else {
            history.push("/view/excel", {
              cols: resp.cols,
              rows: resp.rows,
              currentPath: currentPath,
            });
            setShowExcel(true);
          }
        });
      } catch (error) {
        console.error("Error fetching or rendering Excel file:", error);
      }
    }
    if (
      fileExtension === "png" ||
      fileExtension === "jpg" ||
      fileExtension === "jpeg"
    ) {
      history.push("/view/image", {
        link: fileLink,
      });
    }
  };

  useEffect(() => {
    if (actionStatus.open) {
      setTimeout(() => {
        setActionStatus((predata) => ({
          ...predata,
          open: false,
        }));
      }, 2000);
    }
  }, [actionStatus.open]);

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenuAnchor(event.currentTarget);
  };

  const handleCloseContextMenu = () => {
    setContextMenuAnchor(null);
  };

  const handleContextMenuAction = async (action) => {
    if (action === "DELETE") {
      await deleteFile();
    }
    if (action === "DOWNLOAD") {
      downloadFile();
    }
    handleCloseContextMenu();
  };

  const truncateFilename = (filename, maxLength) => {
    if (filename.length > maxLength) {
      return filename.substring(0, maxLength) + "...";
    }
    return filename;
  };

  const deleteFile = async () => {
    try {
      await axios.delete(NEW_API_URL + "course/files?fileId=" + id, {
        headers: {
          api_key: API_KEY,
          Authorization: `Bearer ${token}`,
        },
      });
      refetch();
    } catch (err) {}
  };

  const downloadFile = () => {
    // Create an anchor element
    const link = document.createElement("a");
    // Set the href attribute to the file link
    link.href = fileLink;
    // Set the download attribute to trigger a download
    link.download = fileName;
    // Append the anchor element to the body
    document.body.appendChild(link);
    // Trigger a click event on the anchor element
    link.click();
    // Remove the anchor element from the body
    document.body.removeChild(link);
  };

  return (
    <div
      onContextMenu={handleContextMenu}
      className="d-flex flex-column align-items-center col-lg-2 col-md-3 col-sm-4 folder"
    >
      <AlertMessage
        open={actionStatus?.open}
        message={actionStatus?.message}
        type={actionStatus?.type}
      />

      <ManageAccessDialog
        type={"FILE"}
        id={id}
        onCancel={() => {
          setShowManageAccess(false);
          setContextMenuAnchor(null);
        }}
        open={showManageAccess}
      />

      <RenameDialog
        onSubmit={(success) => {
          if (success) {
            setActionStatus({
              open: true,
              message: "ປ່ຽນຊື່ສຳເລັດ",
              type: "success",
            });
            setShowRename(false);
            refetch();
          } else {
            setActionStatus({
              open: true,
              message: "ມີບັນຫາໃນການປ່ຽນຊື່​",
              type: "error",
            });
          }
          setContextMenuAnchor(null);
        }}
        onCancel={() => {
          handleCloseContextMenu(null);
          setShowRename(false);
        }}
        type={"FILE"}
        id={id}
        message={"ຊື່ File ໃຫມ່"}
        open={showRename}
      />

      <div className="text-center" onClick={handleFileClick}>
        <FileIconOption link={fileLink} type={fileExtension} />
        <Typography>{truncateFilename(fileName, 15)}</Typography>
        <Typography
          style={{
            fontSize: "14px",
          }}
        >
          {"ສ້າງວັນທີ : " + new Date(date).toLocaleDateString()}
        </Typography>
      </div>

      <Menu
        anchorEl={contextMenuAnchor}
        open={Boolean(contextMenuAnchor)}
        onClose={handleCloseContextMenu}
      >
        <MenuItem onClick={() => handleContextMenuAction("DOWNLOAD")}>
          ດາວໂຫລດ
        </MenuItem>
        <MenuItem onClick={() => setShowManageAccess(true)}>
          ຈັດການສິດການເຂົ້າເຖີງ
        </MenuItem>
        <MenuItem onClick={() => setShowRename(true)}>ປ່ຽນຊື່</MenuItem>
        <MenuItem onClick={() => handleContextMenuAction("DELETE")}>
          ລົບ
        </MenuItem>
      </Menu>
    </div>
  );
};

export default File;
