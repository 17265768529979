import React from "react";
import PropTypes from "prop-types";
import { Modal, Form, Button, Col, Row } from "react-bootstrap";
function Profile({ showUserProfile, _handleShowUserProfile, data }) {
  const PL = 30;
  return (
    <div>
      <Modal
        show={showUserProfile}
        onHide={_handleShowUserProfile}
        size="lg"
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton style={{ borderBottom: 0 }}>
          <Modal.Title style={{ textAlign: "center", width: "100%" }}>
            ຂໍ້ມູນຜູ້ໃຊ້
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ marginLeft: 50, marginRight: 50, paddingLeft: 100 }}
        >
          <Form>
            <Row>
              <Col sm="4"
                style={{ fontSize: 16, fontWeight: "bold" }}>
                  <i color="#057CAE" class="fas fa-sort-down"></i> ຂໍ້ມູນຜູ້ໃຊ້
              </Col>
              <Col sm="8"></Col>
            </Row>

            <Row style={{ paddingLeft:PL }}>
              <Col sm="4">
                  ຊື່
              </Col>
              <Col sm="8">
              {data && data.user && data.user.firstname
                  ? data.user.firstname
                  : "-"}
              </Col>
            </Row>
            <Row as={Row} style={{ paddingLeft: PL }}>
              <Col sm="4">
                ນາມສະກຸນ
              </Col>
              <Col sm="8">
                {data && data.user && data.user.lastname
                  ? data.user.lastname
                  : "-"}
              </Col>
            </Row>

            <Row as={Row} style={{ paddingLeft: PL }}>
              <Col sm="4">
                ເບີໂທ
              </Col>
              <Col sm="8">
                {data && data.user && data.user.phone ? data.user.phone : "-"}
              </Col>
            </Row>
            <Row as={Row} style={{ paddingLeft: PL }}>
              <Col sm="4">
                ອີເມວ
              </Col>
              <Col sm="8">
                {data && data.user && data.user.email ? data.user.email : "-"}
              </Col>
            </Row>

            <Row as={Row}>
              <Col
              
                sm="4"
                style={{ fontSize: 16, fontWeight: "bold" }}
              >
                <i color="#057CAE" class="fas fa-sort-down"></i> ໄອດີ ແລະ ລະຫັດຜ່ານ
              </Col>
              <Col sm="8"></Col>
            </Row>
            <Row as={Row} style={{ paddingLeft: PL }}>
              <Col sm="4">
                ໄອດີ
              </Col>
              <Col sm="8">
                {data && data.user && data.user.userId ? data.user.userId : "-"}
              </Col>
            </Row>
            <Row as={Row} style={{ paddingLeft: PL }}>
              <Col sm="4">
                ລະຫັດຜ່ານ
              </Col>
              <Col sm="8">******</Col>
            </Row>
          </Form>
        </Modal.Body>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ padding: 15 }} className="col-2">
            <Button
              onClick={_handleShowUserProfile}
              variant={"outline-dark"}
              style={{ width: 100 }}
            >
              ອອກ
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

Profile.protoTypes = {
  data: PropTypes.object.isRequired,
};
export default Profile;
