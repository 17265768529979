import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";
import { useLazyQuery } from "@apollo/react-hooks";
import _ from "lodash";
import queryString from "query-string";
import { Breadcrumb, OverlayTrigger, Tooltip, Col, Row, Form } from "react-bootstrap";
import { useSelector } from 'react-redux'

// component ----------------------------------------------------------------------->
import RegisterModal from "./RegisterModal";
import Loading from "../../common/Loading";
// consts -------------------------------------------------------------------------->
import Consts from "../../consts";
import Routers from "../../consts/routes";
import { CustomContainer, Title, TableHeader, TableCell, } from "../../common";
import { QUERY_FACULTY, QUERY_DEPARTMENT, GRADES_FOR_TEACHER } from "./apollo/query";
import { COURSES, COURSES_FACULTY_DEPARTMENT_FOR_TEACHER } from "../../apollo/course";
import PaginationHelper from "../../helpers/PaginationHelper";
import { QUERY_MAJORS } from "../courseList/apollo/query";

const RegisterAll = () => {
    const accessmentRedux = useSelector((state) => state.accessment.value)
    const { history, match, location } = useReactRouter();
    const parsed = queryString?.parse(location?.state);
    const { _limit, _skip, Pagination_helper } = PaginationHelper();
    // states ------------------------------------------------------------->
    const [isShowUpgrade, setIsShowUpgrade] = useState(false);
    const [gradesData, setGradesData] = useState([]);
    const [totals, setTotals] = useState(0);
    const [upgradeDatadetail, setGradesDataDetail] = useState({});
    const [studentCode, setStudentCode] = useState("");
    const [semester, setSemester] = useState(!parsed?.semester ? "1" : parsed?.semester);
    const [facultyArray, setFacultyArray] = useState([]);
    const [departmentArray, setDepartmentArray] = useState([]);
    const [majorArray, setMajorArray] = useState([]);
    const [courseIdArray, setCourseIdArray] = useState([]);

    const [dataFaculties, setDataFaculties] = useState([]);
    const [facultyId, setFacultyId] = useState(!parsed?.faculty ? "" : parsed?.faculty);
    const [dataDepartments, setDataDepartments] = useState([]);
    const [departmentId, setDepartmentId] = useState(!parsed?.department ? "" : parsed?.department);
    const [dataMajors, setDataMajors] = useState([]);
    const [majorId, setMajorId] = useState(!parsed?.major ? "" : parsed?.major);
    const [yearLevel, setYearLevel] = useState(!parsed?.yearLevel ? "1" : parsed?.yearLevel);
    // apollo ------------------------------------------------------------->
    const teacherId = match?.params?.id;
    const [loadData, { loading, data }] = useLazyQuery(GRADES_FOR_TEACHER, { fetchPolicy: "cache-and-network" });
    const [loadDataFacuties, { data: facultiesData }] = useLazyQuery(QUERY_FACULTY, { fetchPolicy: "cache-and-network" });
    const [loadDataDepartment, { data: departmentsData }] = useLazyQuery(QUERY_DEPARTMENT, { fetchPolicy: "cache-and-network" });
    const [loadDataMajor, { data: majorData }] = useLazyQuery(QUERY_MAJORS, { fetchPolicy: "network-only" });
    const [loadCoursesFacultyDepartment, { data: coursesData }] = useLazyQuery(COURSES_FACULTY_DEPARTMENT_FOR_TEACHER);

    // use ---------------------------------------------------------------->
    useEffect(() => {
        loadDataFacuties({ variables: { where: { isDeleted: false, assessmentYear: accessmentRedux } } })
        loadCoursesFacultyDepartment({ variables: { where: { teacher: teacherId, } } });
    }, []);

    useEffect(() => {
        if (data?.grades?.data) {
            setGradesData(data?.grades?.data);
            setTotals(data?.grades?.total)
        }
        if (coursesData) {
            var arrayFaculty = []
            var arrayDepartment = []
            var arrayMajor = []
            var courseIds = []
            for (var i = 0; i < coursesData?.courseAndFacultyAndDepartmentAndMajorFromTeacher?.dataFaculty?.length; i++) {
                arrayFaculty.push({ id: coursesData?.courseAndFacultyAndDepartmentAndMajorFromTeacher?.dataFaculty[i]?.id })
            }
            for (var i = 0; i < coursesData?.courseAndFacultyAndDepartmentAndMajorFromTeacher?.dataDepartment?.length; i++) {
                arrayDepartment.push({ id: coursesData?.courseAndFacultyAndDepartmentAndMajorFromTeacher?.dataDepartment[i]?.id })
            }
            for (var i = 0; i < coursesData?.courseAndFacultyAndDepartmentAndMajorFromTeacher?.dataMajor?.length; i++) {
                arrayMajor.push({ id: coursesData?.courseAndFacultyAndDepartmentAndMajorFromTeacher?.dataMajor[i]?.id })
            }
            for (var i = 0; i < coursesData?.courseAndFacultyAndDepartmentAndMajorFromTeacher?.dataCourse?.length; i++) {
                courseIds.push(coursesData?.courseAndFacultyAndDepartmentAndMajorFromTeacher?.dataCourse[i]?.id)
            }
            setFacultyArray(arrayFaculty)
            setDepartmentArray(arrayDepartment)
            setMajorArray(arrayMajor)
            setCourseIdArray(courseIds)
        }
    }, [data, coursesData, teacherId]);
    useEffect(() => {
        let _where = {
            numberTest: 1, gredeStatus_in: ["REQUESTING", "APROVED"],
            course_in: courseIdArray
        }
        if (facultyId !== "") _where = { ..._where, faculty: facultyId }
        if (departmentId !== "") _where = { ..._where, department: departmentId }
        if (majorId !== "") _where = { ..._where, major: majorId }
        if (semester !== "") _where = { ..._where, semester: parseInt(semester) }
        if (yearLevel !== "") _where = { ..._where, yearLevel: parseInt(yearLevel) }
        if (studentCode !== "") _where = { ..._where, student: studentCode }

        loadData({
            variables: {
                where: _where,
                skip: (_skip - 1) * _limit,
                first: _limit,
            },
        });
    }, [facultyId, departmentId, majorId, yearLevel, studentCode, semester, courseIdArray, teacherId])

    useEffect(() => {
        if (facultiesData)
            setDataFaculties(facultiesData?.faculties)
    }, [facultiesData]);

    useEffect(() => {
        if (departmentsData) {
            setDataDepartments(departmentsData?.departments)
        }
    }, [departmentsData]);

    useEffect(() => {
        if (facultyId === "") {
            setDataDepartments([])
            setDepartmentId("")
        }
        else {
            loadDataDepartment({ variables: { where: { isDeleted: false, faculty: facultyId } } })
        }
    }, [facultyId]);

    useEffect(() => {
        if (departmentId === "") {
            setDataMajors([])
            setMajorId("")
        }
        else {
            loadDataMajor({
                variables: {
                  where: { isDeleted: false, assessmentYear: accessmentRedux, department: departmentId }
                }
            })
        }
    }, [departmentId]);

    useEffect(() => {
        if (departmentsData) setDataDepartments(departmentsData?.departments)
    }, [departmentsData]);

    useEffect(() => {
        if (majorData) setDataMajors(majorData?.majors?.data)
    }, [majorData]);
    
    // Search
    const _selectFaculty = (id) => {
        setFacultyId(id.target.value);
    }
    const _onSelectedDepartment = (id) => {
        setDepartmentId(id?.target?.value)
    }
    const _onSelectedMajor = (id) => {
        setMajorId(id?.target?.value)
    }

    const _onSelectedYear = (year) => {
        setYearLevel(year.target.value);
    };

    const _onSelectedSemester = (semester) => {
        setSemester(semester.target.value);
    }

    const _showTheFacultyAccordingToTheTeacher = (id) => {
        const _showThe = facultyArray.filter(fac => fac?.id === id)
        if (_showThe.length > 0) return false
        else return true
    }
    const _showTheDepartmentAccordingToTheTeacher = (id) => {
        const _showTheDepartment = departmentArray.filter(dep => dep?.id === id)
        if (_showTheDepartment.length > 0) return false
        else return true
    }
    const _showTheMajorAccordingToTheTeacher = (id) => {
        const _showTheMajor = majorArray.filter(major => major?.id === id)
        if (_showTheMajor.length > 0) return false
        else return true
    }
    return (
        <div>
            <Breadcrumb>
                <Breadcrumb.Item onClick={() => history.push("/course-list/1")}>
                    ວິຊາທີສອນ
                </Breadcrumb.Item>
                <Breadcrumb.Item active style={{ fontWeight: "bolder" }}>
                    ຈຳນວນຜູ້ລົງທະບຽນທັງໝົດ
                </Breadcrumb.Item>
            </Breadcrumb>
            <CustomContainer>
                <Title text={"ລາຍຊື່ນັກສຶກສາລົງທະບຽນຮຽນ"} />
                <Row>
                    <Col md={12} style={{ marginBottom: 20, marginTop: 10 }}>
                        <Form.Group
                            as={Row}
                            style={{
                                margin: 0,
                                marginTop: 10,
                                marginBottom: 10,
                                alignItems: "center",
                                fontSize: 16,
                            }}
                        >
                            <Form.Label column sm="1" className="text-left">
                                ຄົ້ນຫາ
                            </Form.Label>
                            <Col md="11">
                                <Form.Control
                                    style={{ borderRadius: 50 }}
                                    placeholder="ປ້ອນໄອດີລະຫັດ ນັກສຶກສາ"
                                    type="text"
                                    onChange={(e) => setStudentCode(e.target.value)}
                                />
                            </Col>
                        </Form.Group>
                    </Col>
                    <Col sm={3}>
                        <label>ຄະນະວິຊາ</label>
                        <Form.Control
                            as="select"
                            style={{ cursor: "pointer" }}
                            value={facultyId}
                            onChange={(e) => { _selectFaculty(e) }}
                        >
                            <option value="">ຄະນະທັງໝົດ</option>
                            {dataFaculties?.map((item, index) => {
                                return (
                                    <option
                                        disabled={_showTheFacultyAccordingToTheTeacher(item?.id)}
                                        key={index}
                                        value={item.id}>
                                        {item.name}
                                    </option>);
                            })}
                        </Form.Control>
                    </Col>
                    <Col sm={3}>
                        <label>ພາກວິຊາ</label>
                        <Form.Control
                            as="select"
                            style={{cursor: "pointer"}}
                            value={departmentId}
                            onChange={(e) => _onSelectedDepartment(e)}
                        >
                            <option value="">ທັງໝົດ</option>
                            {dataDepartments?.map((item, index) => {
                                return (
                                    <option disabled={_showTheDepartmentAccordingToTheTeacher(item?.id)} key={index} value={item.id}>{item.name}</option>
                                );
                            })}
                        </Form.Control>
                    </Col>
                    <Col sm={3}>
                        <label>ສາຂາວິຊາ</label>
                        <Form.Control
                            as="select"
                            style={{cursor: "pointer"}}
                            value={majorId}
                            onChange={(e) => _onSelectedMajor(e)}
                        >
                            <option value="">ທັງໝົດ</option>
                            {dataMajors?.map((item, index) => {
                                return (
                                    <option disabled={_showTheMajorAccordingToTheTeacher(item?.id)} key={index} value={item.id}>{item.name}</option>
                                );
                            })}
                        </Form.Control>
                    </Col>
                    <Col>
                        <label>ປີຮຽນ</label>
                        <Form.Control
                            as="select"
                            style={{cursor: "pointer"}}
                            value={yearLevel}
                            onChange={(e) => _onSelectedYear(e)}
                        >
                            <option value={""}>ທັງໝົດ</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                        </Form.Control>
                    </Col>

                    <Col>
                        <label>ພາກຮຽນ</label>
                        <Form.Control
                            as="select"
                            style={{cursor: "pointer"}}
                            value={semester}
                            onChange={(e) => _onSelectedSemester(e)}
                        >
                            <option value={""}>ທັງໝົດ</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                        </Form.Control>
                    </Col>
                </Row>
                <div
                    style={{
                        marginTop: 24,
                        marginBottom: 8,
                        fontSize: 16,
                        color: Consts.FONT_COLOR_SECONDARY,
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    ທັງຫມົດ {totals} ຄົນ
                </div>
                {/* Table list */}
                <div>
                    {loading ? <Loading /> :
                        <table border="1" bordercolor="#fff" style={{ width: "100%" }}>
                            <thead>
                                <TableHeader style={{ backgroundColor: "#057CAE" }}>
                                    <th style={{ width: 70 }}>ລຳດັບ</th>
                                    <th>ລະຫັດນັກສຶກສາ</th>
                                    <th>ຊື່ນັກສຶກສາ</th>
                                    <th>ນັກສຶກສາປີ</th>
                                    <th>ພາກຮຽນ</th>
                                    <th>ສົກຮຽນ</th>
                                    <th>ຊື່ວິຊາທີ່ລົງທະບຽນ</th>
                                    <th style={{ width: 200 }}>ຈັດການ</th>
                                </TableHeader>
                            </thead>
                            {gradesData?.map((grade, index) => {
                                return (
                                    <tbody key={index}>
                                        <tr style={{ border: "2px solid white" }}>
                                            <TableCell
                                                style={{
                                                    backgroundColor: "#EEEEEE",
                                                    textAlign: "center",
                                                }}
                                            >
                                                {index + 1}
                                            </TableCell>
                                            <TableCell style={{ backgroundColor: "#EEEEEE" }}>
                                                {grade?.student?.userId}
                                            </TableCell>
                                            <TableCell style={{ textAlign: "center" }}>
                                                {grade?.student?.firstNameL}
                                            </TableCell>
                                            <TableCell
                                                style={{
                                                    backgroundColor: "#EEEEEE",
                                                    textAlign: "center",
                                                }}
                                            >
                                                {grade?.student?.yearLevel}
                                            </TableCell>
                                            <TableCell
                                                style={{
                                                    backgroundColor: "#EEEEEE",
                                                    textAlign: "center",
                                                }}
                                            >
                                                {grade?.semester}
                                            </TableCell>
                                            <TableCell
                                                style={{
                                                    backgroundColor: "#EEEEEE",
                                                    textAlign: "center",
                                                }}
                                            >
                                                {grade?.student?.assessmentYear}
                                            </TableCell>
                                            <TableCell
                                                style={{
                                                    backgroundColor: "#EEEEEE",
                                                    textAlign: "center",
                                                }}
                                            >
                                                {grade?.course?.title}
                                            </TableCell>
                                            <TableCell
                                                style={{
                                                    width: 200,
                                                    backgroundColor: "#EEEEEE",
                                                    textAlign: "center",
                                                }}
                                            >
                                                {grade?.gredeStatus === "APROVED" ?
                                                    <div style={{ color: Consts.COLOR_EDIT }}>ລົງທະບຽນແລ້ວ</div>
                                                    : <div
                                                        style={{
                                                            display: "flex",
                                                            // flexDirection: "row",
                                                            justifyContent: "space-around",
                                                            width: 200,
                                                        }}
                                                    >
                                                        <OverlayTrigger
                                                            overlay={
                                                                <Tooltip id="tooltip-disabled">
                                                                    ຍອມຮັບເອົານັກສຶກສາເຂົ້າຮຽນ
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <button
                                                                onClick={() => {
                                                                    setGradesDataDetail(grade);
                                                                    setIsShowUpgrade(!isShowUpgrade);
                                                                }}
                                                                style={{
                                                                    width: 100,
                                                                    height: 30,
                                                                    borderRadius: 3,
                                                                    border: "1px solid #137DAE",
                                                                    // outline: "none",
                                                                    backgroundColor: "white",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                }}
                                                            >
                                                                ລົງທະບຽນ
                                                            </button>
                                                        </OverlayTrigger>
                                                    </div>
                                                }

                                            </TableCell>
                                        </tr>
                                    </tbody>
                                );
                            })
                            }
                        </table>}
                    {Pagination_helper(totals, Routers.REGISTER_ALL + "/id/" + teacherId, `faculty=${facultyId}&&department=${departmentId}&&major=${majorId}&&yearLevel=${yearLevel}&&semester=${semester}`)}
                </div>
            </CustomContainer>
            {/* Search Modal */}
            <RegisterModal
                isShowUpgrade={isShowUpgrade}
                _handleUpgradeClose={() => setIsShowUpgrade(!isShowUpgrade)}
                data={upgradeDatadetail}
            />
        </div>
    );
};

export default RegisterAll;
