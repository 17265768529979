import React, {} from 'react'
import {
  Modal,
  Button,
} from 'react-bootstrap'
import Consts from '../../consts'

const CourseDeleteConfirm = ({
  showDeleteConfirmView,
  _handleDeleteConfirmViewClose
}) => {
  return (
    <Modal
      show={showDeleteConfirmView}
      onHide={_handleDeleteConfirmViewClose}
      size='lg'
    >
      <Modal.Body style={{marginLeft: 50, marginRight: 50, padding: 50}}>
        <p className='text-center' style={{fontWeight: 'bold'}}>
          ຕ້ອງການລຶບ?
        </p>

        <p className='text-center'>ວິຊາຖານຂໍ້ມູນ</p>

        <div style={{height: 20}} />
        <div className='row'>
          <div style={{padding: 15}} className='col'>
            <Button
              onClick={_handleDeleteConfirmViewClose}
              style={{
                width: '100%',
                backgroundColor: '#fff',
                color: '#6f6f6f',
                borderColor: '#6f6f6f'
              }}
            >
              ຍົກເລີກ
            </Button>
          </div>
          <div style={{padding: 15}} className='col'>
            <Button
              style={{
                width: '100%',
                backgroundColor: Consts.SECONDARY_COLOR,
                color: '#fff',
                borderColor: Consts.SECONDARY_COLOR
              }}
            >
              ລຶບ
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default CourseDeleteConfirm
