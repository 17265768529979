import { gql } from "apollo-boost";

export const QUERY_GRADES = gql`
  query Grades(
    $where: CustomGradeWhereInput
    $orderBy: GradeOrderByInput
    $skip: Int
    $first: Int
  ) {
    grades(
      where: $where
      orderBy: $orderBy
      skip: $skip
      first: $first
    ) {
      data {
        id
        yearLevel
        semester
        gredeStatus
        student {
          id
          userId
          firstNameL
          lastNameL
          yearLevel
          assessmentYear
        }
        course {
          id
          title
          year
          semester
          assessmentYear
        }
      }
      total
    }
  }
`;

export const QUERY_FACULTY = gql`
  query Faculties(
    $where: CustomFacultyWhereInput
  ) {
    faculties(
      where: $where
    ) {
      id
      name
    }
  }
`;

export const QUERY_DEPARTMENT = gql`
  query Departments(
    $where: CustomDepartmentWhereInput
  ) {
    departments(
      where: $where
    ) {
      id
      name
    }
  }
`;
export const QUERY_MAJORS = gql`
  query MAJORS(
    $where: CustomMajorWhereInput
    $orderBy: MajorOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    majors(
        where: $where
        orderBy: $orderBy
        skip: $skip
        after: $after
        before: $before
        first: $first
        last: $last

    ){
      total
      data{
        id
        name
        enName
        description
        status
        enDescription
        startDateRegister
        endDateRegister
        note
        faculty{
        id
        name
        note
        }
        department{
          id
          name
        }
        assessmentYear
        startDateRegister
        endDateRegister
      }
    }
  }
`

export const COURSES = gql`
  query Courses(
    $where: CourseWhereInput
    $skip: Int
    $first: Int
  ) {
    course_page(
      where: $where
      skip: $skip
      first: $first
    ) {
    data{
      id
      courseCode
      moodleCourseId
      title
      semester
      year
      faculty{
            id
            name
        }
      department{
            id
            name
        }
    }
    total
    }
  }
`;