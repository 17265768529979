import React from "react";
import { OutTable, ExcelRenderer } from "react-excel-renderer";
import { useLocation } from "react-router-dom";
import "./index.css";
export const ExcelDisplay = () => {
  const location = useLocation();
  const data = location.state;

  return (
    <div style={{ overflowX: "auto", overflowY: "auto", height: "100%" }}>
      <OutTable
        data={data?.rows}
        columns={data?.cols}
        tableClassName="ExcelTable2007"
        tableHeaderRowClass="heading"
      />
    </div>
  );
};

export default ExcelDisplay;
