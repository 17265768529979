import React from "react";
import Folder from "./Folder";

export const FileFolderList = ({ folderList, refetch }) => {
  return (
    <div className="row">
      {folderList.map((folder) => (
        <Folder
          refetch={() => {
            refetch();
          }}
          data={folder}
          id={folder?._id}
          folderName={folder?.folderName}
          date={folder?.createdAt}
        />
      ))}
    </div>
  );
};

export default FileFolderList;
