import React, { useEffect, useState } from "react";
import TopBar from "./Topbar";
import FileFolderList from "./FolderList";
import FileList from "./FilesList";
import axios from "axios";
import { NEW_API_URL, API_KEY } from "../../common/constraint";
import { USER_KEY } from "../../consts/user";
import { useHistory } from "react-router-dom";
import { Typography } from "@material-ui/core";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";

export const CourseFile = () => {
  const user = localStorage.getItem(USER_KEY);
  const userObject = JSON.parse(user);
  const token = userObject?.accessToken;
  const [folderList, setFolderList] = useState([]);
  const [fileList, setFileList] = useState([]);
  const history = useHistory();
  const [isRootPath, setIsRootPath] = useState(true);
  const currentPath = history.location.pathname;
  const [paths, setPaths] = useState([]);

  const onPathClick = (id) => {
    const clickedIdIndex = currentPath.indexOf(id);
    if (clickedIdIndex !== -1) {
      history.push(currentPath.substring(0, clickedIdIndex + id.length));
    }
  };
  const fetchPathNavigate = async () => {
    const urlParts = currentPath.split("/");
    const index_of_one = urlParts.indexOf("1");
    if (index_of_one !== -1 && index_of_one < urlParts.length - 1) {
      const arrayAfterOne = [];
      for (let i = index_of_one + 1; i < urlParts.length; i++) {
        arrayAfterOne.push(urlParts[i]);
      }
      const stringAfterOne = arrayAfterOne.join("/");
      const response = await axios.get(
        NEW_API_URL + "course/files/path?paths=" + stringAfterOne,
        {
          headers: {
            api_key: API_KEY,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPaths(response?.data);
    }
  };

  useEffect(() => {
    fetchPathNavigate();
  }, []);

  useEffect(() => {
    const urlParts = currentPath.split("/");
    const lastParam = urlParts[urlParts.length - 1];

    setFileList([]);
    setFolderList([]);
    // the gome page can not contains file so we check that if first page then fetch only folder
    // data and if inside folder then fetch both file and folders
    if (lastParam == 1) {
      setIsRootPath(true);
      fetchFolder();
    } else {
      setIsRootPath(false);
      fetchFilesAndFolders();
    }
  }, [currentPath]);

  const fetchFilesAndFolders = async () => {
    const urlParts = currentPath.split("/");
    const lastParam = urlParts[urlParts.length - 1];
    const res = await axios.get(
      NEW_API_URL + `course/files?folderId=${lastParam}`,
      {
        headers: {
          api_key: API_KEY,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setFolderList(res.data?.folders);
    setFileList(res.data?.files);
  };

  const fetchFolder = async () => {
    const res = await axios.get(
      NEW_API_URL + `filefolder?teacherId=${userObject?.data?.id}`,
      {
        headers: {
          api_key: API_KEY,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setFolderList(res.data);
  };

  return (
    <div className="mt-4">
      <TopBar
        refetch={() => {
          if (isRootPath) {
            fetchFolder();
          } else {
            fetchFilesAndFolders();
          }
        }}
      />

      <div className="w-100 bg-white">
        <div className="pb-3 pt-2 px-5">
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              color="inherit"
              href="/"
              style={{
                cursor: "pointer",
              }}
              onClick={(event) => {
                event.preventDefault(); // Prevent default link behavior
                history.push("/coursefile/1");
              }}
            >
              ຫນ້າຫລັກ
            </Link>
            {paths.length > 0 &&
              paths.map((path, index) => (
                <Link
                  style={{
                    cursor: "pointer",
                  }}
                  key={index}
                  onClick={(event) => {
                    event.preventDefault(); // Prevent default link behavior
                    onPathClick(path?._id); // Execute onPathClick function
                  }}
                  color="inherit"
                  href="/"
                >
                  {path?.folderName}
                </Link>
              ))}
          </Breadcrumbs>
        </div>
        {isRootPath ? (
          <div className="text-center mt-1">
            {folderList.length > 0 ? (
              <FileFolderList
                refetch={() => {
                  fetchFolder();
                }}
                folderList={folderList || []}
              />
            ) : (
              <div
                style={{
                  height: "90vh",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center", // Center horizontally
                  textAlign: "center", // Center text
                }}
              >
                <img
                  width="64"
                  height="64"
                  src="https://img.icons8.com/external-dreamstale-lineal-dreamstale/32/external-empty-science-education-dreamstale-lineal-dreamstale.png"
                  alt="external-empty-science-education-dreamstale-lineal-dreamstale"
                />
                <Typography
                  style={{
                    marginTop: "20px",
                    fontSize: "30px",
                    marginBottom: "10px",
                  }}
                >
                  ບໍ່ມີຂໍ້ມຸນ
                </Typography>
              </div>
            )}
          </div>
        ) : (
          <>
            {folderList.length > 0 || fileList.length > 0 ? (
              <>
                <FileFolderList
                  refetch={() => {
                    fetchFilesAndFolders();
                  }}
                  folderList={folderList || []}
                />
                <FileList
                  refetch={() => {
                    fetchFilesAndFolders();
                  }}
                  fileList={fileList || []}
                />
              </>
            ) : (
              <div
                style={{
                  height: "90vh",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center", // Center horizontally
                  textAlign: "center", // Center text
                }}
              >
                <img
                  width="64"
                  height="64"
                  src="https://img.icons8.com/external-dreamstale-lineal-dreamstale/32/external-empty-science-education-dreamstale-lineal-dreamstale.png"
                  alt="external-empty-science-education-dreamstale-lineal-dreamstale"
                />
                <Typography
                  style={{
                    marginTop: "20px",
                    fontSize: "30px",
                    marginBottom: "10px",
                  }}
                >
                  ຍັງບໍ່ມີຂໍ້ມູນເທື່ອ
                </Typography>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CourseFile;
