import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Form, Row, Col, ProgressBar } from "react-bootstrap";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import { useDropzone } from "react-dropzone";
import axios from "axios";
// component ------------------------------------------>
// import Loading from "../../common/Loading";
import Consts from "../../consts";
import { UPDATE_FILE, FILE, TEACHER_COURSES } from "../../apollo/file";
import { PRE_SIGNED_URL } from "../../apollo/filePDFUpload";
import { useSelector } from 'react-redux'
const CourseDocEdit = ({
  courseDocEditModal,
  _handlCourseDocEditModalClose,
  course,
}) => {
  const accessmentRedux = useSelector((state) => state.accessment.value);
  const [fileTitle, setFileTitle] = useState();
  const [files, setFiles] = useState();
  const [fileType, setFileType] = useState('')
  const [fileName, setFileName] = useState('')
  const [fileNameErr, setFileNameErr] = useState(false)
  const [classRoom, setClassRoom] = useState([""]);
  const [classRoomes, setClassRoomes] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const [fileUploadProgress, setFileUploadProgress] = useState(0);
  // apollo ------------------------------------------->
  const { data: fileData } = useQuery(FILE, {
    variables: { where: { id: course?.id ?? '' } },
  });
  const [loadFile,
    { data }
  ] = useLazyQuery(PRE_SIGNED_URL, {
    variables: { mimeType: fileType, fileName: fileName }
  })
  const [loadClass, { data: classes }] = useLazyQuery(TEACHER_COURSES, {
    variables:{where: { assessmentYear: accessmentRedux} }
  });
  const [updateFile] = useMutation(UPDATE_FILE);

  const onDrop = useCallback(async (acceptedFiles) => {
    // TODO: store files in state
    setFiles(acceptedFiles);
    let filePath = acceptedFiles[0].path.split('.')
    let _fileType
    if (filePath[filePath.length - 1] === 'jpg' || filePath[filePath.length - 1] === 'png') {
      _fileType = 'image/' + filePath[filePath.length - 1]
    } else {
      _fileType = 'application/' + filePath[filePath.length - 1]
    }
    setFileName(filePath[filePath.length - 2])
    setFileType(_fileType)
  }, []);

  useEffect(() => {
    loadFile()
  }, [fileType, fileName])

  useEffect(()=>{
    loadClass();
  }, [loadClass])
  useEffect(()=> {
    if(classes?.teacherCourses){
      const unquie = removeDuplicates(classes?.teacherCourses, 'id');
      setClassRoomes(unquie)
      
    }
  }, [classes])

  function removeDuplicates(arr, key) {
    const seen = new Set();
    return arr.filter(item => {
      console.log("item: " , item)
      const itemKey = item[key];
      if (!seen.has(itemKey)) {
        seen.add(itemKey);
        return true;
      }
      return false;
    });
  }

  useEffect(() => {
    if (fileData?.file?.title) {
      setFileTitle(fileData.file.title)
    }
    if (fileData?.file?.file) {
      setFileName(decodeURI(fileData.file.file.split('/')[4]))
    }
    setClassRoom(fileData?.file?.classRoom)
  }, [fileData])

  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
  } = useDropzone({ onDrop });

  const _fileUploaded = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} ຂະຫນາດ: {file.size} bytes
    </li>
  ));

  const _uploadFile = async (param) => {
    if(fileTitle=== undefined) {
      setFileNameErr(true)
    }

    setIsLoading(true)
    const { preSignedUrl } = data;
    // Save file to s3
    if (acceptedFiles.length > 0) {
      if (preSignedUrl) {
        if(fileTitle=== undefined) {
          setFileNameErr(true)
        }
        else{
        const response = await axios({
          method: "put",
          url: preSignedUrl.url,
          data: files[0],
          headers: {
            "Content-Type": " file/*; image/*",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
            "Access-Control-Allow-Headers":
              "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
          },
          onUploadProgress: function (progressEvent) {
            setFileUploadProgress(
              parseInt(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              )
            );
          },
        });
        let filename = response.config.url.split("?");

        let data = {
          file: filename[0],
          classRoom
        };

        // set data form when file is uploaded
        let paramQL = {
        };
        if (filename[0] && fileTitle) {
          if(fileTitle=== undefined) {
            setFileNameErr(true)
          }
          else{
          paramQL = {
            data: {
              title: fileTitle,
              ...data,
              classRoom,
            },
            where: {
              id: course.id,
            },
          };
        }
        } else {
          if(fileTitle=== undefined) {
            setFileNameErr(true)
          }
          else{
          paramQL = {
            data: {
              ...data,
              classRoom,
            },
            where: {
              id: course.id,
            },
          };
        }
        }
        paramQL.classRoom = classRoom;
        updateFile({ variables: paramQL }).then((data) => {
          setIsLoading(false)
          window.location.reload(true);
        });
      }
      }
    } else {
      if(fileTitle=== undefined) {
        setFileNameErr(true)
      }
      else{
      
      let paramQL = {
        data: {
          title: fileTitle,
          classRoom,
        },
        where: {
          id: course.id,
        },
      };
      updateFile({ variables: paramQL }).then((data) => {
        setIsLoading(false)
        window.location.reload(true);
      });
    }

    }
  };
  const handleCheck = (e)=>{
    let newArray = [];
    if(classRoom.length===0){
      newArray.push(e.target.value);
      setClassRoom(newArray);
    }else{
      const filter = classRoom.filter((el)=> el === e.target.value);
      if(filter.length===0){
        // console.log("No filter")
        setClassRoom([...classRoom, e.target.value]);
      }else{
        // console.log("Filter matched: " + filter)
        const filtered = classRoom.filter((el)=> el !== e.target.value);
        setClassRoom([...filtered]);
      }
      // console.log("Checking", e.target.value);
      // setClassRoom([...classRoom, e.target.value]);
      // console.log("Checking", classRoom);
    }
  }

  // if (isLoading) return <Loading />;

  return (
    <div>
      <Modal
        show={courseDocEditModal}
        onHide={_handlCourseDocEditModalClose}
        size="lg"
      >
        <Modal.Title style={{ textAlign: "center", paddingTop: 20 }}>
          ແກ້ໄຂ ເອກະສານບົດສອນ
        </Modal.Title>
        <Modal.Body style={{ marginLeft: 50, marginRight: 50, padding: 50 }}>

          {/* file updload box */}
          <div style={{ border: "1px solid #eee", padding: 20, width: "100%" }}>
            {/* ຫົວຂໍ້ */}
            <Form.Group
              as={Row}
              style={{
                margin: 0,
                marginBottom: 10,
                paddingLeft: 20,
                fontSize: 16,
              }}
            >
              <Form.Label column sm="4" className="text-left">
                ຫົວຂໍ້
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="text"
                  placeholder="ປ້ອນເພື່ອປ່ຽນ"
                  style={{ borderRadius: 0 }}
                  value={fileTitle}
                  onChange={(e) => setFileTitle(e.target.value)}
                />
                <div style={{color: 'red'}}>
                  <p>{!fileNameErr ? "" : "ກະລຸນາຂຽນຫົວຂໍ້" }</p>
                </div>
              </Col>
            </Form.Group>
            {/* ຫົວຂໍ້ */}
            <Form.Group
              as={Row}
              style={{
                margin: 0,
                marginBottom: 10,
                paddingLeft: 20,
                fontSize: 16,
              }}
            >
              <Form.Label column sm="4" className="text-left">
                ຫ້ອງຮຽນ
              </Form.Label>
              <Col sm="8">
              {classRoomes && classRoomes.length >0 && classRoomes.map((room,index)=>{
                  return <Form.Check
                    key={index}
                    type="checkbox"
                    className="text-left"
                    value={room.id}
                    label={ room.name }
                    checked={classRoom && classRoom.includes(room.id)}
                    onChange={handleCheck}
                  ></Form.Check>
                })}
              </Col>
            </Form.Group>

            {/* ອັບໂຫລດໄຟລ */}
            <Form.Group
              as={Row}
              style={{
                margin: 0,
                marginBottom: 10,
                paddingLeft: 20,
                fontSize: 16,
              }}
            >
              <Form.Label column sm="4" className="text-left">
                ອັບໂຫລດໄຟລ
              </Form.Label>
              <Col sm="8">
                <div
                  {...getRootProps()}
                  style={{
                    height: 100,
                    border: "1px solid #ddd",
                    outline: "none",
                    cursor: "pointer",
                    textAlign: "center",
                  }}
                  className="mb-5"
                >
                  <input {...getInputProps()} />

                  <div
                    style={{
                      backgroundColor: "#f3f3f3",
                      marginLeft: 20,
                      marginTop: 20,
                      marginRight: 20,
                      minHeight: 50,
                      textJustify: 'center',
                      textAlign: "center",
                    }}
                    className="mb-4"
                  >
                    {/* <img
                      alt="work right"
                      style={{ width: 50, height: 50 }}
                      src="/assets/download.png"
                    /> */}
                    <span>{fileName}</span>
                  </div>
                  <span>Drag and drop or Browse</span>
                  {/* {acceptedFiles.length === 0 && errFile ? (
                    <p style={{ color: "red", fontSize: 14, marginTop: 10 }}>
                      {errFile}
                    </p>
                  ) : (
                    ""
                  )} */}
                </div>
                {acceptedFiles.length > 0 && (
                  <aside>
                    <h4>ໄຟລທີ່ຈະອັບໂຫລດ</h4>
                    <ul>{_fileUploaded}</ul>
                  </aside>
                )}
                {fileUploadProgress > 0 && fileUploadProgress < 100 && (
                  <div>
                    <h3>ກໍາລັງອັບໂຫລດໄຟລເອກະສານ....</h3>
                    <ProgressBar
                      animated
                      now={fileUploadProgress}
                      label={`${fileUploadProgress}%`}
                    />
                  </div>
                )}
              </Col>
            </Form.Group>
          </div>

          <div style={{ height: 20 }} />
          <div className="row" style={{ textAlign: "center" }}>
            <div style={{ padding: 15 }} className="col">
              <Button
                onClick={_handlCourseDocEditModalClose}
                style={{
                  width: "60%",
                  backgroundColor: "#fff",
                  color: Consts.PRIMARY_COLOR,
                  borderColor: Consts.PRIMARY_COLOR,
                  borderRadius: 0,
                }}
              >
                ຍົກເລີກ
              </Button>
            </div>
            <div style={{ padding: 15 }} className="col">
              <Button
                style={{
                  width: "60%",
                  backgroundColor: Consts.PRIMARY_COLOR,
                  color: "#fff",
                  borderColor: Consts.PRIMARY_COLOR,
                  borderRadius: 0,
                }}
                onClick={_uploadFile}
              >
                ບັນທຶກການແກ້ໄຂ
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
CourseDocEdit.propTypes = {
  courseDocEditModal: PropTypes.bool.isRequired,
  _handlCourseDocEditModalClose: PropTypes.func.isRequired,
};

export default CourseDocEdit;
