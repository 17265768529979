import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";
import { useLazyQuery } from "@apollo/react-hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faTh } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import queryString from "query-string";
import { Breadcrumb, OverlayTrigger, Tooltip, Col, Row, Form } from "react-bootstrap";

// component ----------------------------------------------------------------------->
import Loading from "../../common/Loading";
// consts -------------------------------------------------------------------------->
import Consts from "../../consts";
import Routers from "../../consts/routes";
import { CustomContainer, Title, TableHeader, TableCell, } from "../../common";
import { QUERY_FACULTY, QUERY_DEPARTMENT, COURSES } from "./apollo/query";
import PaginationHelper from "../../helpers/PaginationHelper";

const CourseListAll = () => {
    const { history, match, location } = useReactRouter();
    const parsed = queryString?.parse(location?.state);
    const { _limit, _skip, Pagination_helper } = PaginationHelper();
    // states ------------------------------------------------------------->
    const [dataCourses, setDataCourses] = useState([]);
    const [totals, setTotals] = useState(0);
    const [courseCode, setCourseCode] = useState("");
    const [semester, setSemester] = useState(!parsed?.semester ? "1" : parsed?.semester);
    const [facultyArray, setFacultyArray] = useState([]);
    const [departmentArray, setDepartmentArray] = useState([]);
    const [dataFaculties, setDataFaculties] = useState([]);
    const [facultyId, setFacultyId] = useState(!parsed?.faculty ? "" : parsed?.faculty);
    const [dataDepartments, setDataDepartments] = useState([]);
    const [departmentId, setDepartmentId] = useState(!parsed?.department ? "" : parsed?.department);
    const [yearLevel, setYearLevel] = useState(!parsed?.yearLevel ? "1" : parsed?.yearLevel);
    // apollo ------------------------------------------------------------->
    const teacherId = match?.params?.id;
    const [loadDataFacuties, { data: facultiesData }] = useLazyQuery(QUERY_FACULTY, { fetchPolicy: "cache-and-network" });
    const [loadDataDepartment, { data: departmentsData }] = useLazyQuery(QUERY_DEPARTMENT, { fetchPolicy: "cache-and-network" });
    const [loadCourses, { loading, data: coursesData }] = useLazyQuery(COURSES, { fetchPolicy: "network-only" });

    // use ---------------------------------------------------------------->
    useEffect(() => {
        loadDataFacuties({ variables: { where: { isDeleted_not: true } } })
        loadCourses({
            variables: {
                where: { isDeleted_not: true, teacher_some: { id: teacherId }, year: parseInt(yearLevel) },
                skip: (_skip - 1) * _limit,
                first: _limit,
            }
        });
    }, []);

    useEffect(() => {

        if (coursesData) {
            setDataCourses(coursesData?.course_page?.data)
            setTotals(coursesData?.course_page?.total)
            var arrayFaculty = []
            var arrayDepartment = []
            for (var i = 0; i < coursesData?.course_page?.data?.length; i++) {
                arrayFaculty.push({ id: coursesData?.course_page?.data[i]?.faculty?.id })
            }
            for (var i = 0; i < coursesData?.course_page?.data?.length; i++) {
                arrayDepartment.push({ id: coursesData?.course_page?.data[i]?.department?.id })
            }
            setFacultyArray(arrayFaculty)
            setDepartmentArray(arrayDepartment)
        }
    }, [coursesData]);

    useEffect(() => {
        if (facultiesData)
            setDataFaculties(facultiesData?.faculties)
    }, [facultiesData]);

    useEffect(() => {
        if (facultyId)
            loadDataDepartment({ variables: { where: { faculty: { id: facultyId } } } })
    }, [facultyId]);

    useEffect(() => {
        if (departmentsData)
            setDataDepartments(departmentsData?.departments)
    }, [departmentsData]);

    useEffect(() => {
        let _where = { isDeleted_not: true, teacher_some: { id: teacherId } }
        if (facultyId !== "") _where = { ..._where, course: { faculty: { id: facultyId } } }
        if (departmentId !== "") _where = { ..._where, course: { department: { id: departmentId } } }
        if (semester !== "") _where = { ..._where, semester: parseInt(semester) }
        if (yearLevel !== "") _where = { ..._where, year: parseInt(yearLevel) }
        if (courseCode) _where = { ..._where, courseCode_contains: courseCode  }

        loadCourses({
            variables: {
                where: _where,
                skip: (_skip - 1) * _limit,
                first: _limit,
            }
        });

    }, [facultyId, departmentId, yearLevel, courseCode, semester])

    // Search

    const _selectFaculty = (id) => {
        setFacultyId(id.target.value);
    }
    const _onSelectedDepartment = (id) => {
        setDepartmentId(id?.target?.value)
    }

    const _onSelectedYear = (year) => {
        setYearLevel(year.tartget.value);
    };

    const _onSelectedSemester = (setSemester) => {
        setSemester(setSemester.target.value);
    }

    const _showTheFacultyAccordingToTheTeacher = (id) => {
        const _showThe = facultyArray.filter(fac => fac?.id === id)
        if (_showThe.length > 0) return false
        else return true
    }
    const _showTheDepartmentAccordingToTheTeacher = (id) => {
        const _showTheDepartment = departmentArray.filter(dep => dep?.id === id)
        if (_showTheDepartment.length > 0) return false
        else return true
    }
    const _onCourseDetail = (id) => {
        history.push(`/course-detail/id/${id}`);
    };
    return (
        <div>
            <Breadcrumb>
                <Breadcrumb.Item onClick={() => history.push("/course-lists/1")}>
                    ວິຊາທີສອນ
                </Breadcrumb.Item>
                <Breadcrumb.Item active style={{ fontWeight: "bolder" }}>
                    ຈຳນວນວິຊາທັງໝົດ
                </Breadcrumb.Item>
            </Breadcrumb>
            <CustomContainer>
                <Title text={"ລາຍການວິຊາອາຈານສອນ"} />
                <Row>
                    <Col md={12} style={{ marginBottom: 20, marginTop: 10 }}>
                        <Form.Group
                            as={Row}
                            style={{
                                margin: 0,
                                marginTop: 10,
                                marginBottom: 10,
                                alignItems: "center",
                                fontSize: 16,
                            }}
                        >
                            <Form.Label column sm="1" className="text-left">
                                ຄົ້ນຫາ
                            </Form.Label>
                            <Col md="11">
                                <Form.Control
                                    style={{ borderRadius: 50 }}
                                    placeholder="ປ້ອນໄອດີລະຫັດວິຊາ"
                                    type="text"
                                    onChange={(e) => setCourseCode(e.target.value)}
                                />
                            </Col>
                        </Form.Group>
                    </Col>
                    <Col sm={3}>
                        <label>ຄະນະວິຊາ</label>
                        <Form.Control
                            as="select"
                            style={{ border: "none", backgroundColor: "#f1f1f1f1", cursor: "pointer" }}
                            value={facultyId}
                            onChange={(e) => { _selectFaculty(e) }}
                        >
                            <option value="">ຄະນະທັງໝົດ</option>
                            {dataFaculties?.map((item, index) => {
                                return (<option disabled={_showTheFacultyAccordingToTheTeacher(item?.id)} key={index} value={item.id}> {item.name}</option>);
                            })}
                        </Form.Control>
                    </Col>
                    <Col sm={3}>
                        <label>ສາຂາວິຊາ</label>
                        <Form.Control
                            as="select"
                            style={{
                                border: "none",
                                backgroundColor: "#f1f1f1f1",
                                cursor: "pointer",
                            }}
                            value={departmentId}
                            onChange={(e) => _onSelectedDepartment(e)}
                        >
                            <option value="">ທັງໝົດ</option>
                            {dataDepartments?.map((item, index) => {
                                return (
                                    <option disabled={_showTheDepartmentAccordingToTheTeacher(item?.id)} key={index} value={item.id}>{item.name}</option>
                                );
                            })}
                        </Form.Control>
                    </Col>
                    <Col sm={3}>
                        <label>ປີຮຽນ</label>
                        <Form.Control
                            as="select"
                            style={{
                                border: "none",
                                backgroundColor: "#f1f1f1f1",
                                cursor: "pointer",
                            }}
                            value={yearLevel}
                            onChange={(e) => _onSelectedYear(e)}
                        >
                            <option value={""}>ທັງໝົດ</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                        </Form.Control>
                    </Col>

                    <Col sm={3}>
                        <label>ພາກຮຽນ</label>
                        <Form.Control
                            as="select"
                            style={{
                                border: "none",
                                backgroundColor: "#f1f1f1f1",
                                cursor: "pointer",
                            }}
                            value={semester}
                            onChange={(semester) => _onSelectedSemester(semester)}
                        >
                            <option value={""}>ທັງໝົດ</option>
                            <option value="1">ພາກຮຽນ 1</option>
                            <option value="2">ພາກຮຽນ 2</option>
                        </Form.Control>
                    </Col>
                </Row>
                <div
                    style={{
                        marginTop: 24,
                        marginBottom: 8,
                        fontSize: 16,
                        color: Consts.FONT_COLOR_SECONDARY,
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    ທັງຫມົດ {totals} ຄົນ
                </div>
                {/* Table list */}
                <div>
                    {loading ? <Loading /> :
                        <table border="1" bordercolor="#fff" style={{ width: "100%" }}>
                            <thead>
                                <TableHeader style={{ backgroundColor: "#057CAE" }}>
                                    <th style={{ width: 70 }}>ລຳດັບ</th>
                                    <th>ລະຫັດນັກສຶກສາ</th>
                                    <th>ຊື່ນັກສຶກສາ</th>
                                    <th>ນັກສຶກສາປີ</th>
                                    <th>ພາກຮຽນ</th>
                                    <th>ສົກຮຽນ</th>
                                    <th>ຊື່ວິຊາທີ່ລົງທະບຽນ</th>
                                    <th style={{ width: 200 }}>ຈັດການ</th>
                                </TableHeader>
                            </thead>
                            {dataCourses?.map((item, index) => {
                                return (
                                    <tbody key={index}>
                                        <tr style={{ border: "2px solid white", textAlign: "center", backgroundColor: "#EEEEEE", }} key={index}>
                                            <TableCell> {index + 1 + _limit * (_skip - 1)} </TableCell>
                                            <TableCell>{item?.courseCode}</TableCell>
                                            <TableCell>{item?.title}</TableCell>
                                            <TableCell>{item?.year}</TableCell>
                                            <TableCell>{item?.semester}</TableCell>
                                            <TableCell>{item?.faculty?.name}</TableCell>
                                            <TableCell>{item?.department?.name}</TableCell>
                                            <TableCell>
                                                <center>
                                                    <OverlayTrigger
                                                        overlay={
                                                            <Tooltip id="tooltip-disabled">
                                                                ເບິ່ງລາຍລະອຽດເພີ່ມເຕີມ
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <div
                                                            onClick={() => _onCourseDetail(item?.id)}
                                                            style={{
                                                                cursor: "pointer",
                                                                backgroundColor: "#FFFFFF",
                                                                padding: 3,
                                                                width: 64,
                                                                borderRadius: 4,
                                                            }}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={["fas", "eye"]}
                                                                color="#7BB500"
                                                            />
                                                        </div>
                                                    </OverlayTrigger>
                                                </center>
                                            </TableCell>
                                        </tr>
                                    </tbody>
                                );
                            })
                            }
                        </table>}
                    {Pagination_helper(totals, Routers.COURSE_LIST_ALL+ "/id/" + teacherId, `faculty=${facultyId}&&department=${departmentId}&&yearLevel=${yearLevel}&&semester=${semester}`)}
                </div>
            </CustomContainer>
        </div>
    );
};

export default CourseListAll;
