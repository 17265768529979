import React, {useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import { Formik } from "formik";
import moment from 'moment'
import TextField from "@material-ui/core/TextField";
import { CustomButton } from "../../common";

const DocSearch = ({ showSearchView, _handleSearchViewClose, onSearch, startDay, endDay }) => {

  useEffect(() => {
    onSearch({});
  }, []);

  return (
    <Formik
      initialValues={{
        keyword: "",
        createdName: "",
        startDate: startDay,
        endDate: endDay
      }}
      onSubmit={(values) => {
        onSearch(values);
        _handleSearchViewClose();
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
          <Modal show={showSearchView} onHide={_handleSearchViewClose} size="lg">
            <Modal.Title style={{ textAlign: "center", paddingTop: 20 }}>
              ຄົ້ນຫາເອກະສານ
          </Modal.Title>

            <Modal.Body style={{ marginLeft: 50, marginRight: 50, padding: 50 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ marginRight: 20, fontWeight: "bold", width: "20%" }}>ຊື່ເອກະສານ</div>
                <div style={{ width: "80%" }}>
                  <Form.Control
                    type="text"
                    placeholder="ປ້ອນຊື່ເອກະສານ"
                    name="createdName"
                    value={values.createdName}
                    onChange={handleChange}
                    style={{
                      width: "100%",
                      height: 35,
                      borderRadius: 15,
                      outline: "none",
                      borderColor: "1px solid #eee",
                    }}
                  />
                </div>
              </div>
              <div style={{ height: 20 }} />
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ marginRight: 20, fontWeight: "bold" }}>ວັນທີ</div>
                <div style={{ width: "50%" }}>
                  <TextField
                    id="date"
                    type="date"
                    name="startDate"
                    defaultValue={moment(values.startDate).format("YYYY-MM-DD")}
                    // onChange={(e) => handleStartDateChange(e.target.value)}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                {/* <div style={{ width: 20, textAlign: "center" }}> - </div> */}
                <div style={{ width: "50%" }}>
                  <TextField
                    id="date"
                    type="date"
                    name="endDate"
                    defaultValue={moment(endDay).format("YYYY-MM-DD")}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
              </div>
              <div style={{ height: 20 }} />
              {/* <hr />
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ marginRight: 20, marginTop: 5 }}>ຄຳສັບ</div>
                <div style={{ width: "100%" }}>
                  <input
                    type="text"
                    name="keyword"
                    placeholder="ຖານຂໍ້ມູນ, ບົດຄົ້ນຄ້ວາ"
                    style={{
                      width: "100%",
                      padding: "6px 12px",
                      borderRadius: 30,
                      border: "1px solid #eee",
                      outline: "none",
                    }}
                    value={values.keyword}
                    onChange={handleChange}
                  />
                  <FontAwesomeIcon
                    icon={["fa", "search"]}
                    style={{
                      position: "absolute",
                      marginTop: 10,
                      cursor: "pointer",
                      marginLeft: -35,
                      color: Consts.PRIMARY_COLOR
                    }}
                  />
                </div> */}
              {/* </div> */}
              <div style={{ height: 20 }} />
              <div className="row">
                <div style={{ padding: 15 }} className="col">
                  <CustomButton
                    confirm
                    onClick={handleSubmit}
                    width="100%"
                    title="ຄົ້ນຫາ"
                  />
                </div>
              </div>
            </Modal.Body>
          </Modal>
        )}
    </Formik>
  );
};

export default DocSearch;
