module.exports = {
    PAGE_GINATION: '/limit/10/skip/1',
    // Teacher
    DOCUMENT_LIST: '/document-list',
    DOCUMENT_DETAIL: '/document-detail',
    // courses
    COURSE_LISTS: '/course-lists',
    COURSE_LIST_ALL: '/course-list-all',
    // register
    REGISTER_ALL: '/register-all',
    // gradeList
    GRADE_LIST: '/grades-list',
    UPGRADE_LIST: '/upgrade-list',
    STUDENT_FOR_GRADE: '/student-for-grade'
}