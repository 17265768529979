import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";
import { useSelector } from 'react-redux'

import "./index.css";
import Empty from "./Empty";
import Notiflix from "notiflix";
import { Modal, Button, Form, OverlayTrigger, Tooltip, Col, Row,} from "react-bootstrap";
import { CustomContainer, TableHeader } from "../../common";
import { Formik } from "formik";
import * as _ from "lodash";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";

import { UPDATE_GRADE, UPDATE_GRADE_COURSE, GRADE_COURSES, SEND_GRADE_COURSE, FACULTY_NAME} from "../../apollo/grade/index";
import { convertLetterGrade } from "../../common/super";
import { CALL_COURSE_DATA, COURSES_FACULTY_DEPARTMENT_FOR_TEACHER } from "../../apollo/course/index";
import Loading from "../../common/Loading";
import { QUERY_DEPARTMENT, QUERY_MAJORS } from "../courseList/apollo/query";
import { USER_KEY } from "../../consts/user";
import { CLASSROOM_MASTERS } from "../../apollo/classroom";

function UpGradeForStudent() {
  const { history, location, match } = useReactRouter();
  const accessmentRedux = useSelector((state) => state.accessment.value)
  
  const data = location.state;
  const couseId = location.state.id;

  const pageNambers = match?.params?.page;
  const PAGE_LIMIT = 50;

  // state to set grade
  const [teacherId, setTeacherId] = useState("");
  const [factId, setFactId] = useState();
  const [departmentId, setDepartmentId] = useState();
  const [selectedYear, setSelectedYear] = useState();
  const [selectSemester, setSelectSemester] = useState();

  const [dataDepartments, setDataDepartments] = useState([]);
  const [facultyArray, setFacultyArray] = useState([]);
  const [departmentArray, setDepartmentArray] = useState([]);
  const [dataFaculties, setDataFaculties] = useState([]);

  // major
  const [majorId, setMajorId] = useState("");
  const [dataMajors, setDataMajors] = useState([]);
  const [majorArray, setMajorArray] = useState([]);

  // classroom
  const [classroomId, setClassroomId] = useState("");
  const [dataClassrooms, setDataClassrooms] = useState([]);

  const [confirmGrade, setConfirmGrdae] = useState(false);
  const [confirmAllGrade, setConfirmAllGrade] = useState(false);
  const [confirmSentGrade, setConfirmSentGrade] = useState(false);

  const [gradeData, setGradeData] = useState([]);
  const [checkStatus, setCheckStatus] = useState([]);
  const [whenClick, setWhenClick] = useState([]);

  const [dataUser, setDataUser] = useState(null);
  const [updateGrade] = useMutation(UPDATE_GRADE);
  const [updateGradeCourse] = useMutation(UPDATE_GRADE_COURSE);
  const [loadGrades, { data: gradeCourseData, loading}] = useLazyQuery(GRADE_COURSES, {fetchPolicy:"network-only"});

  const [sendGradeCourse] = useMutation(SEND_GRADE_COURSE);

  const _grade = (index, id) => {
    setConfirmGrdae(!confirmGrade);
  };

  // query course
  const [ loadFaculties,{data: facultyData }] = useLazyQuery(FACULTY_NAME);
  const [loadDataDepartment, { data: departmentsData }] = useLazyQuery(QUERY_DEPARTMENT, { fetchPolicy: "network-only" });
  const [loadMajors, { data: majorData }] = useLazyQuery(QUERY_MAJORS, {fetchPolicy: "network-only"});
  const [loadClassroom, { data: classroomData }] = useLazyQuery(CLASSROOM_MASTERS,{ fetchPolicy: "network-only" });

  const [ loadCourse] = useLazyQuery(CALL_COURSE_DATA);
  const [loadCoursesFacultyDepartment, { data: coursesAndFacultyAndDepartmentData }] = useLazyQuery(COURSES_FACULTY_DEPARTMENT_FOR_TEACHER);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem(USER_KEY));
    setTeacherId(user?.data?.id);
    loadFaculties({ variables: { where: { isDeleted: false } } });
    loadCourse();
  }, []);

  useEffect(() => {
    loadCoursesFacultyDepartment({ variables: { where: { teacher: teacherId} } });
  }, [teacherId]);

  useEffect(() => {

    if (coursesAndFacultyAndDepartmentData) {
      var arrayFaculty = []
      var arrayDepartment = []
      var arrayMajor = []
      for (var i = 0; i < coursesAndFacultyAndDepartmentData?.courseAndFacultyAndDepartmentAndMajorFromTeacher?.dataFaculty?.length; i++) {
        arrayFaculty.push({ id: coursesAndFacultyAndDepartmentData?.courseAndFacultyAndDepartmentAndMajorFromTeacher?.dataFaculty[i]?.id })
      }
      for (var i = 0; i < coursesAndFacultyAndDepartmentData?.courseAndFacultyAndDepartmentAndMajorFromTeacher?.dataDepartment?.length; i++) {
        arrayDepartment.push({ id: coursesAndFacultyAndDepartmentData?.courseAndFacultyAndDepartmentAndMajorFromTeacher?.dataDepartment[i]?.id })
      }
      for (var i = 0; i < coursesAndFacultyAndDepartmentData?.courseAndFacultyAndDepartmentAndMajorFromTeacher?.dataMajor?.length; i++) {
        arrayMajor.push({ id: coursesAndFacultyAndDepartmentData?.courseAndFacultyAndDepartmentAndMajorFromTeacher?.dataMajor[i]?.id })
      }
      setFacultyArray(arrayFaculty)
      setDepartmentArray(arrayDepartment)
      setMajorArray(arrayMajor)
    }
  }, [coursesAndFacultyAndDepartmentData]);
 useEffect(() => {
  let _where = { course: data?.id, numberTest: 2, gredeStatus_in: "APROVED",}
  if(factId) _where = {..._where, faculty: factId}
  if(departmentId) _where = {..._where, department: departmentId}
  if(majorId) _where = { ..._where, major: majorId };
  if(classroomId) _where = { ..._where, classRoom: classroomId };
  if(selectedYear) _where = {..._where, yearLevel: parseInt(selectedYear)}
  if(selectSemester) _where = {..._where, semester: parseInt(selectSemester)}
  loadGrades({
    variables: {
      where: _where
        // sendingGradeStatus_in: ["TEACHER_SCORING", "TEACHER_REJECTED"]
      },
  });
 },[factId, departmentId, majorId, classroomId, selectedYear, selectSemester])

  useEffect(() => {
    if (gradeCourseData?.grades?.data) {
      setGradeData(gradeCourseData?.grades?.data);
    }
  }, [gradeCourseData]);

  useEffect(() => {
    let grade = [];
    gradeData.map((x, index) => {
      grade.push(x.gradeType);
    });
  }, [gradeData]);
  useEffect(() => {
    if (facultyData) {
      setDataFaculties(facultyData?.faculties)
    }
}, [facultyData]);

  useEffect(() => {
    if (departmentsData) {
      setDataDepartments(departmentsData?.departments)
    }
  }, [departmentsData]);
  
  useEffect(() => {
    if (majorData) setDataMajors(majorData?.majors?.data);
  }, [majorData]);

  useEffect(() => {
    if (classroomData) setDataClassrooms(classroomData?.classRooms?.data);
  }, [classroomData]);
  useEffect(() => {
    if (majorId) {
      let _where = { major: majorId };
      if (selectedYear !== "") _where = { ..._where, year: selectedYear };
      loadClassroom({ variables: { where: _where } });
    }
  }, [majorId, selectedYear]);

  // select faculty ad get value
  const _selectFaculty =async (e) => {
    let _facultyId = e?.target?.value
    setFactId(e.target.value);
    if (_facultyId === "") {
      setDataDepartments([]);
      setDataMajors([]);
      setMajorId("");
      setDataClassrooms([]);
      setClassroomId("");
    }
    else {
      await loadDataDepartment({ variables: { where: { faculty:_facultyId } } })
    }
  };
  // select department
  const _onSelectedDepartment =async (e) => {
    let _departmentId = e?.target?.value
    setDepartmentId(_departmentId);
    if (_departmentId === "") {
      setDataMajors([]);
      setMajorId("");
      setDataClassrooms([]);
      setClassroomId("");
    } else {
      loadMajors({ variables: { where: { department: _departmentId } } });
    }
  };

  const _onSelectedMajor = async (e) => {
    setClassroomId("");
    let _majorId = e?.target?.value;
    setMajorId(_majorId);
    if (_majorId === "") {
      setDataClassrooms([]);
    }
  };
  const _onSelectedClassroom = async (e) => {
    setClassroomId(e?.target?.value);
  };
  // select yaer
  const _onSelectedYear = (e) => {
    setClassroomId("");
    setSelectedYear(e.target.value);
    if (e.target.value === "") {
      setDataClassrooms([]);
    }
  };
  // select unit
  const _onSelectedSemester = (e) => {
    setSelectSemester(e.target.value);
  };
  const _showTheFacultyAccordingToTheTeacher = (id) => {
    const _showThe = facultyArray.filter(fac => fac?.id === id)
    if (_showThe.length > 0) return false
    else return true
  }
  const _showTheDepartmentAccordingToTheTeacher = (id) => {
    const _showTheDepartment = departmentArray.filter(dep => dep?.id === id)
    if (_showTheDepartment.length > 0) return false
    else return true
  }
  const _showTheMajorAccordingToTheTeacher = (id) => {
    const _showTheMajor = majorArray.filter((major) => major?.id === id);
    if (_showTheMajor.length > 0) return false;
    else return true;
  };

  // pagegination
  useEffect(() => {
    var gradeCount = gradeData?.grades?.total;
    const countPages = [];
    for (var i = 1; i <= Math.ceil(gradeCount / PAGE_LIMIT); i++) {
      countPages.push(i);
    }
  }, [gradeData]);

  // Grade function
  const _detectGrade = (gradeType, name) => {
    let score = gradeType.find((x) => x.name === name)?.score;
    return score;
  };

  const _saveOneGrade = async (grade) => {

    updateGrade({
      variables: {
        data: {
          gredeStatus: "FINISHED",
          numbericGrade: grade?.numbericGrade,
        },
        where: { id: grade.id },
      },
    })
      .then(() => {
        messageSuccess("ຢືນຢັນແລ້ວ");
      })
      .catch((e) => {
        messageError("ບໍ່ສາມາດແກ້ໄຂໄດ້");
        // window.location.reload();
      });
  };

  async function _updateAllGrade() {
    sendingLoad("ການດຳເນີນຕ້ອງໃຊ້ເວລາ, ກະລຸນາລໍຖ້າ");
    gradeData.map(async (grade, index) => {
      await _saveOneGrade(grade);
    });
  }

  const _sendGrade = async () => {
    await sendGradeCourse({
      variables: {
        where: {
          course: couseId,
          faculty: factId,
          department: departmentId,
          yearLevel: parseInt(selectedYear),
          assessmentYear: accessmentRedux,
          semester: selectSemester
        },
        data: {
          sendingGradeStatus: "TEACHER_SUBMITED",
        },
      },
    })
      .then(() => {
        alertSuccess("ການສົ່ງຄະແນນຂອງທ່ານສຳເລັດແລ້ວ");
      })
      .catch((err) => {
        alertWarning("ການສົ່ງຄະແນນຂອງທ່ານລົ້ມແຫຼວ");
      });
  };

  const _totalGrade = (grades) => {
    let result = 0;
    grades.map((_type, _index) => {
      result += parseInt(_type?.numbericGrade ?? "");
    });
    return result;
  };

  const messageSuccess = (e) => {
    Notiflix.Notify.success(e);
  };

  const messageError = (e) => {
    Notiflix.Notify.failure(e);
    // Notiflix.Notify.Init({ position: "right-bottom" });
  };

  const sendingLoad = (e) => {
    Notiflix.Loading.standard(e);
  };

  const alertSuccess = (e) => {
    return Notiflix.Report.success(
      "ສຳເລັດ", e, "ອອກ",
      function () {
        window.location.reload();
      }
    );
  };

  const alertWarning = (e) => {
    return Notiflix.Report.success(
      'ບໍ່ສຳເລັດ', e, "ອອກ",
      function () {
        window.location.reload();
      },
    );
  };

  const setGenders = (SetGender) => {
    let res = "";
    switch (SetGender) {
      case "FEMALE":
        res = "ນາງ";
        break;
      case "MALE":
        res = "ທ້າວ";
        break;
      case "OTHER":
        res = "ອື່ນໆ";
        break;
    }
    return res;
  };

  return (
    <div>
        <div>
          {loading ? (
            <Loading />
          ) : false ? (
            <CustomContainer>
              <Empty />
            </CustomContainer>
          ) : (
            <CustomContainer>
              <div
                className="col-sm-4"
                style={{ display: "block", marginTop: 5 }}
              >
                <span
                  style={{
                    display: "inline-block",
                    fontSize: 24,
                    color: "#057CAE",
                    fontWeight: "bold",
                  }}
                >
                </span>
              </div>

              <Row style={{marginBottom:30}}>
                <Col sm={3}>
                  <label>ຄະນະວິຊາ</label>
                  <Form.Control
                    as="select"
                    style={{ cursor: "pointer" }}
                    value={factId}
                  onChange={(e) => { _selectFaculty(e)}}
                  >
                    <option value="">ຄະນະທັງໝົດ</option>
                    {dataFaculties?.map((item, index) => {
                      return (
                        <option
                          disabled={_showTheFacultyAccordingToTheTeacher(item?.id)}
                          key={index}
                          value={item.id}>
                              {item.name}
                        </option>);
                    })}
                  </Form.Control>
                </Col>
                <Col sm={3}>
                  <label>ພາກວິຊາ</label>
                  <Form.Control
                    as="select"
                    style={{cursor: "pointer"}}
                    value={departmentId}
                    onChange={(e) => _onSelectedDepartment(e)}
                  >
                    <option value="">ທັງໝົດ</option>
                    {dataDepartments?.map((item, index) => {
                      return (
                          <option disabled={_showTheDepartmentAccordingToTheTeacher(item?.id)} key={index} value={item.id}>{item.name}</option>
                      );
                    })}
                  </Form.Control>
                </Col>
                <Col sm={3}>
                  <label>ສາຂາວິຊາ</label>
                  <Form.Control
                    as="select"
                    style={{cursor: "pointer"}}
                    value={majorId}
                    onChange={(e) => _onSelectedMajor(e)}
                  >
                    <option value="">ທັງໝົດ</option>
                    {dataMajors?.map((item, index) => {
                      return (
                        <option disabled={_showTheMajorAccordingToTheTeacher(item?.id)} key={index} value={item.id}>{item.name}</option>
                      );
                    })}
                  </Form.Control>
                </Col>
                <Col sm={3}>
                  <label>ປີຮຽນ</label>
                  <Form.Control
                    as="select"
                    style={{cursor: "pointer"}}
                    value={selectedYear}
                    onChange={(e) => _onSelectedYear(e)}
                  >
                    <option value={""}>ທັງໝົດ</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                  </Form.Control>
                </Col>
              </Row>
              <Row>
                <Col sm={3}>
                  <label>ພາກຮຽນ</label>
                  <Form.Control
                    as="select"
                    style={{cursor: "pointer"}}
                    value={selectSemester}
                  onChange={(e) => _onSelectedSemester(e)}
                  >
                    <option value={""}>ທັງໝົດ</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                  </Form.Control>
                </Col>
                <Col sm={3}>
                  <label>ຫ້ອງນັກສຶກສາ</label>
                  <Form.Control
                    as="select"
                    style={{ cursor: "pointer" }}
                    value={classroomId}
                    onChange={(e) => _onSelectedClassroom(e)}
                  >
                    <option value="">ທັງໝົດ</option>
                    {dataClassrooms?.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Col>
              </Row>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                ທັງຫມົດ {gradeData?.length} ຄົນ
                {/* {dataUser?.role === "ADMIN" ? */}
                <div>
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        ຢືນຢັນການແກ້ໄຂຄະແນນທັງໝົດ
                      </Tooltip>
                    }
                  >
                    <Button
                      type="submit"
                      onClick={() => {
                        setConfirmAllGrade(!confirmAllGrade);
                      }}
                      className="btn btn-info"
                      style={{ width: "200px" }}
                    >
                      ຢືນຢັນທັງໝົດ
                    </Button>
                  </OverlayTrigger>
                  &nbsp;&nbsp;&nbsp;
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        ສົ່ງຄະແນນໄປຫາພາກວິຊາ
                      </Tooltip>
                    }
                  >
                    <Button
                      type="submit"
                      onClick={() => {
                        setConfirmSentGrade(!confirmSentGrade);
                      }}
                      className="btn btn-success"
                      style={{ width: "200px" }}
                    >
                      ສົ່ງຄະແນນ
                    </Button>
                  </OverlayTrigger>
                </div>
                {/* //  : ""} */}
              </div>

              {/* Table list */}
              {gradeData && (
                <Formik
                  initialValues={{}}
                  onSubmit={(values, { setSubmitting }) => { }}
                >
                  {({ resetForm }) => (
                    <div>
                      <table
                        className="table table-bordered "
                        style={{ width: "100%" }}
                      >
                        <thead>
                          <TableHeader>
                            <th style={{ width: "5%" }}>ລຳດັບ</th>
                            <th style={{ width: "18%" }}>ຊື່ ແລະ ນາມສະກຸນ</th>
                            <th width="8%">ຄະແນນລວມ</th>
                            <th style={{ width: "5%" }}>ເກຣດ</th>
                            <th style={{ width: "19%" }}>ຈັດການ</th>
                          </TableHeader>
                        </thead>
                        <tbody>
                          {loading ? (
                            <Loading />
                          ) : (
                            gradeData?.map((grade, index) => {
                              return (
                                <tr
                                  key={index}
                                  style={{
                                    backgroundColor:
                                      grade?.numbericGrade !== null
                                        ? "#fffff"
                                        : "#f0f0ff",
                                  }}
                                >
                                  <td className="center">
                                    {(parseInt(pageNambers) - 1) * PAGE_LIMIT +
                                      index +
                                      1}
                                  </td>
                                  <td>
                                    {setGenders(grade?.student?.gender) ?? "-"}{" "}
                                    &nbsp;
                                    {grade?.student?.firstNameL ??
                                      "-"} &nbsp;{" "}
                                    {grade?.student?.lastNameL ?? "-"}
                                  </td>
                                  <td>
                                    <OverlayTrigger
                                      overlay={
                                        <Tooltip id="tooltip-disabled">
                                          ຄະແນນສູງສຸດ 100
                                        </Tooltip>
                                      }
                                    >
                                      <Form.Control
                                        disabled={
                                          // dataUser?.role !== "ADMIN" ? true :
                                          // grade?.numbericGrade !== null ? false :
                                          whenClick[index] === index - 1
                                            ? true
                                            : checkStatus[index] === index
                                              ? false
                                              : true
                                        }
                                        max={100}
                                        min={0}
                                        className="center"
                                        type="number"
                                        placeholder={
                                          gradeData[index].numbericGrade ?? "0"
                                        }
                                        onChange={(e) => {
                                          gradeData[index].numbericGrade =
                                            parseInt(e.target.value);
                                          let _newGradeData = [...gradeData];
                                          setGradeData(_newGradeData);
                                        }}
                                        value={gradeData[index].numbericGrade}
                                      />
                                    </OverlayTrigger>
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    <OverlayTrigger
                                      overlay={
                                        <Tooltip id="tooltip-disabled">
                                          ສູງສຸດເກຣດ A
                                        </Tooltip>
                                      }
                                    >
                                      <input
                                        type="text"
                                        disabled={true}
                                        className="w-100 grade-type"
                                        style={{ textAlign: "center" }}
                                        name={gradeData?.letterGrade}
                                        value={convertLetterGrade(
                                          gradeData[index].numbericGrade
                                        )}
                                      />
                                    </OverlayTrigger>
                                  </td>
                                  {/* {dataUser?.role === "ADMIN" ? */}
                                  <td style={{ textAlign: "center" }}>
                                    {whenClick[index] === index ? (
                                      <button
                                        className="btn btn-outline-success"
                                        onClick={async (e) => {
                                          let conn = [...checkStatus];
                                          conn[index] = index - 1;
                                          setCheckStatus(conn);
                                          let setwhen = [...whenClick];
                                          setwhen[index] = index - 1;
                                          setWhenClick(setwhen);
                                          await _grade(index, grade.id);
                                          await _saveOneGrade(grade);
                                          resetForm({});
                                        }}
                                        style={{ fontWeight: "bold" }}
                                      >
                                        <i className="fa fa-check-circle"></i>
                                        &nbsp;&nbsp; ຢືນຢັນ
                                      </button>
                                    ) : grade?.numbericGrade !== null &&
                                      checkStatus[index] === index ? (
                                      <button
                                        className="btn btn-outline-success"
                                        onClick={async (e) => {
                                          let conn = [...checkStatus];
                                          conn[index] = index - 1;
                                          setCheckStatus(conn);
                                          let setwhen = [...whenClick];
                                          setwhen[index] = index - 1;
                                          setWhenClick(setwhen);
                                          await _grade(index, grade.id);
                                          await _saveOneGrade();
                                          resetForm({});
                                        }}
                                        style={{ fontWeight: "bold" }}
                                      >
                                        <i className="fa fa-check-circle"></i>
                                        &nbsp;&nbsp; ຢືນຢັນ
                                      </button>
                                    ) : (
                                      ""
                                    )}

                                    {whenClick[index] === index ||
                                      grade?.sendingGradeStatus ==
                                      "TEACHER_SUBMITED" ||
                                      grade?.sendingGradeStatus ==
                                      "DEPARTMENT_CHECKING" ||
                                      grade?.sendingGradeStatus ==
                                      "DEPARTMENT_SUBMITED" ||
                                      grade?.sendingGradeStatus ==
                                      "DEPARTMENT_REJECTED" ||
                                      grade?.sendingGradeStatus ==
                                      "FACULTY_CHECKING" ||
                                      grade?.sendingGradeStatus ==
                                      "FACULTY_SUBMITED" ||
                                      grade?.sendingGradeStatus ==
                                      "FACULTY_REJECTED" ||
                                      grade?.sendingGradeStatus ==
                                      "ADMIN_CHECKING" ||
                                      grade?.sendingGradeStatus ==
                                      "ADMIN_APPROVED" ? (
                                      ""
                                    ) : (
                                      <button
                                        className="btn btn-outline-info"
                                        onClick={() => {
                                          let conn = [...checkStatus];
                                          conn[index] = index;
                                          setCheckStatus(conn);
                                          let setwhen = [...whenClick];
                                          setwhen[index] = index;
                                          setWhenClick(setwhen);
                                        }}
                                      >
                                        ແກ້ໄຂ
                                      </button>
                                    )}
                                  </td>
                                  {/* : <td style={{
                                          color: "#FF8819", textAlign: "center", marginTop: "4px",
                                          fontWeight: "bold"
                                        }}><i className="fa fa-exclamation-triangle"></i>&nbsp;&nbsp;ບໍ່ມີສິດໃຫ້ ຫຼື ແກ້ໄຂຄະແນນ</td>
                                      } */}
                                </tr>
                              );
                            })
                          )}

                          {/* })} */}
                        </tbody>
                      </table>
                      <div
                        className="col-md-12"
                        style={{ textAlign: "center" }}
                      >
                        {/* {dataUser?.role === "ADMIN" ? */}
                        <Button
                          type="submit"
                          onClick={() => {
                            setConfirmAllGrade(!confirmAllGrade);
                          }}
                          className="btn btn-info"
                          style={{ width: "200px" }}
                        >
                          ຢືນຢັນທັງໝົດ
                        </Button>
                        {/* : ""} */}
                      </div>

                      {/* <Pagination
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: 20,
                            }}
                          >
                            <Pagination.Prev onClick={_onPrevius} />
                            {countPage?.slice(0, 5).map((Item, index) => {
                              return (
                                <Pagination.Item
                                  key={"page" + index}
                                  active={Item === pageNambers}
                                  onClick={() =>
                                    history.push(`/add-grade-for-student/${ index + 1 }`)
                                  }
                                >
                                  {Item}
                                </Pagination.Item>
                              );
                            })}
                            {countPage && countPage > 3 ? (
                              <Pagination.Item style={{ pointerEvents: "none" }}>
                                {"..."}
                              </Pagination.Item>
                            ) : (
                              " "
                            )}
                            {countPage && countPage > 3 ? (
                              <Pagination.Item
                                onClick={() =>
                                  history.push(`/add-grade-for-student/${ countPage }`)
                                }
                              >
                                {countPage}
                              </Pagination.Item>
                            ) : (
                              " "
                            )}
                            <Pagination.Next onClick={_onNext} />
                          </Pagination> */}
                    </div>
                  )}
                </Formik>
              )}
            </CustomContainer>
          )}
        </div>
      {/* ) : (
        <CustomContainer>
          <Empty />
        </CustomContainer>
      )} */}

      {/* <EditGrade
        show={showEdit}
        hide={() => setShowEdit(!showEdit)}
        data={editData}
      /> */}

      <Modal
        show={confirmAllGrade}
        onHide={() => setConfirmAllGrade(!confirmAllGrade)}
      >
        <Modal.Header closeButton>
          <p>ຢືນຢັນການບັນທືກຄະແນນທັງໝົດ</p>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center" }}>
          <p>ທ່ານແນ່ໃຈແລ້ວບໍ່ ?</p>
          <div className="col-md-12" style={{ textAlign: "center" }}>
            <button
              type="submit"
              className="btn btn-outline-secondary"
              onClick={() => setConfirmAllGrade(!confirmAllGrade)}
            >
              ຍົກເລີກ
            </button>
            &nbsp;&nbsp; &nbsp;
            <button
              type="submit"
              onClick={async () => {
                await _updateAllGrade();
                window.location.reload();
              }}
              className="btn btn-info"
            >
              ຢືນຢັນ
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={confirmSentGrade}
        onHide={() => setConfirmSentGrade(!confirmSentGrade)}
      >
        <Modal.Header closeButton>
          <p>ຢືນຢັນການສົ່ງຄະແນນ</p>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center" }}>
          <p>ທ່ານແນ່ໃຈແລ້ວບໍ່ວ່າຕ້ອງການສົ່ງຄະແນນວິຊານີ້ ?</p>
          <div className="col-md-12" style={{ textAlign: "center" }}>
            <button
              type="submit"
              className="btn btn-outline-secondary"
              onClick={() => setConfirmSentGrade(!confirmSentGrade)}
            >
              ຍົກເລີກ
            </button>
            &nbsp;&nbsp; &nbsp;
            <button
              type="submit"
              onClick={async () => {
                await _sendGrade();
                setConfirmSentGrade(!confirmSentGrade);
              }}
              className="btn btn-info"
            >
              ຢືນຢັນ
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default UpGradeForStudent;
