import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faTh } from "@fortawesome/free-solid-svg-icons";
import { Breadcrumb, OverlayTrigger, Tooltip, Form, Row, Col } from "react-bootstrap";
import useReactRouter from "use-react-router";
// component ------------------------------------------------->

import CustomPopover from "./CustomPopover";
import ButtonDetail from "./ButtonDetail";
import Loading from "../../common/Loading";
import Styles from "./Styles";

// consts ---------------------------------------------------->
import { CustomContainer, Title, TableHeader, TableCell } from "../../common";
import { useLazyQuery } from "@apollo/react-hooks";
import { COURSES } from "../../apollo/course";
import { QUERY_DEPARTMENT, QUERY_FACULTY } from "./apollo/query";
import { USER_KEY } from "../../consts/user";
import Consts from "../../consts";
import Routers from "../../consts/routes";

const CourseLists = () => {
    const [displayStyle, setDisplayStyle] = useState(false);
    const [userLoginData, setUserLoginData] = useState();
    const [teacherId, setTeacherId] = useState("");
    const [totals, setTotals] = useState(0);
    const [courseCode, setCourseCode] = useState("");

    const [dataFaculties, setDataFaculties] = useState([]);
    const [facultyArray, setFacultyArray] = useState([]);
    const [departmentArray, setDepartmentArray] = useState([]);
    const [facultyId, setFacultyId] = useState("");
    const [dataDepartments, setDataDepartments] = useState([]);
    const [departmentId, setDepartmentId] = useState("");

    const [daySelected, setDaySelected] = useState("MONDAY");
    const { history } = useReactRouter();

    // states ---------------------------------------->
    const [semester, setSemester] = useState(1);
    const [year, setYear] = useState(1);

    const [loadDataFacuties, { data: facultiesData }] = useLazyQuery(QUERY_FACULTY, { fetchPolicy: "cache-and-network" });
    const [loadDataDepartment, { data: departmentsData }] = useLazyQuery(QUERY_DEPARTMENT, { fetchPolicy: "network-only" });
    const [loadCourses, { loading: scheduleLoading, data: scheduleData }] = useLazyQuery(COURSES, { fetchPolicy: "cache-and-network" });

    useEffect(() => {
        loadDataFacuties({ variables: { where: { isDeleted_not: true } } })

        const user = JSON.parse(localStorage.getItem(USER_KEY));
        if (user) { setUserLoginData(user?.data); setTeacherId(user?.data?.id) }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let _whereCourse = { isDeleted: false }
        if (userLoginData !== "") _whereCourse = { ..._whereCourse, teacher_some: { id: userLoginData && userLoginData?.id } }
        if (year !== "") _whereCourse = { ..._whereCourse, year: year }
        if (semester !== "") _whereCourse = { ..._whereCourse, semester: semester }
        if (facultyId !== "") _whereCourse = { ..._whereCourse, faculty: { id: facultyId } }
        if (courseCode !== "") _whereCourse = { ..._whereCourse, courseCode_contains: courseCode }
        if (departmentId !== "") _whereCourse = { ..._whereCourse, department: { id: departmentId } }

        loadCourses({
            variables: {
                where: _whereCourse
            },
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userLoginData, year, semester, facultyId, departmentId, courseCode]);

    useEffect(() => {
        setTotals(scheduleData?.courses.length)
        var arrayFaculty = []
        var arrayDepartment = []
        for (var i = 0; i < scheduleData?.courses?.length; i++) {
            arrayFaculty.push({ id: scheduleData?.courses[i]?.faculty?.id })
        }

        for (var b = 0; b < scheduleData?.courses?.length; b++) {
            arrayDepartment.push({ id: scheduleData?.courses[b]?.department?.id })
        }
        setFacultyArray(arrayFaculty)
        setDepartmentArray(arrayDepartment)
    }, [scheduleData]);

    useEffect(() => {
        if (facultiesData)
            setDataFaculties(facultiesData?.faculties)
    }, [facultiesData]);

    useEffect(() => {
        if (departmentsData)
            setDataDepartments(departmentsData?.departments)
    }, [departmentsData]);


    const innerLine = {
        outline: "1px solid #e6e6e6",
        outlineOffset: "-8px",
        border: "15px solid #fff",
        backgroundColor: "#fff",
        width: 80,
    };
    const innerLineHave = {
        outline: "1px solid #e6e6e6",
        outlineOffset: "-8px",
        border: "15px solid #fff",
        cursor: "pointer",
        width: 80,
    };

    const _getCourseData = (time, day) => {
        let getCourseData = {};
        let status = false;
        if (scheduleData && scheduleData?.courses?.length > 0) {
            for (var j = 0; j < scheduleData?.courses?.length; j++) {
                if (scheduleData?.courses?.[j].teacher.length > 0) {
                    for (var i = 0; i < scheduleData?.courses?.[j].teacher.length; i++) {
                        if (
                            scheduleData?.courses?.[j].teacher[i].userId === userLoginData?.userId
                        ) {
                            for (
                                var k = 0;
                                k < scheduleData?.courses?.[j].dayTimeIndexes.length;
                                k++
                            ) {
                                for (
                                    var l = 0;
                                    l <
                                    scheduleData?.courses?.[j].dayTimeIndexes[k].timeIndexes.length;
                                    l++
                                ) {
                                    if (
                                        scheduleData?.courses?.[j].dayTimeIndexes[k].timeIndexes[l] +
                                        1 ==
                                        time &&
                                        scheduleData?.courses?.[j].dayTimeIndexes[k].day === day
                                    ) {
                                        getCourseData = scheduleData?.courses?.[j];
                                        status = true;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return { status, data: getCourseData };
    };

    const _handleChangeYear = (e) => {
        setYear(parseInt(e.target.value));
    };
    const _handleChangeSemester = (e) => {
        setSemester(parseInt(e.target.value));
    };
    const _selectFaculty = async (e) => {
        let _facultyId = e?.target?.value
        setFacultyId(e.target.value);
        if (_facultyId === "") {
            setDataDepartments([])
        }
        else {
            await loadDataDepartment({ variables: { where: { faculty: { id: _facultyId } } } })
        }
    }

    const _selectDepartment = (e) => {
        setDepartmentId(e.target.value);
    }
    const _showTheFacultyAccordingToTheTeacher = (id) => {
        const _showThe = facultyArray.filter(fac => fac?.id === id)
        if (_showThe.length > 0) return false
        else return true
    }
    const _showTheDepartmentAccordingToTheTeacher = (id) => {
        const _showTheDepartment = departmentArray.filter(dep => dep?.id === id)
        if (_showTheDepartment.length > 0) return false
        else return true
    }
    const _registrationList = () => {
        history.push(Routers.COURSE_LIST_ALL + "/id/" + teacherId + Routers.PAGE_GINATION);
    }
    const _onKeyCourseCode = (courseCode) => {
        if (courseCode.key === "Enter") {
            setCourseCode(courseCode.target.value)
        }
    }
    const _onCourseDetail = (id) => {
        history.push(`/course-detail/id/${id}`);
    };
    if (scheduleLoading) return <Loading />;
    return (
        <div>
            {/* Breadcrumb */}
            <Breadcrumb>
                <Breadcrumb.Item active>ວິຊາທີ່ສອນ</Breadcrumb.Item>
            </Breadcrumb>
            <div style={{ display: "flex" }}>
                <div
                    className="menuItemTap"
                    onClick={() =>
                        history.push("/course-list/1")
                    }
                >
                    ລາຍຊື່ນັກສຶກສາທີ່ລົງທະບຽນຮຽນ
                </div>
                <div
                    className="menuItemTap active-menuTap"
                    onClick={() => history.push(Routers.COURSE_LISTS)}
                >
                    ລາຍວິຊາທັງໝົດ
                </div>
            </div>
            <CustomContainer style={{ marginTop: 0 }}>
                <Row>
                    <Col md={12}>
                        <Form.Group
                            as={Row}
                            style={{
                                margin: 0,
                                marginTop: 10,
                                marginBottom: 10,
                                alignItems: "center",
                                fontSize: 16,
                            }}
                        >
                            <Form.Label column sm="1" className="text-left">
                                ຄົ້ນຫາ
                            </Form.Label>
                            <Col md="11">
                                <Form.Control
                                    style={{ borderRadius: 50 }}
                                    placeholder="ປ້ອນລະ​ຫັດ​ວິຊາແລ້ວ Enter..."
                                    type="text"
                                    onKeyDown={(e) => _onKeyCourseCode(e)}
                                />
                            </Col>
                        </Form.Group>
                    </Col>
                    <Col md={6} style={{ marginTop: 20 }}>ທັງໝົດ {totals} ວິຊາ</Col>
                    <Col md={6} style={{ marginTop: 20, textAlign: "right" }}>
                        <a style={{ textDecoration: "underline", color: Consts.PRIMARY_COLOR, cursor: "pointer" }} onClick={() => _registrationList()}>ເບິ່ງທັງໝົດ</a>
                    </Col>
                    <Col md={12}>
                        <table border="1" bordercolor="#fff" style={{ width: "100%" }}>
                            <thead>
                                <TableHeader style={{ backgroundColor: "#057CAE" }}>
                                    <th style={{ width: 70 }}>ລຳດັບ</th>
                                    <th>ລະຫັດວິຊາ</th>
                                    <th>ຊື່ວິຊາ</th>
                                    <th>ປີຮຽນ</th>
                                    <th>ພາກຮຽນ</th>
                                    <th>ຄະນະ</th>
                                    <th>ພາກວິຊາ</th>
                                    <th style={{ width: 100 }}>ຈັດການ</th>
                                </TableHeader>
                            </thead>
                            <tbody>
                                {scheduleData?.courses?.map((item, index) => {
                                    return (
                                        <tr style={{ border: "2px solid white", textAlign: "center", backgroundColor: "#EEEEEE", }} key={index}>
                                            <TableCell> {index + 1} </TableCell>
                                            <TableCell>{item?.courseCode}</TableCell>
                                            <TableCell>{item?.title}</TableCell>
                                            <TableCell>{item?.year}</TableCell>
                                            <TableCell>{item?.semester}</TableCell>
                                            <TableCell>{item?.faculty?.name}</TableCell>
                                            <TableCell>{item?.department?.name}</TableCell>
                                            <TableCell>
                                                <center>
                                                    <OverlayTrigger
                                                        overlay={
                                                            <Tooltip id="tooltip-disabled">
                                                                ເບິ່ງລາຍລະອຽດເພີ່ມເຕີມ
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <div
                                                            onClick={() => _onCourseDetail(item?.id)}
                                                            style={{
                                                                cursor: "pointer",
                                                                backgroundColor: "#FFFFFF",
                                                                padding: 3,
                                                                width: 64,
                                                                borderRadius: 4,
                                                            }}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={["fas", "eye"]}
                                                                color="#7BB500"
                                                            />
                                                        </div>
                                                    </OverlayTrigger>
                                                </center>
                                            </TableCell>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </Col>
                </Row>
            </CustomContainer>
            <br />
            <CustomContainer>
                <Title text={"ວິຊາທີ່ສອນ"} />
                <Form.Group
                    as={Row}
                    style={{
                        margin: 0,
                        marginTop: 10,
                        marginBottom: 10,
                        justifyContent: "center",
                        fontSize: 16,
                    }}
                >
                    <Form.Label column sm="0" className="text-left">
                        ຄະນະ
                    </Form.Label>
                    <Col sm="2">
                        <Form.Control
                            as="select"
                            name="faculty"
                            onChange={(e) => _selectFaculty(e)}
                        >
                            <option value="">---ເລືອກຄະນະ---</option>
                            {dataFaculties?.map((fac, facIndex) => {
                                return (
                                    <option disabled={_showTheFacultyAccordingToTheTeacher(fac?.id)} key={facIndex} value={fac?.id}>{fac?.name}</option>
                                )
                            })}
                        </Form.Control>
                    </Col>
                    <Form.Label column sm="0" className="text-left">
                        ພາກວິຊາ
                    </Form.Label>
                    <Col sm="2">
                        <Form.Control
                            as="select"
                            name="year"
                            onChange={(e) => _selectDepartment(e)}
                        >
                            <option value="">---ເລືອກພາກວິຊາ---</option>
                            {dataDepartments?.map((dep, depIndex) => {
                                return (<option disabled={_showTheDepartmentAccordingToTheTeacher(dep?.id)} key={depIndex} value={dep?.id}>{dep?.name}</option>)
                            })}
                        </Form.Control>
                    </Col>
                    <Form.Label column sm="0" className="text-left">
                        ປີຮຽນ
                    </Form.Label>
                    <Col sm="2">
                        <Form.Control
                            as="select"
                            name="year"
                            onChange={(e) => _handleChangeYear(e)}
                            value={year}
                        >
                            <option key={1}>1</option>
                            <option key={2}>2</option>
                            <option key={3}>3</option>
                            <option key={4}>4</option>
                            <option key={5}>5</option>
                            <option key={6}>6</option>
                        </Form.Control>
                    </Col>
                    <Form.Label column sm="0" className="text-left">
                        ພາກຮຽນ
                    </Form.Label>
                    <Col sm="2" className="mr-5">
                        <Form.Control
                            as="select"
                            name="semester"
                            onChange={(e) => _handleChangeSemester(e)}
                            value={semester}
                        >
                            <option key={1}>1</option>
                            <option key={2}>2</option>
                        </Form.Control>
                    </Col>
                    <Row sm="6" className="ml-5">
                        <Col sm="1" style={{ cursor: "pointer" }}>
                            <OverlayTrigger
                                overlay={<Tooltip id="tooltip-disabled">ປ່ຽນມູມມອງ</Tooltip>}
                            >
                                <FontAwesomeIcon
                                    style={{ color: "#057CAE", fontSize: "26px" }}
                                    icon={displayStyle ? faTh : faList}
                                    onClick={() => {
                                        setDisplayStyle(!displayStyle);
                                    }}
                                />
                            </OverlayTrigger>
                        </Col>
                    </Row>
                </Form.Group>

                {/* Table list */}
                <div
                    style={{
                        display: displayStyle ? "none" : "flex",
                        justifyContent: "center",
                        marginLeft: "-5%",
                    }}
                >
                    <table border="1" bordercolor="#fff" style={{ width: "70%" }}>
                        <thead>
                            <TableHeader style={{ background: "none", color: "#979797" }}>
                                <th width={80}></th>
                                <th width={80} style={{ fontSize: 22 }}>
                                    ຈັນ
                                </th>
                                <th width={80} style={{ fontSize: 22 }}>
                                    ຄານ
                                </th>
                                <th width={80} style={{ fontSize: 22 }}>
                                    ພຸດ
                                </th>
                                <th width={80} style={{ fontSize: 22 }}>
                                    ພະຫັດ
                                </th>
                                <th width={80} style={{ fontSize: 22 }}>
                                    ສຸກ
                                </th>
                                <th width={80} style={{ fontSize: 22 }}>
                                    ເສົາ
                                </th>
                                <th width={80} style={{ fontSize: 22 }}>
                                    ອາທິດ
                                </th>
                            </TableHeader>
                        </thead>
                        <tbody>
                            {scheduleData?.courses?.length > 0
                                ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((time, index) => {
                                    return (
                                        <tr
                                            // onClick={() => _courseDetail(course.id)}
                                            style={{
                                                borderBottom: "1px solid #ffff",
                                                textAlign: "center",
                                                height: 130,
                                            }}
                                            key={index}
                                        >
                                            <TableCell style={{ background: "#fff" }}>
                                                <p style={{ fontSize: 18 }}>ຊົ່ວໂມງ</p> {index + 1}
                                            </TableCell>
                                            <TableCell
                                                style={
                                                    _getCourseData(time, "MONDAY").status === true
                                                        ? innerLineHave
                                                        : innerLine
                                                }
                                            >
                                                {_getCourseData(time, "MONDAY").status === true ? (
                                                    <CustomPopover
                                                        course={_getCourseData(time, "MONDAY").data}
                                                    />
                                                ) : (
                                                    ""
                                                )}
                                            </TableCell>
                                            <TableCell
                                                style={
                                                    _getCourseData(time, "TUESDAY").status === true
                                                        ? innerLineHave
                                                        : innerLine
                                                }
                                            >
                                                {_getCourseData(time, "TUESDAY").status === true ? (
                                                    <CustomPopover
                                                        course={_getCourseData(time, "TUESDAY").data}
                                                    />
                                                ) : (
                                                    ""
                                                )}
                                            </TableCell>
                                            <TableCell
                                                style={
                                                    _getCourseData(time, "WEDNESDAY").status === true
                                                        ? innerLineHave
                                                        : innerLine
                                                }
                                            >
                                                {_getCourseData(time, "WEDNESDAY").status === true ? (
                                                    <CustomPopover
                                                        course={_getCourseData(time, "WEDNESDAY").data}
                                                    />
                                                ) : (
                                                    ""
                                                )}
                                            </TableCell>
                                            <TableCell
                                                style={
                                                    _getCourseData(time, "THURSDAY").status === true
                                                        ? innerLineHave
                                                        : innerLine
                                                }
                                            >
                                                {_getCourseData(time, "THURSDAY").status === true ? (
                                                    <CustomPopover
                                                        course={_getCourseData(time, "THURSDAY").data}
                                                    />
                                                ) : (
                                                    ""
                                                )}
                                            </TableCell>
                                            <TableCell
                                                style={
                                                    _getCourseData(time, "FRIDAY").status === true
                                                        ? innerLineHave
                                                        : innerLine
                                                }
                                            >
                                                {_getCourseData(time, "FRIDAY").status === true ? (
                                                    <CustomPopover
                                                        course={_getCourseData(time, "FRIDAY").data}
                                                    />
                                                ) : (
                                                    ""
                                                )}
                                            </TableCell>
                                            <TableCell
                                                style={
                                                    _getCourseData(time, "SATURDAY").status === true
                                                        ? innerLineHave
                                                        : innerLine
                                                }
                                            >
                                                {_getCourseData(time, "SATURDAY").status === true ? (
                                                    <CustomPopover
                                                        course={_getCourseData(time, "SATURDAY").data}
                                                    />
                                                ) : (
                                                    ""
                                                )}
                                            </TableCell>
                                            <TableCell
                                                style={
                                                    _getCourseData(time, "SUNDAY").status === true
                                                        ? innerLineHave
                                                        : innerLine
                                                }
                                            >
                                                {_getCourseData(time, "SUNDAY").status === true ? (
                                                    <CustomPopover
                                                        course={_getCourseData(time, "SUNDAY").data}
                                                    />
                                                ) : (
                                                    ""
                                                )}
                                            </TableCell>
                                        </tr>
                                    );
                                })
                                : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((time, index) => {
                                    return (
                                        <tr
                                            key={index}
                                            style={{
                                                borderBottom: "2px solid #ffff",
                                                textAlign: "center",
                                                height: 100,
                                            }}
                                        >
                                            <TableCell style={{ background: "#fff" }}>
                                                <p>ຊົ່ວໂມງ</p>
                                                {time}
                                            </TableCell>
                                            <TableCell style={innerLine}></TableCell>
                                            <TableCell style={innerLine}></TableCell>
                                            <TableCell style={innerLine}></TableCell>
                                            <TableCell style={innerLine}></TableCell>
                                            <TableCell style={innerLine}></TableCell>
                                            <TableCell style={innerLine}></TableCell>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>
                </div>

                {/* //----------------------------//
                     for list view
          //-----------------------------// */}

                <div style={{ display: displayStyle ? "flex" : "none" }}>
                    <div
                        style={{ width: "100%", display: "flex", backgroundColor: "#fff" }}
                    >
                        <div style={{ width: "100%", backgroundColor: "#fff" }}>
                            {/* ວັນ */}
                            <div
                                style={{
                                    overflowX: "scroll",
                                    display: "flex",
                                    width: "100%",
                                    paddingRight: 30,
                                }}
                            >
                                <div
                                    style={
                                        daySelected === "MONDAY"
                                            ? Styles.selected
                                            : Styles.noneSelect
                                    }
                                    onClick={() => setDaySelected("MONDAY")}
                                >
                                    ຈັນ
                                </div>
                                <div
                                    style={
                                        daySelected === "TUESDAY"
                                            ? Styles.selected
                                            : Styles.noneSelect
                                    }
                                    onClick={() => setDaySelected("TUESDAY")}
                                >
                                    ອັງຄານ
                                </div>
                                <div
                                    style={
                                        daySelected === "WEDNESDAY"
                                            ? Styles.selected
                                            : Styles.noneSelect
                                    }
                                    onClick={() => setDaySelected("WEDNESDAY")}
                                >
                                    ພຸດ
                                </div>
                                <div
                                    style={
                                        daySelected === "THURSDAY"
                                            ? Styles.selected
                                            : Styles.noneSelect
                                    }
                                    onClick={() => setDaySelected("THURSDAY")}
                                >
                                    ພະຫັດ
                                </div>
                                <div
                                    style={
                                        daySelected === "FRIDAY"
                                            ? Styles.selected
                                            : Styles.noneSelect
                                    }
                                    onClick={() => setDaySelected("FRIDAY")}
                                >
                                    ສຸກ
                                </div>
                                <div
                                    style={
                                        daySelected === "SATURDAY"
                                            ? Styles.selected
                                            : Styles.noneSelect
                                    }
                                    onClick={() => setDaySelected("SATURDAY")}
                                >
                                    ເສົາ
                                </div>
                                <div
                                    style={
                                        daySelected === "SUNDAY"
                                            ? Styles.selected
                                            : Styles.noneSelect
                                    }
                                    onClick={() => setDaySelected("SUNDAY")}
                                >
                                    ອາທິດ
                                </div>
                            </div>
                            {/* ຊົ່ວໂມງ */}
                            <div style={{ padding: 15, paddingBottom: 30 }}>
                                <div style={Styles.timesBox}>
                                    <div style={Styles.times}>
                                        <h6>ຊມ</h6>
                                        <h5>
                                            <b>1</b>
                                        </h5>
                                    </div>
                                    <div style={Styles.timesResult}>
                                        {_getCourseData(1, daySelected).status === true ? (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <div>
                                                    <p style={{ marginTop: 10 }}>
                                                        {_getCourseData(1, daySelected).data?.title ?? ""}
                                                    </p>
                                                    <p style={{ marginTop: 10 }}>
                                                        {_getCourseData(1, daySelected).data?.courseCode ??
                                                            ""}
                                                    </p>
                                                </div>

                                                <ButtonDetail
                                                    courseId={_getCourseData(1, daySelected).data?.id}
                                                    moodleCourseId={
                                                        _getCourseData(1, daySelected).data?.moodleCourseId
                                                    }
                                                />
                                            </div>
                                        ) : (
                                            <div></div>
                                        )}
                                    </div>
                                </div>
                                <div style={Styles.timesBox}>
                                    <div style={Styles.times}>
                                        <h6>ຊມ</h6>
                                        <h5>
                                            <b>2</b>
                                        </h5>
                                    </div>
                                    <div style={Styles.timesResult}>
                                        {_getCourseData(2, daySelected).status === true ? (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <div>
                                                    <p style={{ marginTop: 10 }}>
                                                        {_getCourseData(2, daySelected).data?.title ?? ""}
                                                    </p>
                                                    <p style={{ marginTop: 10 }}>
                                                        {_getCourseData(2, daySelected).data?.courseCode ??
                                                            ""}
                                                    </p>
                                                </div>
                                                <ButtonDetail
                                                    courseId={_getCourseData(2, daySelected).data?.id}
                                                />
                                            </div>
                                        ) : (
                                            <div></div>
                                        )}
                                    </div>
                                </div>
                                <div style={Styles.timesBox}>
                                    <div style={Styles.times}>
                                        <h6>ຊມ</h6>
                                        <h5>
                                            <b>3</b>
                                        </h5>
                                    </div>
                                    <div style={Styles.timesResult}>
                                        {_getCourseData(3, daySelected).status === true ? (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <div>
                                                    <p style={{ marginTop: 10 }}>
                                                        {_getCourseData(3, daySelected).data?.title ?? ""}
                                                    </p>
                                                    <p style={{ marginTop: 10 }}>
                                                        {_getCourseData(3, daySelected).data?.courseCode ??
                                                            ""}
                                                    </p>
                                                </div>
                                                <ButtonDetail
                                                    courseId={_getCourseData(3, daySelected).data?.id}
                                                />
                                            </div>
                                        ) : (
                                            <div></div>
                                        )}
                                    </div>
                                </div>
                                <div style={Styles.timesBox}>
                                    <div style={Styles.times}>
                                        <h6>ຊມ</h6>
                                        <h5>
                                            <b>4</b>
                                        </h5>
                                    </div>
                                    <div style={Styles.timesResult}>
                                        {_getCourseData(4, daySelected).status === true ? (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <div>
                                                    <p style={{ marginTop: 10 }}>
                                                        {_getCourseData(4, daySelected).data?.title ?? ""}
                                                    </p>
                                                    <p style={{ marginTop: 10 }}>
                                                        {_getCourseData(4, daySelected).data?.courseCode ??
                                                            ""}
                                                    </p>
                                                </div>
                                                <ButtonDetail
                                                    courseId={_getCourseData(4, daySelected).data?.id}
                                                />
                                            </div>
                                        ) : (
                                            <div></div>
                                        )}
                                    </div>
                                </div>
                                <div style={Styles.timesBox}>
                                    <div style={Styles.times}>
                                        <h6>ຊມ</h6>
                                        <h5>
                                            <b>5</b>
                                        </h5>
                                    </div>
                                    <div style={Styles.timesResult}>
                                        {_getCourseData(5, daySelected).status === true ? (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <div>
                                                    <p style={{ marginTop: 10 }}>
                                                        {_getCourseData(5, daySelected).data?.title ?? ""}
                                                    </p>
                                                    <p style={{ marginTop: 10 }}>
                                                        {_getCourseData(5, daySelected).data?.courseCode ??
                                                            ""}
                                                    </p>
                                                </div>
                                                <ButtonDetail
                                                    courseId={_getCourseData(5, daySelected).data?.id}
                                                />
                                            </div>
                                        ) : (
                                            <div></div>
                                        )}
                                    </div>
                                </div>
                                <div style={Styles.timesBox}>
                                    <div style={Styles.times}>
                                        <h6>ຊມ</h6>
                                        <h5>
                                            <b>6</b>
                                        </h5>
                                    </div>
                                    <div style={Styles.timesResult}>
                                        {_getCourseData(6, daySelected).status === true ? (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <div>
                                                    <p style={{ marginTop: 10 }}>
                                                        {_getCourseData(6, daySelected).data?.title ?? ""}
                                                    </p>
                                                    <p style={{ marginTop: 10 }}>
                                                        {_getCourseData(6, daySelected).data?.courseCode ??
                                                            ""}
                                                    </p>
                                                </div>
                                                <ButtonDetail
                                                    courseId={_getCourseData(6, daySelected).data?.id}
                                                />
                                            </div>
                                        ) : (
                                            <div></div>
                                        )}
                                    </div>
                                </div>
                                <div style={Styles.timesBox}>
                                    <div style={Styles.times}>
                                        <h6>ຊມ</h6>
                                        <h5>
                                            <b>7</b>
                                        </h5>
                                    </div>
                                    <div style={Styles.timesResult}>
                                        {_getCourseData(7, daySelected).status === true ? (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <div>
                                                    <p style={{ marginTop: 10 }}>
                                                        {_getCourseData(7, daySelected).data?.title ?? ""}
                                                    </p>
                                                    <p style={{ marginTop: 10 }}>
                                                        {_getCourseData(7, daySelected).data?.courseCode ??
                                                            ""}
                                                    </p>
                                                </div>
                                                <ButtonDetail
                                                    courseId={_getCourseData(7, daySelected).data?.id}
                                                />
                                            </div>
                                        ) : (
                                            <div></div>
                                        )}
                                    </div>
                                </div>
                                <div style={Styles.timesBox}>
                                    <div style={Styles.times}>
                                        <h6>ຊມ</h6>
                                        <h5>
                                            <b>8</b>
                                        </h5>
                                    </div>
                                    <div style={Styles.timesResult}>
                                        {_getCourseData(8, daySelected).status === true ? (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <div>
                                                    <p style={{ marginTop: 10 }}>
                                                        {_getCourseData(8, daySelected).data?.title ?? ""}
                                                    </p>
                                                    <p style={{ marginTop: 10 }}>
                                                        {_getCourseData(8, daySelected).data?.courseCode ??
                                                            ""}
                                                    </p>
                                                </div>
                                                <ButtonDetail
                                                    courseId={_getCourseData(8, daySelected).data?.id}
                                                />
                                            </div>
                                        ) : (
                                            <div></div>
                                        )}
                                    </div>
                                </div>
                                <div style={Styles.timesBox}>
                                    <div style={Styles.times}>
                                        <h6>ຊມ</h6>
                                        <h5>
                                            <b>9</b>
                                        </h5>
                                    </div>
                                    <div style={Styles.timesResult}>
                                        {_getCourseData(9, daySelected).status === true ? (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <div>
                                                    <p style={{ marginTop: 10 }}>
                                                        {_getCourseData(9, daySelected).data?.title ?? ""}
                                                    </p>
                                                    <p style={{ marginTop: 10 }}>
                                                        {_getCourseData(9, daySelected).data?.courseCode ??
                                                            ""}
                                                    </p>
                                                </div>
                                                <ButtonDetail
                                                    courseId={_getCourseData(9, daySelected).data?.id}
                                                />
                                            </div>
                                        ) : (
                                            <div></div>
                                        )}
                                    </div>
                                </div>
                                <div style={Styles.timesBox}>
                                    <div style={Styles.times}>
                                        <h6>ຊມ</h6>
                                        <h5>
                                            <b>10</b>
                                        </h5>
                                    </div>
                                    <div style={Styles.timesResult}>
                                        {_getCourseData(10, daySelected).status === true ? (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <div>
                                                    <p style={{ marginTop: 10 }}>
                                                        {_getCourseData(10, daySelected).data?.title ?? ""}
                                                    </p>
                                                    <p style={{ marginTop: 10 }}>
                                                        {_getCourseData(10, daySelected).data?.courseCode ??
                                                            ""}
                                                    </p>
                                                </div>
                                                <ButtonDetail
                                                    courseId={_getCourseData(10, daySelected).data?.id}
                                                />
                                            </div>
                                        ) : (
                                            <div></div>
                                        )}
                                    </div>
                                </div>
                                <div style={Styles.timesBox}>
                                    <div style={Styles.times}>
                                        <h6>ຊມ</h6>
                                        <h5>
                                            <b>11</b>
                                        </h5>
                                    </div>
                                    <div style={Styles.timesResult}>
                                        {_getCourseData(11, daySelected).status === true ? (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <div>
                                                    <p style={{ marginTop: 10 }}>
                                                        {_getCourseData(11, daySelected).data?.title ?? ""}
                                                    </p>
                                                    <p style={{ marginTop: 10 }}>
                                                        {_getCourseData(11, daySelected).data?.courseCode ??
                                                            ""}
                                                    </p>
                                                </div>
                                                <ButtonDetail
                                                    courseId={_getCourseData(11, daySelected).data?.id}
                                                />
                                            </div>
                                        ) : (
                                            <div></div>
                                        )}
                                    </div>
                                </div>
                                <div style={Styles.timesBox}>
                                    <div style={Styles.times}>
                                        <h6>ຊມ</h6>
                                        <h5>
                                            <b>12</b>
                                        </h5>
                                    </div>
                                    <div style={Styles.timesResult}>
                                        {_getCourseData(12, daySelected).status === true ? (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <div>
                                                    <p style={{ marginTop: 10 }}>
                                                        {_getCourseData(12, daySelected).data?.title ?? ""}
                                                    </p>
                                                    <p style={{ marginTop: 10 }}>
                                                        {_getCourseData(12, daySelected).data?.courseCode ??
                                                            ""}
                                                    </p>
                                                </div>
                                                <ButtonDetail
                                                    courseId={_getCourseData(12, daySelected).data?.id}
                                                />
                                            </div>
                                        ) : (
                                            <div></div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CustomContainer>
        </div>
    );
};

export default CourseLists;

