import { gql } from "apollo-boost";

export const PRE_SIGNED_URL = gql`
  query PreSignedUrl($mimeType: String, $fileName: String) {
    preSignedUrl(mimeType: $mimeType, fileName: $fileName) {
      url
    }
  }
`


export const FILES = gql`
  query Files(
    $where: CustomFileWhereInput
    $orderBy: FileOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    files(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      data{
        id
        title
        description
        file
        createdBy {
          role
          firstname
          lastname
        }
        updatedBy {
          role
          firstname
          lastname
        }
        updatedAt
        createdAt
      }
      total 
    }
  }
`;

export const FILE = gql`
  query File($where: FileWhereUniqueInput!) {
    file(where: $where) {
      id
      title
      description
      file
      keyword
      classRoom
      type
      cate
      createdBy {
        id
        firstname
        lastname
        userId
      }
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_FILE = gql`
  mutation CreateFile($data: CustomFileCreateInput!) {
    createFile(data: $data) {
      id
      title
    }
  }
`;

export const TEACHER_COURSES = gql`
  query TeacherCourses($where: CustomCourseWhereInput) {
    teacherCourses(where: $where) {
      id
      name
      note
    }
  }
`;

export const UPDATE_FILE = gql`
  mutation UpdateFile($data: CustomFileUpdateInput!, $where: FileWhereUniqueInput!) {
    updateFile(data: $data, where: $where) {
      id
      title
    }
  }
`;

export const DELETE_FILE = gql`
  mutation DeleteFile($where: FileWhereUniqueInput!) {
    deleteFile(where: $where) {
      id
      title
    }
  }
`;
