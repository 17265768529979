import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";
/**
 *
 * @Library
 *
 */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLazyQuery } from "@apollo/react-hooks";
import _ from "lodash";

/**
 *
 * @Component
 *
 */
import { Breadcrumb, Tooltip, OverlayTrigger } from "react-bootstrap";
import DocSearch from "./DocSearch";
import {
  CustomContainer,
  SearchBar,
  Title,
  TableHeader,
  TableCell,
} from "../../common";

/**
 *
 * @Constant
 *
 */
import Consts from "../../consts";
import Routers from "../../consts/routes";

/**
 *
 * @Apollo
 *
 */
import { FILES } from "../../apollo/file";
import { FILE_BY_KEYWORD } from "../../apollo/fileByKeyword";
import { formatDateTime } from "../../common/super";

/**
 *
 * @Function
 *
 */
import PaginationHelper from "../../helpers/PaginationHelper";

import Loading from "../../common/Loading";

export default function DocList() {
  const { history, match } = useReactRouter();

  // states ------------------------------------------------------------->
  const [showSearchView, setShowSearchView] = useState(false);
  const [startDay, setStartDay] = useState("2020-1-1");
  let DATENOW =
    new Date().getFullYear() +
    "-" +
    (new Date().getMonth() + 1) +
    "-" +
    new Date().getDate();
  const [endDay, setEndDay] = useState(DATENOW);
  const [isTrue, setIsTrue] = useState(0);
  const [title, setTitle] = useState("ເອກະສານທັງຫມົດ");
  const [whereFile, setWhereFile] = useState("");
  const [fileDatas, setFileDatas] = useState([]);
  const [keywordInput, setKeywordInput] = useState("");
  const [docTotal, setDocTotal] = useState(0);
  const { _limit, _skip, Pagination_helper } = PaginationHelper();

  // apollo ------------------------------------------------------------->
  // Varible

  const [loadData, { data, loading }] = useLazyQuery(FILES);
  const [loadFilesByKeyword, { data: fileByKeywordData }] = useLazyQuery(FILE_BY_KEYWORD);

  // use ---------------------------------------------------------------->
  useEffect(() => {
    loadData({
      fetchPolicy: "network-only",
      variables: {
        where: {
          isDeleted: false,
          type: "PUBLIC_FILE",
        },
        skip: (_skip - 1) * _limit,
        first: _limit,
      },
    });
  }, []);

  useEffect(() => {
    if(whereFile){
      loadData({
        fetchPolicy: "network-only",
        variables: {
          where: {
            isDeleted: false,
            type: "PUBLIC_FILE",
            cate: whereFile,
          },
          skip: (_skip - 1) * _limit,
          first: _limit,
        },
      });
    }else{
      loadData({
        fetchPolicy: "network-only",
        variables: {
          where: {
            isDeleted: false,
            type: "PUBLIC_FILE",
          },
          skip: (_skip - 1) * _limit,
          first: _limit,
        },
      });
    }
    
  }, [whereFile]);

  useEffect(() => {
    if (data?.files?.data)
    setFileDatas(data?.files?.data);
    setDocTotal(data?.files?.total);
  }, [data?.files?.data]);

  // useEffect(() => {
    // loadFilesByKeyword({
    //   fetchPolicy: "network-only",
    //   variables: {
    //     where: {
    //       isDeleted: true,
    //       type: "PUBLIC_FILE",
    //     },
    //     skip: (_skip - 1) * _limit,
    //     first: _limit,
    //   },
    // });
    // if (fileByKeywordData?.fileByKeyword) {
    //   setFileDatas(fileByKeywordData.fileByKeyword);
    // }
  // }, [fileByKeywordData, fileByKeywordData?.fileByKeyword]);

  // function --------------------------------------------------------->
  const _handleSearchViewClose = () => setShowSearchView(false);
  const _handleSearchViewShow = () => setShowSearchView(true);

  // Search
  const _onSearch = async (value) => {
    if(value.createdName === "" && value.keyword===""){
      loadData({
        fetchPolicy: "network-only",
        variables: {
          where: {
            isDeleted: false,
            type: "PUBLIC_FILE",
          },
          skip: (_skip - 1) * _limit,
          first: _limit,
        },
      });
    }
    else{
    setStartDay(value.startDate);
    setEndDay(value.endDate);
        await loadData({
          variables: {
            where: {
              type: "PUBLIC_FILE",
              title: value.createdName,
              isDeleted: false,
              createdAt_gte: value.startDate ?? "",
              createdAt_lt: value.endDate ?? "",
              // cate: fileCat.cate ?? "",
              // createdBy: {
              //   firstname: createdBy ?? "",
              // },
            },
            skip: (_skip - 1) * _limit,
            first: _limit,
          },
        });
    }
        setTitle("ຜົນການຄົ້ນຫາ");
  };

  const _onDocDetail = (id) => {
    history.push(`/documents/${id}`);
  };

  const _onTabs = async (status) => {
    setIsTrue(status);
    if (status === 0) {
      setWhereFile("");
    } else if (status === 1) {
      setWhereFile("RESEARCH");
    } else if (status === 2) {
      setWhereFile("GENERAL");
    } else if (status === 3) {
      setWhereFile("SPECIFIC");
    }
  };

  return (
    <div>
      {/* Breadcrumb */}

      <Breadcrumb>
        <Breadcrumb.Item active style={{ fontWeight: "bolder" }}>
          ເອກະສານທັງຫມົດ
        </Breadcrumb.Item>
      </Breadcrumb>
      {/* <div style={{ display: "flex"}}>
        <div className="menuItemTap active-menuTap" onClick={() => history.push("/document-list/limit/20/skip/1")}> ເອກະສານທັງໝົດ </div>
        <div className="menuItemTap not-active-menuTap" 
        onClick={() => history.push("/document-list")}> ບົດຄົ້ນຄວ້າຕ່າງປະເທດ </div>
      </div> */}
      <CustomContainer>
        <Title text={title} />
        {keywordInput && <span style={{ fontSize: 18 }}>{keywordInput}</span>}

        {/* custom search button */}
        <SearchBar
          title="ປ້ອນKeyword ເພື່ອຄົ້ນຫາ (ຂັ້ນດ້ວຍ “,”) ຕົວຢ່າງ: ຖານຂໍ້ມູນ, ບົດຄົ້ນຄວ້າ"
          onClick={() => _handleSearchViewShow()}
        />

        {/* muad */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            marginTop: 30,
          }}
        >
          <div style={{ width: 80, color: Consts.PRIMARY_COLOR }}>ຫມວດ</div>
          <div
            style={{
              cursor: "pointer",
              width: 80,
              marginRight: 30,
              textAlign: "center",
              borderBottom: isTrue === 0 ? "5px solid #7BB500" : "none",
            }}
            onClick={() => _onTabs(0)}
          >
            ທັງຫມົດ
          </div>
          <div
            style={{
              cursor: "pointer",
              width: 80,
              marginRight: 40,
              textAlign: "center",
              borderBottom:
                isTrue && isTrue === 1 ? "5px solid #7BB500" : "none",
            }}
            onClick={() => _onTabs(1)}
          >
            ບົດຄົ້ນຄ້ວາ
          </div>
          <div
            style={{
              cursor: "pointer",
              width: 100,
              marginRight: 40,
              textAlign: "center",
              borderBottom:
                isTrue && isTrue === 2 ? "5px solid #7BB500" : "none",
            }}
            onClick={() => _onTabs(2)}
          >
            ຄວາມຮູ້ທົ່ວໄປ
          </div>
          <div
            style={{
              cursor: "pointer",
              width: 140,
              borderBottom:
                isTrue && isTrue === 3 ? "5px solid #7BB500" : "none",
            }}
            onClick={() => _onTabs(3)}
          >
            ເອກະສານວິຊາສະເພາະ
          </div>
        </div>

        {/* ເອກະສານທັງຫມົດ */}
        <div
          style={{
            marginTop: 24,
            marginBottom: 8,
            fontSize: 16,
            color: Consts.FONT_COLOR_SECONDARY,
          }}
        >
          ທັງຫມົດ {docTotal ?? "0"}
        </div>

        {/* Table list */}
        <div>
          {loading && <Loading />}
          <table border="1" bordercolor="#fff" style={{ width: "100%" }}>
            <thead>
              <TableHeader style={{ backgroundColor: "#057CAE" }}>
                <th style={{ width: 70 }}>ລຳດັບ</th>
                <th>ຊື່ເອກະສານ</th>
                <th>ສ້າງ</th>
                <th>ອັບເດດ</th>
                <th style={{ width: 200 }}>ຈັດການ</th>
              </TableHeader>
            </thead>
            {fileDatas?.map((file, index) => {
              return (
                <tbody key={index}>
                  <tr
                    style={{
                      border: "2px solid white",
                    }}
                  >
                    <TableCell
                      style={{
                        backgroundColor: "#EEEEEE",
                        textAlign: "center",
                      }}
                    >
                      {index + 1 + _limit * (_skip - 1)}
                    </TableCell>
                    <TableCell style={{ backgroundColor: "#EEEEEE" }}>
                      {file?.title}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      <span>{formatDateTime(file?.createdAt)}</span>
                      <p>
                        (ໂດຍ:{" "}
                        {(file?.createdBy?.firstname ?? "") +
                          " " +
                          (file?.createdBy?.lastname ?? "")}
                        )
                      </p>
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#EEEEEE",
                        textAlign: "center",
                      }}
                    >
                      <span>{formatDateTime(file?.updatedAt)}</span>
                      <p>
                        (ໂດຍ:{" "}
                        {(file?.updatedBy?.firstname ?? "") +
                          " " +
                          (file?.updatedBy?.lastname ?? "")}
                        )
                      </p>
                    </TableCell>
                    <TableCell
                      style={{
                        width: 200,
                        backgroundColor: "#EEEEEE",
                        textAlign: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-around",
                          width: 200,
                        }}
                      >
                        <a href={file?.file}>
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-disabled">
                                ດາວໂຫລດເອກະສານ
                              </Tooltip>
                            }
                          >
                            <button
                              style={{
                                width: 80,
                                height: 30,
                                borderRadius: 3,
                                border: "1px solid #ddd",
                                outline: "none",
                                backgroundColor: "white",
                              }}
                            >
                              <i
                                style={{ color: "#7BB500" }}
                                className="fa fa-download"
                              />
                            </button>
                          </OverlayTrigger>
                        </a>
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="tooltip-disabled">
                              ເບິ່ງລາຍລະອຽດເພີ່ມເຕີມ
                            </Tooltip>
                          }
                        >
                          <button
                            onClick={() => _onDocDetail(file?.id)}
                            style={{
                              width: 60,
                              height: 30,
                              borderRadius: 3,
                              border: "1px solid #ddd",
                              outline: "none",
                              backgroundColor: "white",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={["fa", "eye"]}
                              color="#7BB500"
                            />
                          </button>
                        </OverlayTrigger>
                      </div>
                    </TableCell>
                  </tr>
                </tbody>
              );
            })}
          </table>
        </div>
        {Pagination_helper(docTotal, Routers.DOCUMENT_LIST)}
      </CustomContainer>
      {/* Search Modal */}
      <DocSearch
        showSearchView={showSearchView}
        _handleSearchViewClose={_handleSearchViewClose}
        onSearch={(value) => _onSearch(value)}
        startDay={startDay}
        endDay={endDay}
      />
    </div>
  );
}
