import React from "react";
import { useLocation } from "react-router-dom";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";

export const ImageDisplay = () => {
  const location = useLocation();
  const data = location.state;

  const images = [
    {
      original: data?.link,
      thumbnail: data?.link,
    },
  ];

  return <ImageGallery items={images} />;
};

export default ImageDisplay;
