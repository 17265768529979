import React, { useState } from "react";
import { Breadcrumb, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import useReactRouter from "use-react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { useQuery } from "@apollo/react-hooks";
// component -------------------------------------------------------->
import Loading from "../../common/Loading";
import { CustomContainer, Title, TableHeader, TableCell } from "../../common";
// constance -------------------------------------------------------->
import Consts from "../../consts";
import CourseDeleteConfirm from "./CourseDeleteConfirm";
import { COURSE, COURSES_FACULTY_DEPARTMENT } from "../../apollo/course";
import { useEffect } from "react";
import { reconvertDay, convertWeek } from "../../common/super";
function CourseDetail() {
  const { history, match } = useReactRouter();
  const id = match.params.id;
  
  const { loading, error, data } = useQuery(COURSES_FACULTY_DEPARTMENT, {
    variables: { where: { id: id } },
  });
  
  // States
  const [courseData, setCourseData] = useState({})
  const [courseFaculties, setCourseFaculties] = useState([])
  const [courseDepartment, setCourseDepartment] = useState([])
  const [courseMajor, setCourseMajor] = useState([])
  const [courseClassroom, setCourseClassroom] = useState([])
  const [showDeleteConfirmView, setShowDeleteConfirmView] = useState(false);
  // Set states
  const _handleDeleteConfirmViewClose = () => setShowDeleteConfirmView(false);
 




  useEffect(() => {
    console.log("course data ======== ")
    console.log(courseClassroom)

  },[courseData])

  

  useEffect(() => {
  
    if (data) {
      setCourseData(data?.courseAndFacultyAndDepartmentAndMajor?.dataCourse);
      setCourseFaculties(data?.courseAndFacultyAndDepartmentAndMajor?.dataFaculty)
      setCourseDepartment(data?.courseAndFacultyAndDepartmentAndMajor?.dataDepartment)
      setCourseMajor(data?.courseAndFacultyAndDepartmentAndMajor?.dataMajor)
      setCourseClassroom(data?.courseAndFacultyAndDepartmentAndMajor?.dataClassroom)
    }
  }, [data])
  const _approveStudent = (id) => {
    history.push(`/register-list/id/${id}`, courseData);
    window.location.reload()
  };

  const _giveGarde = (id) => {
    history.push(`/giveGradeForStudent/1`, { courseData, courseClassroom });
  };
  const _viewDoc = (id) => {
    history.push(`/course-doc-list/${id}`);
  };

  const _edit = () => {
    history.push(`/course-edit/${id}`, courseData);
    // window.location.reload(true)
  };
  const _reconvertDay = (day) => {
    let result = "";
    switch (day) {
      case "MONDAY":
        result = "ຈັນ";
        break;
      case "TUESDAY":
        result = "ອັງຄານ";
        break;
      case "WEDNESDAY":
        result = "ພຸດ";
        break;
      case "THURSDAY":
        result = "ພະຫັດ";
        break;
      case "FRIDAY":
        result = "ສຸກ";
        break;
      case "SATURDAY":
        result = "ເສົາ";
        break;
      case "SUNDAY":
        result = "ອາທິດ";
        break;
      default:
        result = "ຈັນ";
    }
    return result;
  };

  if (loading) return <Loading />;
  if (error) return <h1>GraphQL error</h1>;
  // const course = data && data?.courseAndFacultyAndDepartmentAndMajor ? data?.courseAndFacultyAndDepartmentAndMajor : "";
  // console.log("course111----",course?.courseAndFacultyAndDepartmentAndMajor)
  return (
    <div>
      {/* Breadcrumb */}
      <Breadcrumb>
        <Breadcrumb.Item onClick={() => history.push("/course-list/1")}>
          ວິຊາທີສອນ
        </Breadcrumb.Item>
        <Breadcrumb.Item onClick={() => history.push("/course-list/1")}>
          ລາຍລະອຽດວິຊາ
        </Breadcrumb.Item>
        {/* <Breadcrumb.Item active>ລາຍລະອຽດວິຊາ</Breadcrumb.Item> */}
      </Breadcrumb>

      <CustomContainer>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title text="ລາຍລະອຽດວິຊາ" />

          {/* Button group */}
          <div>
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">
                  ຈຳນວນຜູ້ທີ່ລົງທະບຽນຮຽນວິຊານີ້
                </Tooltip>
              }
            >
              <span className="d-inline-block">
                <button
                  style={{
                    backgroundColor: "#fff",
                    color: Consts.PRIMARY_COLOR,
                    width: 140,
                    height: 40,
                    border: "1px solid " + Consts.PRIMARY_COLOR,
                    outline: "none",
                    marginRight: 5,
                  }}
                  onClick={() => _approveStudent(id)}
                >
                  ຈຳນວນຜູ້ລົງທະບຽນ
                </button>
              </span>
            </OverlayTrigger>
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">ໃຫ້ຄະແນນນັກສຶກສາ</Tooltip>
              }
            >
              <span className="d-inline-block">
                <button
                  style={{
                    backgroundColor: "#fff",
                    color: Consts.PRIMARY_COLOR,
                    width: 140,
                    height: 40,
                    border: "1px solid " + Consts.PRIMARY_COLOR,
                    outline: "none",
                    marginRight: 5,
                  }}
                  onClick={() => _giveGarde(id)}
                >
                  ການໃຫ້ຄະແນນ
                </button>
              </span>
            </OverlayTrigger>
            {/* ເອກະສານບົດສອນ */}
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">ເບິ່ງເອກະສານບົດສອນ</Tooltip>
              }
            >
              <span className="d-inline-block">
                <button
                  style={{
                    backgroundColor: "#fff",
                    color: Consts.PRIMARY_COLOR,
                    width: 140,
                    height: 40,
                    border: "1px solid " + Consts.PRIMARY_COLOR,
                    outline: "none",
                    marginRight: 5,
                  }}
                  onClick={() => _viewDoc(id)}
                >
                  ເອກະສານບົດສອນ
                </button>
              </span>
            </OverlayTrigger>

            {/* ແກ້ໃຂ */}
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">ແກ້ໄຂຂໍ້ມູນວິຊານີ້</Tooltip>
              }
            >
              <span className="d-inline-block">
                <button
                  style={{
                    backgroundColor: Consts.PRIMARY_COLOR,
                    color: "#fff",
                    width: 140,
                    height: 40,
                    border: "1px solid " + Consts.PRIMARY_COLOR,
                    outline: "none",
                    marginRight: 5,
                  }}
                  onClick={() => _edit(
                    // course && course.id
                    )}
                >
                  <FontAwesomeIcon icon={faPen} />
                  ແກ້ໄຂຂໍ້ມູນວິຊາ
                </button>
              </span>
            </OverlayTrigger>
          </div>
        </div>

        <div
          style={{
            width: 500,
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: 20,
            paddingBottom: 80,
          }}
        >
          {/* ------ detail box ------ */}
          <div
            style={{
              border: "1px solid #ddd",
              width: 500,
              padding: 20,
              fontSize: 14,
              paddingLeft: 80,
            }}
          >
            <Row>
              <Col>ຊື່ວິຊາ</Col>
              <Col
                style={{ color: Consts.FONT_COLOR_PRIMARY, fontWeight: "bold" }}
              >
                {courseData?.title ?? "-"}
              </Col>
            </Row>
            <div style={{ height: 10 }} />
            <Row>
              <Col>ລະຫັດວິຊາ</Col>
              <Col
                style={{ color: Consts.FONT_COLOR_PRIMARY, fontWeight: "bold" }}
              >
                {courseData?.courseCode ?? "-"}
              </Col>
            </Row>
            <div style={{ height: 10 }} />
            <Row>
              <Col>ຈໍານວນຫນ່ວຍກິດ</Col>
              <Col
                style={{ color: Consts.FONT_COLOR_PRIMARY, fontWeight: "bold" }}
              >
                {courseData && courseData?.unit}
              </Col>
            </Row>
          </div>

          {/* -------- ຄະນະແລະພາກວິຊາ -------- */}
          <div style={{ paddingTop: 20, paddingBottom: 0 }}>
            <div>
              <i className="fa fa-caret-down " style={{ color: "#057CAE" }} />
              &nbsp;<b>ຄະນະ, ພາກວິຊາ ແລະ ສາຂາວິຊາ</b>
              <div style={{ paddingLeft: 10, fontSize: 14 }}>
                <b>&nbsp;&nbsp;# ຄະນະ</b>
              <table border="1" bordercolor="#fff" style={{ width: "100%" }}>
                <thead>
                  <TableHeader>
                    <th style={{ width: 60 }}>ລຳດັບ</th>
                    <th>ຊື່ຄະນະ</th>
                  </TableHeader>
                </thead>
                <tbody>
                  {courseFaculties?.length > 0 &&
                    courseFaculties?.map((item, index) => (
                      <tr
                        style={{
                          borderBottom: "2px solid #ffff",
                          textAlign: "center",
                        }}
                        key={index}
                      >
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                              <p>
                                {item?.faculty?.name ?? "-"}
                              </p>
                        </TableCell>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            
            <div style={{ paddingLeft: 10, fontSize: 14 }}>
              <b>&nbsp;&nbsp;# ພາກວິຊາ</b>
              <table border="1" bordercolor="#fff" style={{ width: "100%" }}>
                <thead>
                  <TableHeader>
                    <th style={{ width: 60 }}>ລຳດັບ</th>
                    <th>ຊື່ພາກວິຊາ</th>
                  </TableHeader>
                </thead>
                <tbody>
                {courseDepartment?.length > 0 &&
                  courseDepartment?.map((item, index) => (
                    <tr
                      style={{
                        borderBottom: "2px solid #ffff",
                        textAlign: "center",
                      }}
                      key={index}
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        <p>
                          {item?.department?.name ?? "-"}
                        </p>
                      </TableCell>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div style={{ paddingLeft: 10, fontSize: 14 }}>
              <b>&nbsp;&nbsp;# ສາຂາວິຊາ</b>
              <table border="1" bordercolor="#fff" style={{ width: "100%" }}>
                <thead>
                  <TableHeader>
                    <th style={{ width: 60 }}>ລຳດັບ</th>
                    <th>ຊື່ສາຂາວິຊາ</th>
                  </TableHeader>
                </thead>
                <tbody>
                {courseMajor?.length > 0 &&
                  courseMajor?.map((item, index) => (
                    <tr
                      style={{
                        borderBottom: "2px solid #ffff",
                        textAlign: "center",
                      }}
                      key={index}
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        <p>
                          {item?.major?.name ?? "-"}
                        </p>
                      </TableCell>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            </div>
          </div>

          {/* -------- ປີຮຽນແລະພາກຮຽນ -------- */}
          <div style={{ paddingTop: 20, paddingBottom: 0 }}>
            <div>
              <i className="fa fa-caret-down" style={{ color: "#057CAE" }} />
              <b>&nbsp;ປີຮຽນ ແລະ ພາກຮຽນ</b>
            </div>
            <div style={{ paddingLeft: 10, fontSize: 14 }}>
              <Row>
                <Col>ປີຮຽນ</Col>
                <Col>{courseData && courseData?.year}</Col>
              </Row>
              <Row>
                <Col>ພາກຮຽນ</Col>
                <Col>{courseData && courseData?.semester}</Col>
              </Row>
            </div>
          </div>

          <div style={{ paddingTop: 20, paddingBottom: 0 }}>
            <i className="fa fa-caret-down " style={{ color: "#057CAE" }} />
              <b>&nbsp;ຫ້ອງນັກສຶກສາ</b>
            <div style={{ paddingLeft: 10, fontSize: 14 }}>
              <table border="1" bordercolor="#fff" style={{ width: "100%" }}>
                <thead>
                  <TableHeader>
                    <th style={{ width: 60 }}>ລຳດັບ</th>
                    <th>ຊື່ຫ້ອງນັກສຶກສາ</th>
                  </TableHeader>
                </thead>
                <tbody>
                {courseClassroom?.length > 0 &&
                    courseClassroom?.map((item, index) => (
                      <tr
                        style={{
                          borderBottom: "2px solid #ffff",
                          textAlign: "center",
                        }}
                        key={index}
                      >
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          <p>{item?.classRoom?.name}</p>
                        </TableCell>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>

          <div style={{ paddingTop: 20, paddingBottom: 0 }}>
            {/* <div>ອາຈານສອນ</div> */}
            <i className="fa fa-caret-down " style={{ color: "#057CAE" }} />
              <b>&nbsp;ອາຈານສອນ</b>
            <div style={{ paddingLeft: 10, fontSize: 14 }}>
              <table border="1" bordercolor="#fff" style={{ width: "100%" }}>
                <thead>
                  <TableHeader>
                    <th style={{ width: 60 }}>ລຳດັບ</th>
                    <th>ຊື່ອາຈານສອນ</th>
                  </TableHeader>
                </thead>
                <tbody>
                {courseData?.teacher?.length > 0 &&
                    courseData?.teacher?.map((item, index) => (
                      <tr
                        style={{
                          borderBottom: "2px solid #ffff",
                          textAlign: "center",
                        }}
                        key={index}
                      >
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          {
                            // courseData?.teacher?.length > 0 &&
                            // courseData?.teacher?.map((item) => (
                            <p>
                              {item?.firstname +
                                " " +
                                (item?.lastname ? item?.lastname : "")}
                            </p>
                            // ))
                          }
                        </TableCell>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>

          <div style={{ paddingTop: 20, paddingBottom: 0 }}>
            <div>
              <i className="fa fa-caret-down" style={{ color: "#057CAE" }} />
              <b>&nbsp;ອາທິດທີ່ສອນ</b>
            </div>
            <div style={{ paddingLeft: 10, fontSize: 14 }}>
              <Row>
                <Col>ອາທິດທີ່ສອນ</Col>
                <Col>{courseData && convertWeek(courseData?.week)}</Col>
              </Row>
            </div>
          </div>
          {/* -------- ຕາຕະລາງມື້ສອນ -------- */}
          <div style={{ paddingTop: 20, paddingBottom: 0 }}>
            {/* <div>ຕາຕະລາງມື້ສອນ</div> */}
            <i className="fa fa-caret-down " style={{ color: "#057CAE" }} />
              <b>&nbsp;ຕາຕະລາງມື້ສອນ</b>
            <div style={{ paddingLeft: 10, fontSize: 14 }}>
              <table border="1" bordercolor="#fff" style={{ width: "100%" }}>
                <thead>
                  <TableHeader>
                    <th style={{ width: 60 }}>ລຳດັບ</th>
                    <th>ວັນ</th>
                    <th>ຊົ່ວໂມງ</th>
                  </TableHeader>
                </thead>
                <tbody>
                {courseData &&
                    courseData?.dayTimeIndexes &&
                    courseData?.dayTimeIndexes?.length > 0 &&
                    courseData?.dayTimeIndexes.map((item, index) => (
                      <tr
                        style={{
                          borderBottom: "2px solid #ffff",
                          textAlign: "center",
                        }}
                        key={index}
                      >
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          {item?.day ? reconvertDay(item?.day) : "-"}
                        </TableCell>
                        <TableCell>
                          {item?.timeIndexes &&
                            item?.timeIndexes?.length > 0 &&
                            item?.timeIndexes?.map(
                              (t, ti) =>
                                parseInt(t) +
                                1 +
                                (ti + 1 >= item?.timeIndexes?.length ? "" : " - ")
                            )}
                        </TableCell>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>

          <div style={{ paddingTop: 20, paddingBottom: 0 }}>
            <div>
              <i className="fa fa-caret-down" style={{ color: "#057CAE" }} />
              <b>&nbsp;ສະຖານທີ່ຮຽນ</b>
            </div>
            <div style={{ paddingLeft: 10, fontSize: 14 }}>
              <Row>
                <Col>ສະຖານທີ່ຮຽນ</Col>
                <Col>{courseData && courseData?.addressLearn}</Col>
              </Row>
            </div>
          </div>

          {/* -------- ຄໍາອະທິບາຍ -------- */}
          <div style={{ paddingTop: 20, paddingBottom: 0 }}>
            <div>
              <i className="fa fa-caret-down" style={{ color: "#057CAE" }} />
              <b>&nbsp;ຄໍາອະທິບາຍ</b>
            </div>
            <div style={{ paddingLeft: 10, fontSize: 14 }}>
              <Row>
                <Col>ເນື້ອໃນຂອງວິຊາ</Col>
                <Col>{courseData && courseData?.description}</Col>
              </Row>
            </div>
          </div>

          {/* -------- ອັບໂຫລດ Syllabus -------- */}
          <div style={{ paddingTop: 20, paddingBottom: 0 }}>
            <div>
              <i className="fa fa-caret-down" style={{ color: "#057CAE" }} />
              <b>&nbsp;ອັບໂຫລດ Syllabus</b>
            </div>
            <div style={{ paddingLeft: 10, fontSize: 14 }}>
              <Row>
                <Col>ອັບໂຫລດໄຟລ (PDF)</Col>
                <Col>
                <a
                    href={
                      courseData &&
                      courseData?.syllabusFile &&
                      courseData?.syllabusFile?.file
                    }
                  >
                    {courseData &&
                      courseData?.syllabusFile &&
                      courseData?.syllabusFile?.title}
                  </a>
                </Col>
              </Row>
            </div>
          </div>
        </div>

        {/* ------- Delete Modal ------ */}
        {/* DeleteConfirm Modal */}
        <CourseDeleteConfirm
          showDeleteConfirmView={showDeleteConfirmView}
          _handleDeleteConfirmViewClose={_handleDeleteConfirmViewClose}
        />
      </CustomContainer>
    </div>
  );
}

export default CourseDetail;
