import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { NEW_API_URL, API_KEY } from "../../common/constraint";
import axios from "axios";

const RenameDialog = ({ open, message, onSubmit, onCancel, type, id }) => {
  const [name, setName] = useState("");

  const renameFolder = async () => {
    try {
      await axios.patch(
        NEW_API_URL + "filefolder?folderId=" + id,
        {
          foloderName: name,
        },
        {
          headers: {
            api_key: API_KEY,
          },
        }
      );
      onSubmit(true);
    } catch (err) {
      onSubmit(false);
    }
  };

  const renameFile = async () => {
    try {
      await axios.patch(
        NEW_API_URL + "course/files?fileId=" + id,
        {
          fileName: name,
        },
        {
          headers: {
            api_key: API_KEY,
          },
        }
      );
      onSubmit(true);
    } catch (err) {
      onSubmit(false);
    }
  };
  const handleSubmit = () => {
    if (type === "FOLDER") {
      renameFolder();
    }
    if (type === "FILE") {
      renameFile();
    }
  };

  return (
    <Modal
      open={open}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <div className="bg-white px-5  pt-5 pb-4 d-flex flex-column align-items-center">
          <TextField
            onChange={(e) => setName(e.target.value)}
            style={{
              width: "300px",
            }}
            id="standard-basic"
            placeholder={message}
          />
          <div className="col mt-3 text-center">
            <Button
              disabled={name === "" ? true : false}
              style={{
                marginRight: "10px",
              }}
              onClick={handleSubmit}
              variant="contained"
              color="secondary"
            >
              ຕົກລົງ
            </Button>
            <Button
              onClick={() => {
                onCancel();
              }}
              variant="outlined"
              color="primary"
            >
              ຍົກເລີກ
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RenameDialog;
