import React, { useEffect, useState } from "react";
import axios from "axios";
import useReactRouter from "use-react-router";

import { NEW_API_URL, API_KEY } from "../../../common/constraint";
import { USER_KEY } from "../../../consts/user";

export const GiveGrade = () => {
  const { location, match } = useReactRouter();
  const dataProp = location.state;
  const [gradeIdList, setGradeIdList] = useState([]);
  const [classRoom, setClassRoom] = useState("");
  const [courseData, setCourseData] = useState("");
  const [courseClassroom, setCourseClassroom] = useState();
  const [filters, setFilters] = useState();
  const [scoreStatus, setScoreStatus] = useState("");

  const user = localStorage.getItem(USER_KEY);
  const userObject = JSON.parse(user);
  const token = userObject?.accessToken;



  useEffect(() => {
    if (dataProp) {
      setFilters({
        assessmentYear: dataProp?.courseData.assessmentYear,
        year: dataProp?.courseData.year,
        semester: dataProp?.courseData.semester,
        courseId: dataProp?.courseData.id,
      });

      if (dataProp.courseClassroom.length > 0) {
        setClassRoom(dataProp?.courseClassroom[0].classRoom?.id);
      }
      setCourseClassroom(dataProp?.courseClassroom[0]);
      setCourseData(dataProp?.courseData);
    }
  }, [dataProp]);

  const fetchStudent = async () => {
    if (courseData) {
      await axios
        .get(
          NEW_API_URL +
            `student/class/${courseData?.assessmentYear}/${
              courseData.id || ""
            }`,
          {
            headers: {
              api_key: API_KEY,
            },
          }
        )
        .then((res) => {
          setGradeIdList(res.data?.students);
          if (res.data?.students.length > 0) {
            setScoreStatus(res.data?.students[0]?.sendingGradeStatus);
          }
        })
        .catch((err) => {
          // console.log("fetch student error");
          // console.log(err);
        });
    }
  };

  useEffect(() => {
    fetchStudent();
  }, [classRoom, courseData]);

  const handleSelectChange = async (e, student) => {
    const grade = e.target.value; // Get the selected value from the event
    const studentId = student.student._id;
    const body = {
      studentId: studentId,
      courseId: courseData.id,
      year: courseData.year,
      semester: courseData.semester,
      assessmentYear: courseData.assessmentYear,
      grade: grade,
    };

    await axios
      .post(NEW_API_URL + "score/give", body, {
        headers: {
          api_key: API_KEY,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        fetchStudent();
        // Handle the response data here
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        console.error("Error:", error);
      });
  };

  const sendGrade = async () => {
    await axios
      .post(
        NEW_API_URL + "score/teacher/send",
        {
          gradeIdList: gradeIdList,
          filters: filters,
        },
        {
          headers: {
            api_key: API_KEY,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        alert("ສົ້ງຄະເເນນສຳເລັດ");
        fetchStudent();
        // Handle the response data here
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        alert("ສົ້ງຄະເເນນບໍ່ສຳເລັດ");

        console.error("Error:", error);
      });
  };

  const [canEdit, setCanEdit] = useState(false);
  useEffect(() => {
    if (
      scoreStatus === "TEACHER_SCORING" ||
      scoreStatus === "DEPARTMENT_REJECTED"
    ) {
      setCanEdit(true);
    } else {
      setCanEdit(false);
    }
  }, [scoreStatus]);

  return (
    <div className="px-5 pt-5 bg-white">
      <div className="row">
        <div className="col">
          <h3 className="text-center">ເພິ່ມຄະເເນນນັກສຶກສາ</h3>
        </div>
      </div>
      <div className="row mb-4 mt-4">
        <div className="col">
          <h5 className="text-center">
            ຄະນະ: {courseClassroom?.faculty?.name}
          </h5>
        </div>
        <div className="col">
          <h5 className="text-center">
            ພາກວິຊາ: {courseClassroom?.department?.name}
          </h5>
        </div>
        <div className="col">
          <h5 className="text-center">ສາຂາ: {courseClassroom?.major?.name}</h5>
        </div>
        <div className="col-1">
          <h5 className="text-center">
            ຫ້ອງຮຽນ: {courseClassroom?.classRoom.name}
          </h5>
        </div>
        <div className="col">
          <h5 className="text-center">ຊື່ວິຊາ: {courseData?.title}</h5>
        </div>
        <div className="col-1">
          <h5 className="text-center">ປີຮຽນ: {courseData?.year}</h5>
        </div>
        <div className="col-1">
          <h5 className="text-center">ພາກຮຽນ: {courseData?.semester}</h5>
        </div>
      </div>

      <div className="col">
        {scoreStatus === "TEACHER_SCORING" ? (
          <div className="w-100 text-end mb-4 pe-4">
            <button
              onClick={() => {
                sendGrade();
              }}
              type="button"
              className="btn btn-primary px-5"
            >
              ສົ່ງຄະແນນ
            </button>
          </div>
        ) : scoreStatus === "DEPARTMENT_REJECTED" ? (
          <div className="w-100 text-end mb-4 pe-4">
            <span className="pl-3">ຄະເເນນຖືກປະຕິເສດ</span>
            <button
              onClick={() => {
                sendGrade();
              }}
              type="button"
              className="btn btn-danger px-5"
            >
              ສົ່ງຄະແນນໃຫມ່
            </button>
          </div>
        ) : (
          (scoreStatus === "TEACHER_SUBMITED" ||
            scoreStatus === "DEPARTMENT_SUBMITED" ||
            scoreStatus === "DEPARTMENT_REJECTED" ||
            scoreStatus === "FACULTY_CHECKING" ||
            scoreStatus === "FACULTY_SUBMITED" ||
            scoreStatus === "FACULTY_REJECTED" ||
            scoreStatus === "FACULTY_SUBMITED" ||
            scoreStatus === "ADMIN_APPROVED") && (
            <div className="w-100 text-end mb-4 pe-4">
              <button
                onClick={() => {
                  // Code to handle other conditions
                }}
                type="button"
                className="btn btn-secondary px-5"
              >
                ຄະເເນນຖືກສົ່ງແລ້ວ
              </button>
            </div>
          )
        )}
      </div>

      <table className="table table-striped">
        <thead className="text-center">
          <tr>
            <th colSpan={1} className="py-3 text-center">
              ລຳດັບ
            </th>
            <th colSpan={2} className="py-3 text-center">
              ລະຫັດນັກສຶກສາ
            </th>
            <th colSpan={3} className="py-3 text-center">
              ຊື່ ແລະ ນາມສະກຸນ
            </th>
            <th colSpan={2} className="py-3 text-center">
              ຄະເເນນ (ເກລດ)
            </th>
          </tr>
        </thead>
        <tbody>
          {gradeIdList.length > 0 &&
            gradeIdList.map((student, index) => (
              <tr
                key={student._id}
                className={index % 2 === 0 ? "even-row" : "odd-row"}
              >
                <td colSpan={1} className="text-center pt-4">
                  {index + 1}
                </td>
                <td colSpan={2} className="text-center pt-4">
                  {student.student.studentNumber}
                </td>
                <td colSpan={3} className="text-center pt-4 text-start">
                  {student.student.firstNameL +
                    "  " +
                    student.student.lastNameL}
                </td>
                <td colSpan={2} className="text-center pt-3">
                  <div className="w-100 text-center d-flex justify-content-center">
                    <select
                      disabled={!canEdit}
                      value={student?.grade || ""}
                      className="form-control w-50 text-center"
                      onChange={(e) => handleSelectChange(e, student)}
                    >
                      <option value="">ເລືອກຄະເເນນ</option>
                      <option value="A">A</option>
                      <option value="B+">B+</option>
                      <option value="B">B</option>
                      <option value="C+">C+</option>
                      <option value="C">C</option>
                      <option value="D+">D+</option>
                      <option value="D">D</option>
                      <option value="F">F</option>
                      <option value="I">I</option>
                    </select>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
