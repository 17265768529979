import React from "react";
import Modal from "@material-ui/core/Modal";
import { Typography, Button } from "@material-ui/core";

const DetailDialog = ({ open, data, onCancel }) => {
  return (
    <Modal
      open={open}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <div
          style={{
            borderRadius: "9px",
            boxShadow: "0px 3px 6px #00000029",
            padding: "24px",
            backgroundColor: "#ffffff",
            width: "500px",
          }}
        >
          <Typography variant="h6" gutterBottom>
            <b>ລາຍລະອຽດ</b>
          </Typography>
          <div className="row">
            <div className="col-12 text-start">
              <Typography>{"ຊື່ Folder : " + data?.folderName}</Typography>
            </div>
            <div className="col-12 text-start">
              <Typography>{"ຊື່ວິຊາ : " + data?.course?.title}</Typography>
            </div>
            <div className="col-12 text-start">
              <Typography>
                {"ສົກຮຽນ : " + data?.course?.assessmentYear}
              </Typography>
            </div>
            <div className="col-12 text-start">
              <Typography>{"ປິຮຽນ : " + data?.course?.year}</Typography>
            </div>
            <div className="col-12 text-start">
              <Typography>{"ພາກຮຽນ : " + data?.course?.semester}</Typography>
            </div>
            <div className="d-flex flex-row text-start">
              <div>ຫ້ອງຮຽນ</div>
              <div>
                {data?.classRoom
                  ? data.classRoom.map((_class, index) => {
                      return (
                        <div key={index} className="col-12 text-start mx-3">
                          <Typography>{_class.name}</Typography>
                        </div>
                      );
                    })
                  : "No classes available"}
              </div>
            </div>
            <div className="col-12 text-start">
              <Typography>
                {"ສ້າງວັນທິ : " + new Date(data?.createdAt).toLocaleString()}
              </Typography>
            </div>
          </div>

          {/* Close Button */}
          <div style={{ marginTop: "16px", textAlign: "center" }}>
            <Button variant="contained" onClick={onCancel}>
              ປິດ
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DetailDialog;
