import React from "react";
import { Modal, Button } from "react-bootstrap";
import Consts from "../../consts";

const CourseDocDeleteConfirm = ({
  handleDeleteFile,
  courseDocDeleteConfirmModal,
  _handlCourseDocDeleteConfirmModalClose,
  fileTitle,
}) => {
  return (
    <div>
      <Modal
        show={courseDocDeleteConfirmModal}
        onHide={_handlCourseDocDeleteConfirmModalClose}
        size="sm"
      >
        <Modal.Title style={{ textAlign: "center", paddingTop: 10 }}>
          ຕ້ອງການລຶບເອກະສານ?
        </Modal.Title>
        <Modal.Body style={{ marginLeft: 50, marginRight: 50, padding: 50 }}>
          <p className="text-center">{fileTitle ? fileTitle : "-"}</p>
          <div className="row">
            <div className="d-flex justify-content-center">
              <button
                onClick={handleDeleteFile}
                className="btn btn-danger mr-2"
              >
                ຕົກລົງ
              </button>
              <button
                onClick={_handlCourseDocDeleteConfirmModalClose}
                className="btn btn-primary"
              >
                ຍົກເລີກ
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CourseDocDeleteConfirm;
