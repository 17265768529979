
const VERSION = "SLMS Teacher v1.0.23"
const VERSION_LOGIN = "v1.0.23"
// production
const MOODLE_URL = "http://54.255.147.171:888"

// staging
// const MOODLE_URL = "http://54.254.4.191:888"

module.exports = {
  PRIMARY_COLOR: "#057CAE",
  SECONDARY_COLOR: "#6f6f6f",
  FONT_COLOR_PRIMARY: "#383838",
  FONT_COLOR_SECONDARY: "#6f6f6f",
  CONTAINER_PADDING: 24,
  CONTAINER_MARGIN_TOP: -10,
  FONT_COLOR_WHITE: "#fff",
  TAB_COLOR: "#057CAE",
  COLOR_EDIT: "#7bb500",
  COLOR_DELETE: "#B80000",
  MOODLE_URL,
  VERSION,
  VERSION_LOGIN
};
