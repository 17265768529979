import React, { useState, useCallback, useEffect } from "react";
import useReactRouter from "use-react-router";
import { useDropzone } from "react-dropzone";
import { Breadcrumb, Form, Row, Col, ProgressBar } from "react-bootstrap";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import { Formik } from "formik";
import axios from "axios";
// component ------------------------------------------------>
import { CustomContainer, Title, CustomButton } from "../../common";
import Loading from "../../common/Loading";
// constance ------------------------------------------------>
import { COURSE_EDIT, UPDATE_COURSE, PRE_SIGNED_URL } from "../../apollo/course";
import { COURSES } from "../../apollo/course";
import { CREATE_FILE, UPDATE_FILE } from "../../apollo/file";
// import { createFilter } from "react-select/dist/Select-95fb2b5c.cjs.prod";
function CourseEdit() {
  const { history, match, location } = useReactRouter();
  const { id } = match?.params;
  const fileId = location?.state?.syllabusFile?.id
  // state ----------------------------------------------------->
  // const [errFile, setErrFile] = useState("");
  const [files, setFiles] = useState([]);
  const [fileName, setFileName] = useState('')
  const [fileType, setFileType] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [idTeacher, setIdTeacher] = useState()
  const [moodleCourseId, setMoodleCourseId] = useState()
  const [fileUploadProgress, setFileUploadProgress] = useState(0);
  // apollo ---------------------------------------------------->
  const { loading, error, data } = useQuery(COURSE_EDIT, {
    variables: { where: { id } },
  });
  const [loadCourse, { data: courseData }] = useLazyQuery(COURSES, { variables: { where: { id: id } } })
  // const { data: preSignedUrlData } = useQuery(PRE_SIGNED_URL, {
  //   variables: { mimeType: "application/pdf" },
  // });

  const [loadFile,
    { called, loading: loadingPresign, data: preSignedUrlData }
  ] = useLazyQuery(PRE_SIGNED_URL, {
    variables: { mimeType: fileType, fileName: fileName }
  })

  useEffect(() => {
    loadFile()
    loadCourse()
  }, [fileType, fileName])

  useEffect(() => {
    setIdTeacher(courseData?.courses?.data[0]?.teacher[0]?.id)
    setMoodleCourseId(courseData?.courses?.data[0]?.moodleCourseId)
  }, [courseData])

  const [updateFile] = useMutation(UPDATE_FILE);
  const [createFile] = useMutation(CREATE_FILE);
  const [updateCourse] = useMutation(UPDATE_COURSE);
  // functional ------------------------------------------------>
  const onDrop = useCallback(async (acceptedFiles) => {
    // TODO: store files in state
    setFiles(acceptedFiles);
    let filePath = acceptedFiles[0].path.split('.')
    let _fileType
    if (filePath[filePath.length - 1] === 'jpg' || filePath[filePath.length - 1] === 'png') {
      _fileType = 'image/' + filePath[filePath.length - 1]
    } else {
      _fileType = 'application/' + filePath[filePath.length - 1]
    }
    setFileName(filePath[filePath.length - 2])
    setFileType(_fileType)
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    acceptedFiles,
  } = useDropzone({ onDrop });

  const fileUploaded = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} ຂະຫນາດ: {file.size} bytes
    </li>
  ));

  // const _handleChangeTitleName = (e) => {
  //   setTitleName(e.target.value)
  //   setErrTitle('')
  // }

  const _uploadFile = async (param) => {
    setIsLoading(true)
    const { preSignedUrl } = preSignedUrlData;
    // Save file to s3
    if (acceptedFiles.length > 0) {
      if (preSignedUrl) {
        const response = await axios({
          method: "put",
          url: preSignedUrl.url,
          data: files[0],
          headers: {
            "Content-Type": " file/*; image/*",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
            "Access-Control-Allow-Headers":
              "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
          },
          onUploadProgress: function (progressEvent) {
            setFileUploadProgress(
              parseInt(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              )
            );
          },
        });
        let filename = response.config.url.split("?");

        let _data = {
          file: filename[0],
          title: fileName,
        };
        // set data form when file is uploaded
        if (fileId === undefined) {
          let paramQL = {
            data: {
                ..._data, type: "SYLLABUS_FILE"
            }
          };
          await createFile({
            variables: {
              ...paramQL
            }
          }).then(async (x) => {
            let paramQLCourse = {
              data: {
                description: param,
                syllabusFile: x.data.createFile.id,
              },
              where: {
                id: id
              }
            }
            updateCourse({ variables: paramQLCourse })
              history.push(`/course-detail/id/${id}`);
              window.location.reload()
          })
        }

        let paramQL = {
          data: {
            ..._data,
          },
          where: {
            id: fileId,
          },
        };
        const _updateFile = await updateFile({ variables: paramQL })
        if (_updateFile) {
          let paramQLCourse = {
            data: {
              description: param,
            },
            where: {
              id:id
            },
          };
         updateCourse({ variables: paramQLCourse })
              history.push(`/course-detail/id/${id}`);
              window.location.reload()
        }

      }
    } else {

      let paramQL = {
        data: {
          description: param,
        },
        where: {
          id:id,
        },
      };

      updateCourse({ variables: paramQL })
          history.push(`/course-detail/id/${id}`);
          window.location.reload();
    }
  };

  if (loading || isLoading) return <Loading />;
  if (error) return <h1>GraphQL error</h1>;
  if (data) {
  }
  return (
    <div>
      {/* Breadcrumb */}
      <Breadcrumb>
        <Breadcrumb.Item href="" onClick={() => history.push("/course-list/1")}>
          ຈັດການວິຊາ
        </Breadcrumb.Item>
        <Breadcrumb.Item active>ແກ້ໄຂວິຊາ</Breadcrumb.Item>
      </Breadcrumb>

      <CustomContainer>
        <Title text="ແກ້ໄຂວິຊາ" />
        {data && <Formik
          initialValues={{ description: data.course.description || "" }}
          validate={(values) => {
            const errors = {};
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            _uploadFile(values.description);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <div
              style={{ width: "80%", marginLeft: "auto", marginRight: "auto" }}
            >
              {/* ---------- ຄໍາອະທິບາຍ --------- */}
              <div style={{ marginBottom: 10 }}>
                <div>
                  <i
                    className="fa fa-caret-down"
                    aria-hidden="true"
                    style={{ marginRight: 5 }}
                  />
                  ຄໍາອະທິບາຍ
                </div>
                {/* ເນື້ອໃນຂອງວິຊາ */}
                <Form.Group
                  as={Row}
                  style={{
                    margin: 0,
                    marginBottom: 10,
                    paddingLeft: 20,
                    fontSize: 16,
                  }}
                >
                  <Form.Label column sm="4" className="text-left">
                    ເນື້ອໃນຂອງວິຊາ
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      as="textarea"
                      rows="3"
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                    />
                  </Col>
                </Form.Group>
              </div>

              {/* ---------- ອັບໂຫລດ --------- */}
              <div style={{ marginBottom: 10 }}>
                <div>
                  <i
                    className="fa fa-caret-down"
                    aria-hidden="true"
                    style={{ marginRight: 5 }}
                  />
                  ອັບໂຫລດ
                </div>
                {/* ອັບໂຫລດໄຟລ */}
                <Form.Group
                  as={Row}
                  style={{
                    margin: 0,
                    marginBottom: 10,
                    paddingLeft: 20,
                    fontSize: 16,
                  }}
                >
                  <Form.Label column sm="4" className="text-left">
                    ອັບໂຫລດໄຟລ
                  </Form.Label>
                  <Col sm="8">
                    <div
                      {...getRootProps()}
                      style={{
                        height: 100,
                        border: "1px solid #ddd",
                        outline: "none",
                        cursor: "pointer",
                        textAlign: "center",
                      }}
                      className="mb-4"
                    >
                      <input {...getInputProps()} />

                      <div
                        style={{
                          display: "flex",
                          backgroundColor: "#f3f3f3",
                          marginLeft: 20,
                          marginTop: 20,
                          marginRight: 20,
                          height: 50,
                          textAlign: "center",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {/* file name */}
                        <span>{data?.course?.syllabusFile?.file ? (data?.course?.syllabusFile?.title + "." + (data?.course?.syllabusFile?.file?.split('/')[4]).split(".")[(data?.course?.syllabusFile?.file?.split('/')[4]).split(".").length - 1]) : ""
                        }</span>
                      </div>
                      <span>Drag and drop or Browse</span>
                    </div>
                    {acceptedFiles.length > 0 && (
                      <aside>
                        <h4>ໄຟລທີ່ຈະອັບໂຫລດ</h4>
                        <ul>{fileUploaded}</ul>
                      </aside>
                    )}
                    {fileUploadProgress > 0 && fileUploadProgress < 100 && (
                      <div>
                        <h3>ກໍາລັງອັບໂຫລດໄຟລເອກະສານ....</h3>
                        <ProgressBar
                          animated
                          now={fileUploadProgress}
                          label={`${fileUploadProgress}%`}
                        />
                      </div>
                    )}
                  </Col>
                </Form.Group>
              </div>

              {/* Buttons */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  textAlign: "center",
                  justifyContent: "center",
                  marginTop: 40,
                  marginBottom: 40,
                }}
              >
                <div style={{ marginRight: 80 }}>
                  <button
                    style={{
                      backgroundColor: "#fff",
                      color: "#057CAE",
                      width: 140,
                      height: 40,
                      border: "1px solid #057CAE",
                      outline: "none",
                    }}
                    onClick={() => history.push(`/course-detail/id/${id}`)}
                  >
                    ຍົກເລີກ
                  </button>
                </div>

                <CustomButton
                  confirm
                  title="ບັນທຶກການແກ້ໄຂ"
                  onClick={() => handleSubmit()}
                />
              </div>
            </div>
          )}
        </Formik>}
      </CustomContainer>
    </div>
  );
}

export default CourseEdit;
