import React from "react";

const FileIconOption = ({ type, link }) => {
  switch (type) {
    case "pdf":
      return (
        <img
          width="48"
          height="48"
          src="https://img.icons8.com/color/48/pdf-2--v1.png"
          alt="pdf-2--v1"
        />
      );
    case "xls":
      return (
        <img
          width="48"
          height="48"
          src="https://img.icons8.com/color/48/xls.png"
          alt="xls"
        />
      );
    case "xlsx":
      return (
        <img
          width="48"
          height="48"
          src="https://img.icons8.com/color/48/xls.png"
          alt="xls"
        />
      );
    case "doc":
      return (
        <img
          width="48"
          height="48"
          src="https://img.icons8.com/fluency/48/google-docs--v2.png"
          alt="google-docs--v2"
        />
      );
    case "doc":
      return (
        <img
          width="48"
          height="48"
          src="https://img.icons8.com/fluency/48/google-docs--v2.png"
          alt="google-docs--v2"
        />
      );
    case "png":
      return <img width="48" height="48" src={link} alt="stack-of-photos" />;
    case "jpeg":
      return <img width="48" height="48" src={link} alt="stack-of-photos" />;
    case "pptx":
      return (
        <img
          width="48"
          height="48"
          src="https://img.icons8.com/color/48/ms-powerpoint--v1.png"
          alt="ms-powerpoint--v1"
        />
      );
    default:
      return (
        <img
          width="48"
          height="48"
          src="https://img.icons8.com/color/48/file.png"
          alt="file"
        />
      );
  }
};

export default FileIconOption;
