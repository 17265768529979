import React from "react";
import { Modal, Form } from "react-bootstrap";
import { Formik } from "formik";
import { CustomButton } from "../../common";


const RegisterSearch = ({ showSearchView, _handleSearchViewClose, onSearch}) => {
  return (
    <Formik
      initialValues={{
        createdID: ""
      }}
      onSubmit={(values) => {
        onSearch(values);
        _handleSearchViewClose();
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
          <Modal show={showSearchView} onHide={_handleSearchViewClose} size="lg">
            <Modal.Title style={{ textAlign: "center", paddingTop: 20 }}>
              ຄົ້ນຫານັກສຶກສາ
          </Modal.Title>

            <Modal.Body style={{ marginLeft: 50, marginRight: 50, padding: 50 }}>
              <div style={{ display: "flex", flexDirection: "row",justifyContent:'center' }}>
                <a style={{ marginRight: 20, fontWeight: "bold",marginTop:5 }}>ຄົ້ນຫາ</a>
                <div style={{ width: "100%" }}>
                  <Form.Control
                    type="text"
                    placeholder="ປ້ອນ ID ນັກສຶກສາ"
                    name="createdID"
                    value={values.createdID}
                    onChange={handleChange}
                    style={{
                      width: "100%",
                      height: 35,
                      borderRadius: 15,
                      outline: "none",
                      borderColor: "1px solid #eee",
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div style={{ padding: 15 }} className="col">
                  <CustomButton
                    confirm
                    onClick={handleSubmit}
                    width="100%"
                    title="ຄົ້ນຫາ"
                  />
                </div>
              </div>
            </Modal.Body>
          </Modal>
        )}
    </Formik>
  );
};

export default RegisterSearch;
