import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";
import { Breadcrumb, Pagination } from "react-bootstrap";
import { useLazyQuery } from "@apollo/react-hooks";
import { USER_KEY } from '../../consts/user'
// component ----------------------------------------------------------------------->
import UpgradeRegisterModal from './UpgradeRegisterModal'
import UnUpgradeRegisterModal from './UnUpgradeRegisterModal'
import Loading from "../../common/Loading";
// consts -------------------------------------------------------------------------->
import Consts from "../../consts";
import { CustomContainer, SearchBar, Title, TableHeader, TableCell} from "../../common";
import { GRADES_REQUESTING } from "../../apollo/grade";
import UpgradeSearch from "./upgradeSearch";
const UpgradeList = () => {
  const { history, match } = useReactRouter();
  // states ------------------------------------------------------------->
  const [showSearchView, setShowSearchView] = useState(false);
  const [isShowUpgrade, setIsShowUpgrade] = useState(false)
  const [isShowUnUpgrade, setIsShowUnUpgrade] = useState(false)
  const [upgradeData, setUpgradeData] = useState([])
  const [upgradeDatadetail, setUpgradeDataDetail] = useState({})
  const [studentId, setStudentId] = useState()
  // apollo ------------------------------------------------------------->

  const { data: { id } } = JSON.parse(localStorage.getItem(USER_KEY))
  const numberPage = match.params.page;
  
  const NUMBER_LIMIT_PAGE = 20;
  var student = { student: { userId: student } }
  const [loadData, { loading, data }] = useLazyQuery(GRADES_REQUESTING, {fetchPolicy:"network-only"});

  // use ---------------------------------------------------------------->
  useEffect(() => {
    let _where = {numberTest: 2, gredeStatus: "REQUESTING",}
    if(studentId !==""){
      _where = {..._where,student: studentId}
    }
    loadData({
      variables: {
        where: _where,
      skip: NUMBER_LIMIT_PAGE * (numberPage - 1),
      first: NUMBER_LIMIT_PAGE,
        }
    });
  },[studentId])

  useEffect(() => {
    if (data?.grades?.data) {
      setUpgradeData(data.grades.data);
    }

  },[data]);

  const docCount = data?.grades?.total;
  const countPage = [];
  for (var i = 1; i <= Math.ceil(docCount / NUMBER_LIMIT_PAGE); i++) {
    countPage.push(i);
  }
  const _onBack = () => {
    if (parseInt(numberPage) - 1 < 1) {
      history.push(`/upgrades-list/${1}`);
    } else {
      history.push(`/upgrades-list/${parseInt(numberPage) - 1}`);
    }
  };
  const _onNext = () => {
    if (parseInt(numberPage) + 1 > countPage.length) {
      history.push(`/upgrades-list/${countPage.length}`);
    } else {
      history.push(`/upgrades-list/${parseInt(numberPage) + 1}`);
    }
  };

  // Search
  const _onSearch = async (value) => {
    setStudentId(value.createdID)
  };


  return (
    <div>
      {/* Breadcrumb */}

      <Breadcrumb>
        <Breadcrumb.Item active style={{ fontWeight: "bolder" }}>
        ການອັບເກຣດຄະແນນ
        </Breadcrumb.Item>
      </Breadcrumb>

      <CustomContainer>
        <Title text={"ລາຍຊື່ນັກສຶກສາທີ່ອັບເກຣດ"} />

        {/* custom search button */}
        <SearchBar
          title="ປ້ອນ ID ນັກສຶກສາ"
          onClick={() => setShowSearchView(!showSearchView)}
        />
        <div
          style={{
            marginTop: 24,
            marginBottom: 8,
            fontSize: 16,
            color: Consts.FONT_COLOR_SECONDARY,
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          ທັງຫມົດ {docCount ?? "0"}
          {/* <button
            // onClick={() => _onDocDetail()}
            style={{
              width: 105,
              height: 30,
              borderRadius: 3,
              border: "1px solid #137DAE",
              // outline: "none",
              backgroundColor: "white",
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <FontAwesomeIcon
              icon={["fa", "print"]}
              color="#137DAE"
            />
            &nbsp;
            ລົງທະບຽນ
          </button> */}
        </div>

        {/* Table list */}
        <div>
          <table border="1" bordercolor="#fff" style={{ width: "100%" }}>
            <thead>
              <TableHeader style={{ backgroundColor: "#057CAE" }}>
                <th style={{ width: 70 }}>ລຳດັບ</th>
                <th>ລະຫັດນັກສຶກສາ</th>
                <th>ຊື່ນັກສຶກສາ</th>
                <th>ນັກສຶກສາປີ</th>
                <th>ພາກຮຽນ</th>
                <th>ສົກຮຽນ</th>
                <th>ຊື່ວິຊາທີ່ແກ້ເກຣດ</th>
                <th>ຊື່ອາຈານປະຈຳວິຊາ</th>
                <th>ເກດເກົ່າ</th>
                <th style={{ width: 200 }}>ຈັດການ</th>
              </TableHeader>
            </thead>
            <tbody>
              {loading?<Loading/>: upgradeData?.map((grade, index) => {
                return (
                  <tr
                    style={{
                      border: "2px solid white",
                    }}
                    key={index}
                  >
                    <TableCell
                      style={{
                        backgroundColor: "#EEEEEE",
                        textAlign: "center",
                      }}
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell style={{ backgroundColor: "#EEEEEE" }}>
                      {grade?.student?.userId}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {
                        !grade?.student?.firstNameL || grade?.student?.firstNameL === "" 
                        ? grade?.student?.firstNameE
                        : grade?.student?.firstNameL
                      }
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#EEEEEE",
                        textAlign: "center",
                      }}
                    >
                      {grade?.student?.yearLevel}
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#EEEEEE",
                        textAlign: "center",
                      }}
                    >
                      {grade?.semester}
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#EEEEEE",
                        textAlign: "center",
                      }}
                    >
                      {grade?.student?.assessmentYear ?? ""}
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#EEEEEE",
                        textAlign: "center",
                      }}
                    >
                      {grade?.course?.title}
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#EEEEEE",
                        textAlign: "center",
                      }}
                    >
                      {grade?.note ?? ""}

                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#EEEEEE",
                        textAlign: "center",
                      }}
                    >
                      {grade?.letterGrade}
                    </TableCell>
                    <TableCell
                      style={{
                        width: 200,
                        backgroundColor: "#EEEEEE",
                        textAlign: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          // flexDirection: "row",
                          justifyContent: "space-around",
                          // width: 200,
                        }}
                      >
                        <button
                          onClick={() => {
                            setUpgradeDataDetail(grade);
                            setIsShowUpgrade(!isShowUpgrade)
                          }
                          }
                          style={{
                            width: 100,
                            height: 30,
                            borderRadius: 3,
                            border: "1px solid #137DAE",
                            // outline: "none",
                            backgroundColor: "white",
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          ລົງທະບຽນ
                          </button>
                          &nbsp;&nbsp;&nbsp;
                          <button
                          onClick={() => {
                            setUpgradeDataDetail(grade);
                            setIsShowUnUpgrade(!isShowUnUpgrade)
                          }
                          
                          }
                          // className="btn btn-danger"
                          style={{
                            width: 100,
                            height: 30,
                            borderRadius: 3,
                            border: "1px solid #b30000",
                            // outline: "none",
                            backgroundColor: "white",
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          ປະຕິເສດ
                          </button>
                      </div>
                    </TableCell>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <Pagination
          style={{ display: "flex", justifyContent: "center", marginTop: 20 }}
        >
          <Pagination.Prev onClick={_onBack} />
          {countPage.map((Item, index) => {
            return (
              <Pagination.Item
                key={Item}
                active={Item === numberPage}
                onClick={() => history.push(`/upgrades-list/${Item}`)}
              >
                {Item}
              </Pagination.Item>
            );
          })}

          <Pagination.Next onClick={_onNext} />
        </Pagination>
      </CustomContainer>
      {/* Search Modal */}
      <UpgradeSearch
        showSearchView={showSearchView}
        _handleSearchViewClose={() => setShowSearchView(!showSearchView)}
        onSearch={(value) => _onSearch(value)}
      />
      <UpgradeRegisterModal
        isShowUpgrade={isShowUpgrade}
        _handleUpgradeClose={() => setIsShowUpgrade(!isShowUpgrade)}
        data={upgradeDatadetail}
      />
      <UnUpgradeRegisterModal
        isShowUnUpgrade={isShowUnUpgrade}
        _handleUnUpgradeClose={() => setIsShowUnUpgrade(!isShowUnUpgrade)}
        data={upgradeDatadetail}
      />
    </div>
  );
};

export default UpgradeList;
