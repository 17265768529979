import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Modal from "@material-ui/core/Modal";
import { Button, Typography } from "@material-ui/core";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const StatusDialog = ({ open, message, type, detail, onCancel }) => {
  return (
    <Modal
      open={open}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh", // Adjust the height as needed
        }}
      >
        <div
          style={{
            width: "400px",
          }}
          className="bg-white px-5 py-4 d-flex flex-column align-items-center"
        >
          {type === "error" && (
            <ErrorIcon style={{ fontSize: 60, color: "red" }} />
          )}
          {type === "success" && (
            <CheckCircleOutlineIcon style={{ fontSize: 60, color: "blue" }} />
          )}
          <Typography variant="h5" className="mt-3">
            {message || ""}
          </Typography>
          <Typography variant="body1" className="mt-3">
            {detail || ""}
          </Typography>
          <div className="mt-3">
            <Button
              variant="contained"
              color={type === "error" ? "secondary" : "primary"}
              onClick={onCancel}
            >
              ປິດ
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default StatusDialog;
