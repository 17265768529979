import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { Breadcrumb, Form, Col, Row, Tooltip, OverlayTrigger } from "react-bootstrap";
import Consts from "../../consts";
import Routers from "../../consts/routes";
import {
  valiDate,
  setGenders,
  formatDateWithoutTime,
} from "../../common/super";
import { CustomContainer, TableHeader } from "../../common";
import "./util/index.css";
import * as _ from "lodash";
import { useLazyQuery } from "@apollo/react-hooks";
import {
  GRADES,
  FACULTY_NAME,
} from "../../apollo/grade/index";
import { COURSES, COURSES_FACULTY_DEPARTMENT_FOR_TEACHER } from "../../apollo/course/index";
// import ExcelReader from './ExcelReader'
import { USER_KEY } from "../../consts/user";
import Loading from "../../common/Loading";
import EditGrade from "./EditGrade";
import { QUERY_DEPARTMENT, QUERY_MAJORS } from "../courseList/apollo/query";
import PaginationHelper from "../../helpers/PaginationHelper";
import queryString from "query-string";
import { useSelector } from 'react-redux'
import { CLASSROOM_MASTERS } from "../../apollo/classroom";

function UpgradesList() {
  const { history, location } = useReactRouter();
  const accessmentRedux = useSelector((state) => state.accessment.value);
  const parsed = queryString?.parse(location?.state);
  const { _limit, _skip, Pagination_helper } = PaginationHelper();

  //  user data
  const [userData, setUserData] = useState();

  const [role, setRole] = useState(null);
  const [teacherId, setTeacherId] = useState("");
  const [teacherName, setTeacherName] = useState("");
  // filter data
  const [factId, setFactId] = useState(!parsed?.factId ? "" : parsed?.factId);
  const [departmentId, setDepartmentId] = useState(!parsed?.departmentId ? "" : parsed?.departmentId);
  const [dataDepartments, setDataDepartments] = useState([]);
  const [dataCourses, setDataCourses] = useState([]);

  const [dataFaculties, setDataFaculties] = useState([]);
  const [facultyArray, setFacultyArray] = useState([]);
  const [departmentArray, setDepartmentArray] = useState([]);
  const [majorArray, setMajorArray] = useState([]);
  const [courseIdArray, setCourseIdArray] = useState([]);

  const [majorId, setMajorId] = useState(!parsed?.major ? "" : parsed?.major);
  const [dataMajors, setDataMajors] = useState([]);
  const [classroomId, setClassroomId] = useState(!parsed?.classroom ? "" : parsed?.classroom);
  const [dataClassrooms, setDataClassrooms] = useState([]);

  const [gradesData, setGradesData] = useState([]);
  const [totals, setTotals] = useState(0);
  const [selectedYear, setSelectedYear] = useState(!parsed?.selectedYear ? "" : parsed?.selectedYear);
  // const [selectAccessMent, setSelectAccessMent] = useState(!parsed?.selectAccessMent ? "" : parsed?.selectAccessMent);
  const [selectSemester, setSelectSemester] = useState(!parsed?.selectSemester ? "" : parsed?.selectSemester);
  const [selectCourse, setSelectCourse] = useState(!parsed?.selectCourse ? "" : parsed?.selectCourse);
  const [studentName, setStudentName] = useState();
  const [countPage, setCountPage] = useState();

  const [showEdit, setShowEdit] = useState(false);
  const [editData, setEditData] = useState();
  const [callAccessMent, setCallAccessMent] = useState();

  // use ----------------------------------------------------------------->
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem(USER_KEY));
    setUserData(user?.data);
    setRole(user?.data?.role);
    setTeacherId(user?.data?.id);
    setTeacherName(user?.data?.firstname);

    let today = new Date();
    let y1 = today.getFullYear() - 1;
    let y2 = today.getFullYear() + 1;
    var accessment = [];
    for (var i = y2; i >= 2000; i--) {
      accessment.push(i);
    }
    setCallAccessMent(accessment);

  }, []);

  // Query faculties
  const [loadFaculties,{ data: facultyData }] = useLazyQuery(FACULTY_NAME);
  const [loadDataDepartment, { data: departmentsData }] = useLazyQuery(QUERY_DEPARTMENT, { fetchPolicy: "network-only" });
  const [loadDataMajor, { data: majorData }] = useLazyQuery(QUERY_MAJORS, { fetchPolicy: "network-only" });
  const [loadClassRooms, { data: classroomData }] = useLazyQuery(CLASSROOM_MASTERS, { fetchPolicy: "network-only" });
  // query grade
  const [
    loadGrades,
    { called: gradeCalled, loading: gradeLoading, data: gradeDataApollo },
  ] = useLazyQuery(GRADES, { fetchPolicy: "cache-and-network" });

  // query course
  const [loadCoursesFacultyDepartment, { data: coursesData }] = useLazyQuery(COURSES_FACULTY_DEPARTMENT_FOR_TEACHER);
  const [loadCourseByTeacher, { data: coursesByTeacherData }] = useLazyQuery(COURSES, { fetchPolicy: "cache-and-network" });

  useEffect(() => {
    loadFaculties({ variables: { where: { isDeleted: false } } });
    loadCoursesFacultyDepartment({ variables: { where: { teacher: teacherId, } } });
  }, [teacherId]);

  useEffect(() => {

    if (coursesData) {
      var arrayFaculty = []
      var arrayDepartment = []
      var arrayMajor = []
      var courseIds = []
      for (var i = 0; i < coursesData?.courseAndFacultyAndDepartmentAndMajorFromTeacher?.dataCourse?.length; i++) {
        arrayFaculty.push({ id: coursesData?.courseAndFacultyAndDepartmentAndMajorFromTeacher?.dataFaculty[i]?.id })
        courseIds.push({ id: coursesData?.courseAndFacultyAndDepartmentAndMajorFromTeacher?.dataCourse[i]?.id })
      }
      for (var i = 0; i < coursesData?.courseAndFacultyAndDepartmentAndMajorFromTeacher?.dataDepartment?.length; i++) {
        arrayDepartment.push({ id: coursesData?.courseAndFacultyAndDepartmentAndMajorFromTeacher?.dataDepartment[i]?.id })
      }
      for (var i = 0; i < coursesData?.courseAndFacultyAndDepartmentAndMajorFromTeacher?.dataDepartment?.length; i++) {
        arrayDepartment.push({ id: coursesData?.courseAndFacultyAndDepartmentAndMajorFromTeacher?.dataDepartment[i]?.id })
      }
      for (var i = 0; i < coursesData?.courseAndFacultyAndDepartmentAndMajorFromTeacher?.dataMajor?.length; i++) {
        arrayMajor.push({ id: coursesData?.courseAndFacultyAndDepartmentAndMajorFromTeacher?.dataMajor[i]?.id })
      }
      setFacultyArray(arrayFaculty)
      setDepartmentArray(arrayDepartment)
      setMajorArray(arrayMajor)
      setCourseIdArray(courseIds)
    }
  }, [coursesData]);
  useEffect(() => {
    if (gradeDataApollo) {
      setGradesData(gradeDataApollo?.grades?.data);
      setTotals(gradeDataApollo?.grades?.total)
    }
  }, [gradeDataApollo]);
  useEffect(() => {
    if(majorId) {
      let _where = { major: majorId }
      if(selectedYear !== "") _where = { ..._where, year: selectedYear }
      loadClassRooms({
        variables: {
          where: _where
        }
      })
    }
  }, [majorId, selectedYear])
  useEffect(() => {
    let allWhere = {
      numberTest_not_in: 1,
      // course_in: courseIdArray,
      sendingGradeStatus: "ADMIN_APPROVED"
    }
    if (studentName)
      allWhere = {
        ...allWhere,
        student: { student: studentName },
      };
    if (teacherId) allWhere = { ...allWhere, teacher: teacherId };
    if (selectedYear) allWhere = { ...allWhere, yearLevel: parseInt(selectedYear) };
    if (selectSemester) allWhere = { ...allWhere, semester: parseInt(selectSemester) };
    if (accessmentRedux) allWhere = { ...allWhere, assessmentYear: accessmentRedux };
    if (factId) allWhere = { ...allWhere, faculty: factId };
    if (departmentId) allWhere = { ...allWhere, department: departmentId };
    if (majorId) allWhere = { ...allWhere, major: majorId };
    if (classroomId) allWhere = { ...allWhere, classRoom: classroomId };
    if (selectCourse) allWhere = {...allWhere, course: selectCourse};
    loadGrades({
      variables: {
        where: allWhere,
        skip: (_skip - 1) * _limit,
        first: _limit,
      },
    });
  }, [
    factId,
    departmentId,
    selectedYear,
    selectSemester,
    accessmentRedux,
    selectCourse,
    teacherId,
    studentName,
    courseIdArray,
    majorId,
    classroomId
  ]);
  useEffect(() => {
    if (facultyData) {
      setDataFaculties(facultyData?.faculties)
    }
  }, [facultyData]);

  useEffect(() => {
    if (departmentsData) {
      setDataDepartments(departmentsData?.departments)
    }
  }, [departmentsData]);

  useEffect(() => {
    if (majorData) setDataMajors(majorData?.majors?.data)
  }, [majorData]);
  
  useEffect(() => {
    if (classroomData) setDataClassrooms(classroomData?.classRooms?.data)
  }, [classroomData]);

  useEffect(() => {
    if (coursesByTeacherData) {
      setDataCourses(coursesByTeacherData?.courses?.data)
    }
  }, [coursesByTeacherData]);

  // select faculty ad get value
  const _selectFaculty = async (e) => {
    let _facultyId = e?.target?.value
    setFactId(e.target.value);
    if (_facultyId === "") {
      setDepartmentId("")
      setDataDepartments([])
      setMajorId("")
      setDataMajors([])
      setClassroomId("")
      setDataClassrooms([])
      setSelectCourse("")
      setDataCourses([])
    }
    else {
      setDataDepartments([]);
      await loadDataDepartment({ variables: { where: { faculty:_facultyId } } })
    }
  };
  const _showTheFacultyAccordingToTheTeacher = (id) => {
    const _showThe = facultyArray.filter(fac => fac?.id === id)
    if (_showThe.length > 0) return false
    else return true
  }
  // select department
  const _onSelectedDepartment = async (e) => {
    let _departmentId = e?.target?.value
    setDepartmentId(_departmentId);
    if (_departmentId === "") {
      setMajorId("")
      setDataMajors([])
      setClassroomId("")
      setDataClassrooms([])
      setSelectCourse("")
      setDataCourses([])
    }
    else {
      loadDataMajor({
        variables: {
          where: { isDeleted: false, department: _departmentId }
        }
      })
    }
  };
  const _onSelectedMajor =async (e) => {
    setMajorId(e?.target?.value);
    if(e?.target?.value === "") {
      setClassroomId("")
      setDataClassrooms([])
      setSelectCourse("")
      setDataCourses([])
    }
  };
  const _onSelectedClassroom = (e) => {
    setClassroomId(e.target.value);
    if(e.target.value === "") {
      setSelectCourse("")
      setDataCourses([])
    }
  };
  const _showTheDepartmentAccordingToTheTeacher = (id) => {
    const _showTheDepartment = departmentArray.filter(dep => dep?.id === id)
    if (_showTheDepartment.length > 0) return false
    else return true
  }
  const _showTheMajorAccordingToTheTeacher = (id) => {
    const _showTheMajor = majorArray.filter(major => major?.id === id)
    if (_showTheMajor.length > 0) return false
    else return true
  }
  // select yaer
  const _onSelectedYear = (e) => {
    setSelectedYear(e.target.value);
    if(e.target.value === "") {
      setClassroomId("")
      setDataClassrooms([])
      setSelectCourse("")
      setDataCourses([])
    }
  };
  // select unit
  const _onSelectedSemester = (e) => {
    setSelectSemester(e.target.value);
    if(e.target.value === "") {
      setSelectCourse("")
      setDataCourses([])
    }
  };
  const _onSelectedCourse = (e) => {
    setSelectCourse(e.target.value);
  };
  const _showTheCourseAccordingToTheTeacher = (id) => {
    const _showTheCourses = courseIdArray.filter(cou => cou?.id === id)
    if (_showTheCourses.length > 0) return false
    else return true
  }
  // select Teacher
  const _onSelectedTeacher = (e) => {
    setTeacherId(e.target.value);
  };

  // search data
  const _onKeyDown = (e) => {
    if(e.key === "Enter") setStudentName(e.target.value);
  };

  // pagegination

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item>ການເບິ່ງຄະແນນ</Breadcrumb.Item>
        <Breadcrumb.Item active style={{ fontWeight: "bolder" }}>
          ຄະແນນແກ້ເກຣດ
        </Breadcrumb.Item>
      </Breadcrumb>
      <div
        style={{
          display: "flex",
          // flexDirection: "row",
          // justifyContent:"between",
        }}
      >
        <div
          className="menuItemTap not-active-menuTap"
          onClick={() =>
            history.push(Routers.GRADE_LIST + Routers.PAGE_GINATION)
          }
        >
          ຄະແນນ
        </div>
        <div
          className="menuItemTap active-menuTap"
          onClick={() =>
            history.push(Routers.UPGRADE_LIST + Routers.PAGE_GINATION)
          }
        >
          ຄະແນນແກ້ເກຣດ
        </div>
        <div
          className="menuItemTap not-active-menuTap"
          onClick={() => history.push(Routers.STUDENT_FOR_GRADE)}
        >
          ນັກສຶກສາ
        </div>
      </div>
      <CustomContainer style={{ marginTop: 0 }}>
        <div style={{ display: "block", marginTop: 20 }}>
          <span
            style={{
              display: "inline-block",
              fontSize: 24,
              marginTop: 20,
              color: "#057CAE",
              fontWeight: "bold",
            }}
          >
            ຄະແນນທັງໝົດ
          </span>
        </div>
        <div
          style={{
            marginTop: 24,
            marginBottom: 8,
            fontSize: 16,
            color: Consts.FONT_COLOR_SECONDARY,
          }}
        >
          <Row className="mt-3">
            <Col sm={3}>
              <Form.Group>
                <Form.Label>ຄະນະ</Form.Label>
                <Form.Control
                  as="select"
                  onChange={(e) => _selectFaculty(e)}
                >
                  <option value="">ທັງໝົດ</option>
                  {dataFaculties?.map((fac, facIndex) => {
                    return (
                      <option disabled={_showTheFacultyAccordingToTheTeacher(fac?.id)} key={facIndex} value={fac?.id}>{fac?.name}</option>
                    )
                  })}
                </Form.Control>
              </Form.Group>

            </Col>
            <Col sm={3}>
              <Form.Group>
                <Form.Label>ພາກວິຊາ</Form.Label>
                <Form.Control
                  as="select"
                  onChange={(e) => _onSelectedDepartment(e)}
                >
                   <option value="">ທັງໝົດ</option>
                  {dataDepartments?.map((dep, depIndex) => {
                    return (
                      <option disabled={_showTheDepartmentAccordingToTheTeacher(dep?.id)} key={depIndex} value={dep?.id}>{dep?.name}</option>
                    )
                  })}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col sm={3}>
              <Form.Group>
                <Form.Label>ສາຂາວິຊາ</Form.Label>
                <Form.Control
                  as="select"
                  onChange={(e) => _onSelectedMajor(e)}
                >
                   <option value="">ທັງໝົດ</option>
                  {dataMajors?.map((major, majorIndex) => {
                    return (
                      <option disabled={_showTheMajorAccordingToTheTeacher(major?.id)} key={majorIndex} value={major?.id}>{major?.name}</option>
                    )
                  })}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col sm={3}>
              <Form.Group>
                <Form.Label>ປີ</Form.Label>
                <Form.Control as="select" onChange={(e) => _onSelectedYear(e)}>
                  <option value="">ທັງໝົດ</option>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                  <option value={6}>6</option>
                </Form.Control>
              </Form.Group>

            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              <Form.Group>
                <Form.Label>ພາກຮຽນ</Form.Label>
                <Form.Control
                  as="select"
                  onChange={(e) => _onSelectedSemester(e)}
                >
                  <option value="">ທັງໝົດ</option>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col sm={3}>
              <Form.Group>
                <Form.Label>ຫ້ອງນັກສຶກສາ</Form.Label>
                <Form.Control as="select" onChange={(e) => _onSelectedClassroom(e)}>
                  <option value="">ທັງໝົດ</option>
                  {dataClassrooms?.map((classroom, index) => {
                    return (<option key={index} value={classroom?.id}>{classroom?.name}</option>)
                  })}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col sm={3}>
              <Form.Group>
                <Form.Label>ວິຊາ</Form.Label>
                <Form.Control as="select" onChange={(e) => _onSelectedCourse(e)}>
                  <option value="">ທັງໝົດ</option>
                  {dataCourses?.map((cou, couIndex) => {
                    return (<option disabled={_showTheCourseAccordingToTheTeacher(cou?.id)} key={couIndex} value={cou?.id}>{cou?.title}</option>)
                  })}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col sm={3}>
              <Form.Label>ລະຫັດນັກສຶກສາ&nbsp;
                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">ປ້ອນລະຫັດນັກສຶກສາ Enter ເພື່ອຄົ້ນຫາ</Tooltip>}>
                  <FontAwesomeIcon icon={faInfoCircle} color={Consts.PRIMARY_COLOR} />
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                type="text"
                className="form-control box-search"
                placeholder="ປ້ອນລະຫັດນັກສຶກສາ"
                onKeyDown={(e) => _onKeyDown(e)}
              />
            </Col>
          </Row>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 10,
            }}
          >
            ທັງຫມົດ {totals} ຄົນ
            
          </div>
        </div>
        <div>
          <div className="table-responsive">
            <table className="table tabl-hover">
              <thead>
                <TableHeader>
                  <th style={{ width: 70 }}>ລຳດັບ</th>
                  <th>ລະຫັດນັກສຶກສາ</th>
                  <th>ຊື່ ແລະ ນາມສະກຸນ</th>
                  <th>ຄະແນນ</th>
                  <th>ເກຣດ</th>
                  <th>ວິຊາ</th>
                  <th>ໃຫ້ຄະແນນໂດຍ</th>
                  <th>ເວລາອັບເດດ</th>
                  {/* <th style={{ width: 180 }}>ແກ້ໄຂ</th> */}
                </TableHeader>
              </thead>
              {gradeLoading ? (
                <Loading />
              ) : (
                gradesData?.map((grade, index) => {
                  return (
                    <tbody key={index}>

                      <tr
                        style={{
                          borderBottom: "2px solid #ffff",
                          textAlign: "center",
                        }}

                      >
                        <td>
                          {index + 1}
                        </td>
                        <td>{grade?.student?.studentNumber}</td>
                        <td style={{ textAlign: "left" }}>
                          {/* {setGenders(grade?.student?.gender)}{" "}
                          {grade?.student?.firstNameL}&nbsp;{" "}
                          {grade?.student?.lastNameL} */}
                          {!grade?.student?.firstNameL || grade?.student?.firstNameL === "" ? 
                            setGenders(grade?.student?.gender) + " " + grade?.student?.firstNameE + " " + grade?.student?.lastNameE
                            :
                            setGenders(grade?.student?.gender) + " " + grade?.student?.firstNameL + " " + grade?.student?.lastNameL
                          }
                        </td>
                        {/* <td>{grade?.numericGrade}</td> */}
                        <td>{grade?.numbericGrade}</td>
                        <td>{grade?.letterGrade}</td>
                        <td>{grade?.course?.title}</td>
                        <td>
                          {grade?.updatedBy}
                        </td>
                        <td>{formatDateWithoutTime(grade?.createdAt)}</td>
                        {/* <td>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-around",
                          }}
                        >
                          {(grade?.sendingGradeStatus === "TEACHER_SCORING" || grade?.sendingGradeStatus === "TEACHER_REJECTED") ? 
                          <div
                            onClick={() => {
                              setShowEdit(!showEdit);
                              setEditData(grade);
                            }}
                            style={{
                              cursor: "pointer",
                              backgroundColor: "#FFFFFF",
                              padding: 3,
                              width: 64,
                              borderRadius: 4,
                            }}
                          >
                            <FontAwesomeIcon
                              icon={["fas", "edit"]}
                              color={Consts.BORDER_COLOR}
                            />{" "}
                          </div> : <p style={{ color: "green" }}><b>ສົ່ງຄະແນນແລ້ວ</b></p>}
                        </div>
                      </td> */}
                      </tr>

                      {/* })} */}
                    </tbody>
                  );
                })
              )}
            </table>
            {Pagination_helper(totals, Routers.UPGRADE_LIST,
              `factId=${factId}&&departmentId=${departmentId}&&selectedYear=${selectedYear}&&selectedYear=${selectedYear}&&selectSemester=${selectSemester}&&selectCourse=${selectCourse}`
            )}
          </div>
          {/* )} */}
        </div>
      </CustomContainer>
      <EditGrade
        show={showEdit}
        hide={() => setShowEdit(!showEdit)}
        data={editData}
      />

      {/* search madal */}
      <div className="modal fade" id="editData" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <strong>ຄົ້ນຫາ</strong>
              <button
                className="btn right btn-sm close-icon"
                data-dismiss="modal"
              >
                <i className="fa fa-times"></i>
              </button>
            </div>
            <div className="modal-body">
              <label>ຊື່ຊົນເຜົ່າ {valiDate()}</label>
              <input
                type="text"
                className="form-control"
                name="tribeName"
                placeholder="ກະລຸນາປ້ອນຊື່ຊົນເຜົ່າ"
              />
              <label>ໝາຍເຫດ</label>
              <textarea
                className="form-control"
                name="node"
                placeholder="ກະລຸນາປ້ອນໝາຍເຫດ(ຖ້າມີ)"
              />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-info">
                ຄົ້ນຫາ
              </button>
              <button
                type="button"
                className="btn btn-control"
                data-dismiss="modal"
              >
                ຍົກເລີກ
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpgradesList;
