import { gql } from "apollo-boost";

export const COURSES = gql`
  query Courses(
    $where: CustomCourseWhereInput
    $orderBy: CourseOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    courses(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      data{
      id
      courseCode
      moodleCourseId
      assessmentYear
      title
      description
      teacher{
        id
        userId
        firstname
        lastname
      }
      # faculty{
      #       id
      #       name
      #     }
      #     department{
      #       id
      #       name
      #     }
      #     teacher {
      #       firstname
      #       lastname
      #     }
      dayTimeIndexes{
        day
        timeIndexes
      }
      year
      unit
      semester
      }
    }
  }
`;
export const COURSES_FACULTY_DEPARTMENT_FOR_TEACHER = gql`
  query CourseAndFacultyAndDepartmentAndMajorFromTeacher(
    $where: CourseAndFacultyAndDepartmentFromTeacherInput!
  ) {
    courseAndFacultyAndDepartmentAndMajorFromTeacher(
      where: $where
    ){
      dataCourse{
      id
      week
      title
      id
      courseCode
      assessmentYear
      title
      description
      # teacher{
      #   id
      #   userId
      #   firstname
      #   lastname
      # }
      dayTimeIndexes{
        day
        timeIndexes
      }
      year
      unit
      semester
      addressLearn
    }
    dataFaculty{
      id
      name
      # faculty{
      #   id
      #   name
      # }
    }
    dataDepartment{
      id
      name
      # faculty{
      #   id
      #   name
      # }
      # department{
      #   id
      #   name
      # }
    }
    dataMajor {
      id
      name
    }
  }
  }
`;
export const COURSES_FACULTY_DEPARTMENT = gql`
  query CourseAndFacultyAndDepartmentAndMajor(
    $where: CourseWhereUniqueInput!
  ) {
    courseAndFacultyAndDepartmentAndMajor(
      where: $where
    ){
      dataCourse{
        id
        courseCode
        title
        syllabusFile {
          id
          file
          title
        }
        files {
          id
          description
          title
          file
          createdAt
        }
        week
        addressLearn
        dayTimeIndexes {
          day
          timeIndexes
        }
        teacher {
          id
          userId
          firstname
          lastname
        }
        gradeType{
          name
          detail
          score
          maxScore
        }
        moodleCourseId
        assessmentYear
        year
        semester
        unit
        description
        note
        createdAt
        updatedAt
      
      teacher{
        id
        firstname
        lastname
      }
    }
    dataDepartment{
        id
        department{
          id
          name
        }
        faculty{
          id
          name
        }
    }
    dataFaculty{
        id
        faculty{
          id
          name
        }
    }
    dataMajor{
      id
      faculty{
        id
        name
      }
      department{
        id
        name
      }
      major{
        id
        name
      }
    }
    dataClassroom{
      id
      faculty{
        id
        name
      }
      department{
        id
        name
      }
      major{
        id
        name
      }
      classRoom{
        id
        name
      }
    }
  
  }
  }
`;

export const COURSE = gql`
  query Course($where: CourseWhereUniqueInput!) {
    course(where: $where) {
      id
      courseCode
      title
      department {
        id
        name
      }
      teacher{
        userId
        firstname
        lastname
      }
      dayTimeIndexes{
        day
        timeIndexes
      }
      faculty {
        id
        name
      }
      syllabusFile {
        id
        file
        title
        createdAt
        updatedAt
      }
      files {
        id
        description
        title
        file
        updatedAt
      }
      unit
      year
      semester
      description
      updatedAt
    }
  }
`;
export const CLASS_ROOM_COURSES = gql`
  query ClassRoomCourses($where: ClassroomAndCourseInput) {
    classRoomAndCourses(where: $where) {
      data{
      		classRoom{
            id
            name
          }
      }
      }
  }
`;
export const COURSE_DOC = gql`
  query Course($where: CourseWhereUniqueInput!) {
    course(where: $where) {
      id
      courseCode
      title
     
      files {
        id
        description
        title
        file
        updatedAt
      }
      unit
    }
  }
`;
export const CALL_COURSE_DATA = gql`
  query Course(
    $where: CustomCourseWhereInput
    $orderBy: CourseOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ){
    courses(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ){
      data{
      id
      title
      detail
      gradeType {
        name
        score
        maxScore
      }
    }
    }
  }
`;

export const COURSE_EDIT = gql`
  query CourseEdit(
    $where: CourseWhereUniqueInput!
  ) {
    course(where: $where) {
      id
      courseCode
      syllabusFile {
        id
        file
        title
      }
      description
    }
  }
`;

export const CREATE_COURSE = gql`
  mutation CreateCourse($data: CourseCreateInput!) {
    createCourse(data: $data) {
      id
    }
  }
`;

export const UPDATE_COURSE = gql`
  mutation UpdateCourse(
    $data: CustomCourseCreateInput!
    $where: CourseWhereUniqueInput!
  ) {
    updateCourse(data: $data, where: $where) {
      id
    }
  }
`;

export const PRE_SIGNED_URL = gql`
  query PreSignedUrl($mimeType: String, $fileName: String) {
    preSignedUrl(mimeType: $mimeType, fileName: $fileName) {
      url
    }
  }
`;

export const DELETE_COURSE = gql`
  mutation DeleteCourse($where: CourseWhereUniqueInput!) {
    deleteCourse(where: $where) {
      id
    }
  }
`;

export const DELETE_FILE = gql`
  mutation deleteFile($where: FileWhereUniqueInput!) {
    deleteFile(where: $where) {
      id
    }
  }
`;
