import styled from "styled-components";
import Consts from "../consts";

export const TableHeader = styled.tr`
  background-color: ${Consts.PRIMARY_COLOR};
  color: #fff;
  text-align: center;
  height: 40px;
`;

export const TableCell = styled.td`
  background-color: #f1f1f1;
  height: 40px;
  margin-top: 10px;
  // padding-left: 10px;
  // padding-right:10px
  color: ${Consts.FONT_COLOR_SECONDARY};
`;
