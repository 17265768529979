import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useReactRouter from "use-react-router";
import Consts, { MOODLE_URL } from "../../consts/index";
import { CLASS_ROOM_COURSES, COURSES_FACULTY_DEPARTMENT } from "../../apollo/course";
import { useLazyQuery } from "@apollo/react-hooks";
import { USER_KEY } from "../../consts/user";
import { convertWeek } from "../../common/super";
function CustomPopover({ course }) {
  const { history } = useReactRouter();
  const [teacherId,setTeacherId] = useState();
  const [dataDepartment,setDataDepartment] = useState([]);
  const [loadClassRoomAndCourses, { data: classRoomAndCoursesData }] = useLazyQuery(CLASS_ROOM_COURSES, { fetchPolicy: "network-only" });
  const [loadCoursesFacultyDepartment, { data: CoursesFacultyDepartmentData }] = useLazyQuery(COURSES_FACULTY_DEPARTMENT, { fetchPolicy: "network-only" });
  // useEffect(() => {
  //   const user = JSON.parse(localStorage.getItem(USER_KEY));
  //   if (user) {setTeacherId(user?.data?.id) }
  //   // loadClassRoomAndCourses({
  //   //   variables: {
  //   //     where:{
  //   //       course: course?.id
  //   //     }
  //   //   }
  //   // });
  // },[]);
  useEffect(() => {
    loadCoursesFacultyDepartment({
      variables: {
        where: {
          id: course?.id
        }
      }
    })
  },[])
  useEffect(() => {
    if(CoursesFacultyDepartmentData){
      setDataDepartment(CoursesFacultyDepartmentData?.courseAndFacultyAndDepartmentAndMajor?.dataDepartment)
    }
  },[CoursesFacultyDepartmentData])
  const _onCourseDetail = (id) => {
    history.push(`/course-detail/id/${id}`);
  };
  const _onDocumentFile = (id) => {
    history.push(`/course-doc-list/${id}`);
  };
  return (
    <OverlayTrigger
      trigger="click"
      placement="right"
      overlay={
        <Popover id="popover-basic">
          <div
            style={{
              padding: "15px 20px 0px 20px",
              cursor: "pointer",
            }}
            onClick={() => _onCourseDetail(course && course.id)}
          >
            <FontAwesomeIcon
              icon={["fa", "list"]}
              style={{
                color: Consts.PRIMARY_COLOR,
              }}
            />{" "}
            ລາຍລະອຽດວິຊາ
          </div>
          <hr />
          <div
            style={{
              padding: "0px 20px 0px 20px",
              cursor: "pointer",
            }}
            onClick={() => _onDocumentFile(course && course.id)}
          >
            <FontAwesomeIcon
              icon={["fa", "folder"]}
              style={{
                color: "#057CAE",
              }}
            />{" "}
            ເອກະສານບົດສອນ
          </div>
          {/* <hr />
          <div
            style={{
              padding: "0px 20px 15px 20px",
              cursor: "pointer",
            }}
          >
            <a
              href={`${MOODLE_URL}/user/index.php?id=${course?.moodleCourseId}`}
              target="_blank"
            >
              <img
                style={{ width: 23, height: 23 }}
                src="/assets/moodleyes.png"
              />{" "}
              ເຂົ້າສູ່ຫ້ອງຮຽນ
            </a>
          </div> */}
        </Popover>
      }
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "#057CAE",
          // textAlign:"center"
        }}
      >
        <OverlayTrigger
          overlay={
            <Tooltip id="tooltip-disabled">
              {course && course.title ? course.title : ""}
            </Tooltip>
          }
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
              color: "#fff",
              position: "relative",
              margin: "auto",
              height: "100%",
            }}
          >
            <p
              style={{
                width: 100,
                overflowX: "hidden",
                overflowY: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              <FontAwesomeIcon
                icon={["fa", "circle"]}
                style={{ fontSize: 10, marginRight: 10 }}
              />
              {course && course?.title ? course?.title : ""}<br></br>
              {/* <span style={{fontSize: 12}}>
                {"ສອນລວມ "+dataDepartment?.length+" ພາກວິຊາ"}
              </span><br /> */}
              <span style={{fontSize: 14}}>
                <b>{course && course?.week ? convertWeek(course?.week) : ""}</b>
              </span>
            </p>
          </div>
        </OverlayTrigger>
      </div>
    </OverlayTrigger>
  );
}

CustomPopover.propTypes = {
  course: PropTypes.object.isRequired,
};

export default CustomPopover;
