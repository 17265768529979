import { gql } from "apollo-boost";

export const LOGIN_USER = gql`
  mutation LoginUser($data: loginUserInput!) {
    loginUser(data: $data) {
      data {
        id
        userId
        firstname
        lastname
        role
        faculty {
          id
          name
        }
        department {
          id
          name
        }
      }
      accessToken
    }
  }
`;

export const CREATE_USER = gql`
  mutation CreateUser($data: UserCreateInput!) {
    createUser(data: $data) {
      id
      firstname
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($data: UserUpdateInput!, $where: UserWhereUniqueInput!) {
    updateUser(data: $data, where: $where) {
      id
      firstname
    }
  }
`;

export const UPDATE_USER_PASSWORD = gql`
  mutation UpdateUserPassword(
    $data: UserUpdatePassword!
    $where: CustomUserWhereUniqueInput!
  ) {
    updateUserPassword(data: $data, where: $where) {
      id
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($where: UserWhereUniqueInput!) {
    deleteUser(where: $where) {
      id
    }
  }
`;

export const USER = gql`
  query User($where: UserWhereUniqueInput!) {
    user(where: $where) {
      id
      userId
      firstname
      lastname
      userId
      email
      role
      description
      note
      phone
      createdAt
      updatedAt
    }
  }
`;

export const USERS = gql`
  query User(
    $where: UserWhereInput
    $orderBy: UserOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    users(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      id
      userId
      firstname
      lastname
      userId
      password
      email
      phones
      role
      description
      note
      createdAt
      updatedAt
    }
  }
`;

export const TEACHERS = gql`
  query TEACHERS(
    $where: UserWhereInput
    $orderBy: UserOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    users(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      id
      userId
      firstname
      lastname
      userId
      password
      email
      phones
      role
      description
      note
      createdAt
      updatedAt
    }
  }
`;
export const TEACHERS_BOX = gql`
  query Users_page {
    user_page {
      data {
        id
        firstname
        lastname
      }
      total
    }
  }
`;
