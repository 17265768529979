import React, { useState, useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { useMutation} from "@apollo/react-hooks";

import {APPROVE_GRADE} from '../../apollo/grade'

const RegisterModal = ({ isShowUpgrade, _handleUpgradeClose,data }) => {

  const MARGINTOP = 10

  const [approveGrade, { data:approveData }] = useMutation(
    APPROVE_GRADE
  );


  return (
    <Modal show={isShowUpgrade} onHide={_handleUpgradeClose} size="md">
      <Modal.Header>
        <Modal.Title style={{ paddingTop: 20, paddingLeft: 20 }}>
          ຢືນ​ຢັນ​ການ​ລົງ​ທະ​ບຽນ
      </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ marginLeft: 50, marginRight: 50, padding: 50 }}>
        <Row style={{ backgroundColor: "#F2F2F2", padding: 5, borderRadius: 5, display: 'flex', alignItems: 'center' }}>
          <a style={{ marginTop: 5 }}>ຂໍ້ມູນທົ່ວໄປ</a>
        </Row>
        <Row style={{ marginTop: MARGINTOP }}>
          <Col >ລະ​ຫັດ​ນັກ​ສຶກ​ສາ: </Col>
          <Col>{data?.student?.userId}</Col>
        </Row>
        <Row style={{ marginTop: MARGINTOP }}>
          <Col >ຊື່: </Col>
          <Col>{data?.student?.firstNameL}</Col>
        </Row>
        <Row style={{ marginTop: MARGINTOP }}>
          <Col >ນາມ​ສະ​ກຸນ: </Col>
          <Col>{data?.student?.lastNameL}</Col>
        </Row>
        <Row style={{ marginTop: MARGINTOP }}>
          <Col >ນັກ​ສຶກ​ສາ​ປີ: </Col>
          <Col>{data?.student?.yearLevel}</Col>
        </Row>
        <Row style={{ marginTop: MARGINTOP }}>
          <Col >ພາກ​ຮຽນ: </Col>
          <Col>{data?.semester}</Col>
        </Row>
        <Row style={{ backgroundColor: "#F2F2F2", padding: 5, borderRadius: 5, display: 'flex', alignItems: 'center' }}>
          <a style={{ marginTop: 5 }}>ວິຊາ</a>
        </Row>
        <Row style={{ marginTop: MARGINTOP }}>
          <Col >ວິຊາທີ່ລົງທະບຽນ: </Col>
          <Col>{data?.course?.title}</Col>
        </Row>
        <Row style={{ marginTop: MARGINTOP }}>
          <Col >​ສົກ​ຮຽນ: </Col>
          <Col>​{data?.student?.assessmentYear}</Col>
        </Row>
      </Modal.Body>
      {/* <Modal.Footer> */}
        <div style={{ display: 'flex', justifyContent: 'flex-end',marginBottom:20,marginRight:20}}>
        <button
            onClick={_handleUpgradeClose}
            style={{
              width: 100,
              height: 30,
              borderRadius: 3,
              border: "1px solid #137DAE",
              // outline: "none",
              backgroundColor: "white",
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            ຍົກເລີກ
          </button>
          &nbsp;
          <button
            onClick={async()=>{
              await approveGrade({variables:{where:{id:data?.id}}})
              window.location.reload()
            }}
            style={{
              width: 100,
              height: 30,
              borderRadius: 3,
              border: "1px solid #137DAE",
              // outline: "none",
              backgroundColor: "#0D76B5",
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color:'white'

            }}
          >
            ລົງທະບຽນ
          </button>
        </div>
      {/* </Modal.Footer> */}
    </Modal>
  );
};

export default RegisterModal;
