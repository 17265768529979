import React, { useState, useEffect } from "react";
import { Modal, CloseButton, Row, Col, Button, Form } from "react-bootstrap";
import "./util/index.css";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import { UPDATE_GRADE } from "../../apollo/grade/index";
import { GRADES } from "../register/apollo/query";
import {
  convertLetterGrade,
  alertSuccess,
  alertWarning,
} from "../../common/super";
import PaginationHelper from "../../helpers/PaginationHelper";

function EditGrade({ show, hide, data, _where, setGradesData, setTotals }) {
  const { _limit, _skip } = PaginationHelper();
  const [newData, setNewData] = useState();
  const [gradeType, setGradeType] = useState([]);

  const [updateGrade] = useMutation(UPDATE_GRADE);

  const [loadGrades, { data: gradeDataApollo }] = useLazyQuery(GRADES, { fetchPolicy: "network-only" });

  useEffect(() => {
    setNewData(data);
    if (data?.gradeType.length > 0) setGradeType(data?.gradeType);
  }, [data]);

  useEffect(() => {
    if (gradeDataApollo) {
      setGradesData(gradeDataApollo?.grades?.data);
      setTotals(gradeDataApollo?.grades?.total)
    }
  }, [gradeDataApollo]);

  const _saveOneGrade = async (grade) => {
    let newGradeType = gradeType?.map((item) => {
      let data = {
        name: item?.name,
        score: item?.score,
        maxScore: item?.maxScore,
      };
      return data;
    });
    await updateGrade({
      variables: {
        data: {
          gredeStatus: "APROVED",
          gradeType: newGradeType
        },
        where: { id: newData?.id },
      },
    })
      .then(async () => {
        alertSuccess("ເກຣດ");
        hide()
    
        loadGrades({
          variables: {
            where: _where,
            skip: (_skip - 1) * _limit,
            first: _limit,
          },
        });    
      })
      .catch((err) => {
        alertWarning("ເກຣດ");
      });
  };
  const _totalGrade = (grades) => {
    let result = 0;
    grades.map((_type, _index) => {
      result += parseInt(_type?.score ?? 0);
    });
    return result;
  };
  return (
    <div>
      <Modal
        animation={false}
        size="lg"
        centered
        show={show}
        style={{ padding: 0 }}
        onHide={hide}
      >
        <Modal.Header style={{ padding: 10, border: 0 }}>
          <Modal.Title
            style={{
              textAlign: "center",
              width: "100%",
              padding: 0,
              marginTop: 20,
              fontWeight: "bolder",
            }}
          >
            ແກ້ໄຂຄະແນນ
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "0 100px 20px 100px",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "100%",
              padding: 20,
              fontWeight: "bolder",
            }}
          >
            <Row>
              <Col sm={4}>
                <p>ລະຫັດນັກສຶກສາ</p>
              </Col>
              <Col sm={4}>
                <p>{data?.student?.studentNumber}</p>
              </Col>
            </Row>
            <Row>
              <Col sm={4}>
                <p>ຊື່ ແລະ ນາມສະກຸນ</p>
              </Col>
              <Col sm={4}>
                <p>
                  {data?.student?.firstNameL + " "}
                  {data?.student?.lastNameL}
                </p>
              </Col>
            </Row>
            <Row>
              <Col sm={4}>
                <p>ລະຫັດວິຊາ</p>
              </Col>
              <Col sm={4}>
                <p>{data?.course?.courseCode}</p>
              </Col>
            </Row>
            <Row>
              <Col sm={4}>
                <p>ວິຊາ</p>
              </Col>
              <Col sm={4}>
                <p>{data?.course?.title}</p>
              </Col>
            </Row>
            {gradeType?.map((_type, _index) => {
              return (
                <Row key={_index}>
                  <Col sm={4}>{_type?.name}</Col>
                  <Col sm={8}>
                    <Form.Control
                      style={{
                        width: "100%",
                        height: 30,
                        marginTop: 10,
                        textAlign: "center",
                      }}
                      max={parseInt(_type?.maxScore)}
                      min={0}
                      type="number"
                      className="form-control"
                      name="gradeType"
                      defaultValue={data?.numbericGrade ? _type?.score : 0}
                      placeholder={"ສູງສຸດ " +_type?.maxScore}
                      onChange={async (e) => {
                        let _gradeType = [...gradeType];
                        _gradeType[_index].score = e?.target?.value
                          ? parseInt(e.target.value)
                          : parseInt(_type?.score);
                        setGradeType(_gradeType);
                      }}
                    />
                  </Col>
                </Row>
              );
            })}
            <Row>
              <Col sm={4}>
                <p>ຄະແນນ</p>
              </Col>
              <Col sm={8}>
                <Form.Control
                  style={{ width: "100%", height: 30, marginTop: 10 }}
                  className="form-control"
                  disabled
                  value={gradeType ? _totalGrade(gradeType) : _totalGrade([])}
                  name="numericGrade"
                />
              </Col>
            </Row>
            <Row>
              <Col sm={4}>ເກດ</Col>
              <Col sm={8}>
                <Form.Control
                  disabled
                  style={{ width: "100%", height: 30 }}
                  className="form-control"
                  name="letterGrade"
                  value={convertLetterGrade(_totalGrade(gradeType))}
                />
              </Col>
            </Row>
          </div>
          <div
            style={{
              marginTop: 20,
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            <Button
              onClick={hide}
              variant={"none"}
              className="btn btn-outline-secondary"
            >
              ຍົກເລີກ
            </Button>
            <Button
              onClick={() => _saveOneGrade()}
              type="submit"
              className="btn btn-info"
            >
              ບັນທຶກການແກ້ໄຂ
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default EditGrade;
