import React, { useState } from "react";
import useReactRouter from "use-react-router";
import Tabs from "react-bootstrap/Tabs";
import { Breadcrumb, Tab } from "react-bootstrap";
// import GradesList from "./GradesList";
// import StudentListForGrade from "./StudentListForGrade";
import GiveGradeForStudent from "./GiveGradeForStudentList";
import UpGradeForStudent from "./UpGradeforStudent";
export default function GradesAndUpGrade() {
  const { history, match } = useReactRouter();
  return (
    <div>
      {/* Breadcrumb */}
      <Breadcrumb>
        <Breadcrumb.Item>
          <a onClick={() => history.goBack()}>ການຈັດການຄະແນນ</a>
        </Breadcrumb.Item>
      </Breadcrumb>
      <Tabs
        defaultActiveKey="list-all"
        transition={false}
        id="noanim-tab-example"
        //   onSelect={(selected) => tab(selected)}
      >
        <Tab eventKey="list-all" title="ໃຫ້ຄະແນນ">
          <GiveGradeForStudent />
        </Tab>
        <Tab eventKey="student-list" title="ໃຫ້ຄະແນນແກ້ເກຣດ">
          <UpGradeForStudent />
        </Tab>
      </Tabs>
    </div>
  );
}
