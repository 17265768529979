import React, { useEffect, useState } from "react";
import axios from "axios";
import "./index.css";
import { USER_KEY } from "../../consts/user";
import useReactRouter from "use-react-router";
import { COURSE, COURSES_FACULTY_DEPARTMENT } from "../../apollo/course";
import { Col, Row } from "antd";
import {
  InfoOutlined,
  BookOutlined,
  ArrowUpOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { API_KEY, NEW_API_URL } from "../../common/constraint";
import { useSelector } from "react-redux";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { useQuery } from "@apollo/react-hooks";

const CourseList = () => {
  const user = localStorage.getItem(USER_KEY);
  const userObject = JSON.parse(user);
  const accessmentRedux = useSelector((state) => state.accessment.value);

  const [teacherId, setTeacherId] = useState("");

  useEffect(() => {
    if (userObject?.data) {
      setTeacherId(userObject?.data?.id);
    }
  }, [userObject]);

  const { history, match } = useReactRouter();

  const [dataTable, setDataTable] = useState({});

  const [courseDetail, setCourseDetail] = useState();
  const [showCourse, setShowCourse] = useState(false);

  const [courseData, setCourseData] = useState({});
  const [courseFaculties, setCourseFaculties] = useState([]);
  const [courseDepartment, setCourseDepartment] = useState([]);
  const [courseMajor, setCourseMajor] = useState([]);
  const [courseClassroom, setCourseClassroom] = useState([]);

  const { loading, error, data } = useQuery(COURSES_FACULTY_DEPARTMENT, {
    variables: { where: { id: courseDetail?.id || "" } },
  });

  useEffect(() => {
    if (data) {
      setCourseData(data?.courseAndFacultyAndDepartmentAndMajor?.dataCourse);
      setCourseFaculties(
        data?.courseAndFacultyAndDepartmentAndMajor?.dataFaculty
      );
      setCourseDepartment(
        data?.courseAndFacultyAndDepartmentAndMajor?.dataDepartment
      );
      setCourseMajor(data?.courseAndFacultyAndDepartmentAndMajor?.dataMajor);
      setCourseClassroom(
        data?.courseAndFacultyAndDepartmentAndMajor?.dataClassroom
      );
    }
  }, [data]);

  const [selectedYear, setSelectedYear] = useState(1); // State to store the selected option
  const [selectedSemester, setSelectedSemester] = useState(1); // State to store the selected option

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem(USER_KEY));
    if (user) {
      setTeacherId(user.data?.id);
    }
  }, []);

  const _onCourseDetail = (id) => {
    history.push(`/course-detail/id/${id}`);
  };

  const _giveGarde = () => {
    //  history.push(`/giveGradeForStudent/1`, { courseData, courseClassroom });
    history.push(`/giveGradeForStudentv2`, {
      courseData: courseDetail,
      courseClassroom,
    });
  };

  const _viewDoc = (id) => {
    history.push(`/course-doc-list/${id}`);
  };

  useEffect(() => {
    axios
      .get(
        NEW_API_URL +
          "teacher/" +
          teacherId +
          "/" +
          selectedYear +
          "/" +
          selectedSemester +
          "/" +
          accessmentRedux,
        {
          headers: {
            api_key: API_KEY,
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          const data = res.data.data;
          setDataTable(data);
        } else {
          setDataTable([]);
        }
      })
      .catch((err) => {
        setDataTable([]);
      });
  }, [selectedYear, selectedSemester, teacherId, accessmentRedux]);

  const Column = ({ data, day }) => {
    return (
      <div className="my-column-row p-0">
        {data.map((item, index) => {
          let week = "";
          const v = item?.timesStudy.map((time) => {
            if (day === time?.day) {
              if (time.oddWeek) {
                week = "odd";
              } else if (time.evenWeek) {
                week = "even";
              } else if (time.allWeek) {
                week = "all";
              }
              return time;
            }
          });

          return (
            <div
              key={index}
              onClick={() => {
                let times = "";
                item.timesStudy.forEach((d, index) => {
                  d.times.forEach((time, timeIndex) => {
                    if (d.day === day) {
                      times += time?.time;
                      if (
                        index !== item.timesStudy.length - 1 ||
                        timeIndex !== d.times.length - 1
                      ) {
                        times += " ແລະ ";
                      }
                    }
                  });
                });

                let w = "";
                if (week == "odd") w = "ອາທິດຄີກ";
                else if (week == "even") w = "ອາທິດຄີກ";
                else if (week === "all") w = "ທຸກອາທິດ";

                setCourseDetail({
                  assessmentYear: item.assessmentYear,
                  semester: selectedSemester,
                  year: selectedYear,
                  id: item.id,
                  title: item?.course?.course?.subject?.title,
                  description: item?.description,
                  week: w,
                  times: times,
                  facName: item?.faculty,
                  addressLearn: item?.addressLearn || "ບໍ່ມິຂໍ້ມູນ",
                  classRoom: item?.classRoom,
                  course: item?.course,
                });

                setShowCourse(true);
              }}
              className="mycenter"
            >
              <p className="mylabel">{item?.course?.course?.subject?.title}</p>
              <p className="mylabel">{"ຫ້ອງຮຽນ : " + item?.classRoom?.name}</p>
              {item.addressLearn && (
                <span className="mylabel">
                  {"(" + item?.addressLearn || "" + ")"}{" "}
                </span>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const Nothing = () => {
    return <div className="nothing"></div>;
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value); // Update the selected option when the user selects a new value
  };

  const handleSemesterChange = (event) => {
    setSelectedSemester(event.target.value); // Update the selected option when the user selects a new value
  };

  return (
    <div className="bg-white">
      <div className="mb-3 d-flex justify-content-start">
        <div className="col-1"></div>
        <div className="col-2 text-end">
          <FormControl
            style={{
              width: "100%",
            }}
          >
            <InputLabel id="demo-simple-select-helper-label">ປິຮຽນ</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedYear} // Set the value of the select element based on the state
              onChange={handleYearChange} // Add an onChange event handler to update the state when the user selects an option
            >
              <MenuItem value={"1"}>1</MenuItem>
              <MenuItem value={"2"}>2</MenuItem>
              <MenuItem value={"3"}>3</MenuItem>
              <MenuItem value={"4"}>4</MenuItem>
              <MenuItem value={"5"}>5</MenuItem>
              <MenuItem value={"6"}>6</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className="col-2">
          <FormControl
            style={{
              width: "100%",
            }}
          >
            <InputLabel id="demo-simple-select-helper-label">ພາກຮຽນ</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedSemester} // Set the value of the select element based on the state
              onChange={handleSemesterChange} // Add an onChange event handler to update the state when the user selects an option
            >
              <MenuItem value={"1"}>1</MenuItem>
              <MenuItem value={"2"}>2</MenuItem>
            </Select>
          </FormControl>
        </div>

        <Modal
          open={showCourse}
          onClose={() => setShowCourse(false)}
          className="custom-modal"
        >
          {showCourse && (
            <div className="modal-content">
              <h2 className="modal-title">ລາຍລະອຽດລາຍວິຊາ</h2>
              <div className="modal-body">
                <div className="course-info">
                  <div className="info-item">
                    <b>ຊື່ວິຊາ:</b> {courseDetail?.title}
                  </div>
                  <div className="info-item">
                    <b>ລະຫັດວິຊາ:</b> {courseDetail?.course?.courseCode}
                  </div>
                  <div className="info-item">
                    <b>ຄຳອະທິບາຍ:</b>{" "}
                    {courseDetail?.description || "ບໍ່ມິຂໍ້ມູນ"}
                  </div>
                  <div className="info-item">
                    <b>ອາທິດຮຽນ:</b> {courseDetail?.week}
                  </div>
                  <div className="info-item">
                    <b>ເວລາສອນ:</b> {courseDetail?.times}
                  </div>
                  <div className="info-item">
                    <b>ຫ້ອງຮຽນ:</b> {courseDetail?.classRoom?.name}
                  </div>
                  <div className="info-item">
                    <b>ສະຖານທີສອນ:</b>{" "}
                    {courseDetail?.addressLearn || "ບໍ່ມິຂໍ້ມູນ"}
                  </div>
                  <div className="info-item">
                    <b>ຄະນະ:</b> {courseDetail?.facName || "ບໍ່ມີຂໍ້ມຸນ"}
                  </div>
                </div>
                <div className="action-buttons">
                  <button
                    className="btn-primary"
                    onClick={() => history.push("/coursefile/1")}
                  >
                    <BookOutlined className="btn-icon" /> ເອກະສານບົດສອນ
                  </button>
                  <button
                    className="btn-secondary"
                    onClick={() => _giveGarde()}
                  >
                    <InfoOutlined className="btn-icon" /> ໃຫ້ຄະແນນນັກສຶກສາ
                  </button>
                </div>
              </div>
            </div>
          )}
        </Modal>
      </div>
      <div className="row">
        <div
          className="col-1"
          style={{
            marginTop: "80px",
          }}
        >
          <div className="hour">
            <p className="label">{"ຊົ່ວໂມງ 1"}</p>
          </div>
          <div className="hour">
            <p className="label">{"ຊົ່ວໂມງ 2"}</p>
          </div>
          <div className="hour">
            <p className="label">{"ຊົ່ວໂມງ 3"}</p>
          </div>
          <div className="hour">
            <p className="label">{"ຊົ່ວໂມງ 4"}</p>
          </div>
          <div className="hour">
            <p className="label">{"ຊົ່ວໂມງ 5"}</p>
          </div>
          <div className="hour">
            <p className="label">{"ຊົ່ວໂມງ 6"}</p>
          </div>
          <div className="hour">
            <p className="label">{"ຊົ່ວໂມງ 7"}</p>
          </div>
          <div className="hour">
            <p className="label">{"ຊົ່ວໂມງ 8"}</p>
          </div>
        </div>
        <div className="col-11" style={{ overflowX: "auto" }}>
          <table className="my-table">
            <thead>
              <tr>
                <th className="my-column-header">ຈັນ</th>
                <th className="my-column-header">ອັງຄານ</th>
                <th className="my-column-header">ພຸດ</th>
                <th className="my-column-header">ພະຫັດ</th>
                <th className="my-column-header">ສຸກ</th>
                <th className="my-column-header">ເສົາ</th>
                <th className="my-column-header">ອາທິດ</th>
              </tr>
            </thead>
            <tbody>
              <td>
                {dataTable.monday &&
                  dataTable?.monday.slice(1).map((monday, index) => {
                    if (monday)
                      return (
                        <Column key={index} data={monday || []} day={"ຈັນ"} />
                      );
                    else return <Nothing key={index} />;
                  })}
              </td>
              <td>
                {dataTable.tuesday &&
                  dataTable?.tuesday.slice(1).map((tuesday, index) => {
                    if (tuesday)
                      return (
                        <Column
                          key={index}
                          data={tuesday || []}
                          day={"ອັງຄານ"}
                        />
                      );
                    else return <Nothing key={index} />;
                  })}
              </td>

              <td>
                {dataTable.wednsday &&
                  dataTable?.wednsday.slice(1).map((wednsday, index) => {
                    if (wednsday)
                      return (
                        <Column key={index} data={wednsday || []} day={"ພຸດ"} />
                      );
                    else return <Nothing key={index} />;
                  })}
              </td>
              <td>
                {dataTable.thursday &&
                  dataTable?.thursday.slice(1).map((thursday, index) => {
                    if (thursday)
                      return (
                        <Column
                          key={index}
                          data={thursday || []}
                          day={"ພະຫັດ"}
                        />
                      );
                    else return <Nothing key={index} />;
                  })}
              </td>
              <td>
                {dataTable.friday &&
                  dataTable?.friday.slice(1).map((friday, index) => {
                    if (friday)
                      return (
                        <Column key={index} data={friday || []} day={"ສຸກ"} />
                      );
                    else return <Nothing key={index} />;
                  })}
              </td>
              <td>
                {dataTable.saturday &&
                  dataTable?.saturday.slice(1).map((saturday, index) => {
                    if (saturday)
                      return (
                        <Column
                          key={index}
                          data={saturday || []}
                          day={"ເສົາ"}
                        />
                      );
                    else return <Nothing key={index} />;
                  })}
              </td>
              <td>
                {dataTable.sunday &&
                  dataTable?.sunday.slice(1).map((sunday, index) => {
                    if (sunday)
                      return (
                        <Column key={index} data={sunday || []} day={"ອາທິດ"} />
                      );
                    else return <Nothing key={index} />;
                  })}
              </td>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CourseList;
