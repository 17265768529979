import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";
import { useSelector } from "react-redux";
import Notiflix from "notiflix";
import { Formik } from "formik";
import * as _ from "lodash";

import {
  Form,
  Modal,
  Button,
  OverlayTrigger,
  Tooltip,
  Row,
  Col,
} from "react-bootstrap";
import { CustomContainer, TableHeader } from "../../common";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";

import {
  UPDATE_GRADE,
  GRADE_COURSES,
  UPDATE_GRADE_COURSE,
  SEND_GRADE_COURSE,
  FACULTY_NAME,
} from "../../apollo/grade/index";
import {
  CALL_COURSE_DATA,
  COURSES_FACULTY_DEPARTMENT_FOR_TEACHER,
} from "../../apollo/course/index";
import { QUERY_DEPARTMENT, QUERY_MAJORS } from "../courseList/apollo/query";
import { CLASSROOM_MASTERS } from "../../apollo/classroom";
import { USER_KEY } from "../../consts/user";

// import ExcelReader from './ExcelReader'
import Loading from "../../common/Loading";
import EditGrade from "./EditGrade";
import { setGenders, convertLetterGrade } from "../../common/super";

import "./index.css";
function GiveGradeForStudentList() {
  const accessmentRedux = useSelector((state) => state.accessment.value);
  const { location, match } = useReactRouter();
  const data = location.state;

  useEffect(() => {
    console.log("course prop");

    console.log(data);
  }, [data]);

  const pageNambers = match?.params?.page;
  const PAGE_LIMIT = 50;
  const [dataFromLocation, setDataFromLocation] = useState({});
  const [countPage, setCountPage] = useState();
  const [isDisableSendGrade, setIsDisableSendGrade] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [teacherId, setTeacherId] = useState("");
  const [factId, setFactId] = useState("");
  const [departmentId, setDepartmentId] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectSemester, setSelectSemester] = useState("");
  const [dataDepartments, setDataDepartments] = useState([]);
  const [facultyArray, setFacultyArray] = useState([]);
  const [departmentArray, setDepartmentArray] = useState([]);
  const [dataFaculties, setDataFaculties] = useState([]);

  // major
  const [majorId, setMajorId] = useState("");
  const [dataMajors, setDataMajors] = useState([]);
  const [majorArray, setMajorArray] = useState([]);

  // classroom
  const [classroomId, setClassroomId] = useState("");
  const [dataClassrooms, setDataClassrooms] = useState([]);

  const [checkFacultyEmpty, setCheckFacultyEmpty] = useState(false);
  const [checkDepartmentEmpty, setCheckDepartmentEmpty] = useState(false);
  const [checkMajorEmpty, setCheckMajorEmpty] = useState(false);
  const [checkClassroomEmpty, setCheckClassroomEmpty] = useState(false);
  const [checkYearEmpty, setCheckYearEmpty] = useState(false);
  const [checkSemesterEmpty, setCheckSemesterEmpty] = useState(false);

  const [showEdit, setShowEdit] = useState(false);
  const [editData, setEditData] = useState();

  // state to set grade
  const [gradeType, setGradeType] = useState([]);
  const [confirmGrade, setConfirmGrdae] = useState(false);
  const [confirmAllGrade, setConfirmAllGrade] = useState(false);
  const [confirmSentGrade, setConfirmSentGrade] = useState(false);
  const [indexGrade, setIndexGrade] = useState(0);
  const [idGrade, setIdGrade] = useState();

  const [gradeData, setGradeData] = useState([]);

  const [checkStatus, setCheckStatus] = useState([]);
  const [isDisabledSendGrade, setIsDisabledSendGrade] = useState([]);
  const [whenClick, setWhenClick] = useState([]);

  const [dataUser, setDataUser] = useState(null);
  const [updateGrade] = useMutation(UPDATE_GRADE);
  const [loadGradeCourseData, { data: gradeCourseData, loading }] =
    useLazyQuery(GRADE_COURSES, { fetchPolicy: "network-only" });

  const [updateGradeCourse] = useMutation(UPDATE_GRADE_COURSE);
  const [sendGradeCourse] = useMutation(SEND_GRADE_COURSE);

  // query course
  const [loadFaculties, { data: facultyData }] = useLazyQuery(FACULTY_NAME);
  const [loadDataDepartment, { data: departmentsData }] = useLazyQuery(
    QUERY_DEPARTMENT,
    { fetchPolicy: "network-only" }
  );
  const [loadMajors, { data: majorData }] = useLazyQuery(QUERY_MAJORS, {
    fetchPolicy: "network-only",
  });
  const [loadClassroom, { data: classroomData }] = useLazyQuery(
    CLASSROOM_MASTERS,
    { fetchPolicy: "network-only" }
  );
  const [loadCourse, { data: courseData }] = useLazyQuery(CALL_COURSE_DATA);
  const [
    loadCoursesFacultyDepartment,
    { data: coursesAndFacultyAndDepartmentData },
  ] = useLazyQuery(COURSES_FACULTY_DEPARTMENT_FOR_TEACHER);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem(USER_KEY));
    setTeacherId(user?.data?.id);
    loadFaculties({ variables: { where: { isDeleted: false } } });
    loadCourse();
  }, []);

  useEffect(() => {
    if (data) {
      setDataFromLocation(data);
      setFactId(data?.courseClassroom[0]?.faculty?.id);
      setDepartmentId(data?.courseClassroom[0]?.department?.id);
      setMajorId(data?.courseClassroom[0]?.major?.id);
      setSelectSemester(data?.courseData?.semester);
      setSelectedYear(data?.courseData?.year);
    }
  }, [data]);

  useEffect(() => {
    loadCoursesFacultyDepartment({
      variables: { where: { teacher: teacherId } },
    });
  }, [teacherId]);

  useEffect(() => {
    if (coursesAndFacultyAndDepartmentData) {
      var arrayFaculty = [];
      var arrayDepartment = [];
      var arrayMajor = [];
      for (
        var i = 0;
        i <
        coursesAndFacultyAndDepartmentData
          ?.courseAndFacultyAndDepartmentAndMajorFromTeacher?.dataFaculty
          ?.length;
        i++
      ) {
        arrayFaculty.push({
          id: coursesAndFacultyAndDepartmentData
            ?.courseAndFacultyAndDepartmentAndMajorFromTeacher?.dataFaculty[i]
            ?.id,
        });
      }
      for (
        var i = 0;
        i <
        coursesAndFacultyAndDepartmentData
          ?.courseAndFacultyAndDepartmentAndMajorFromTeacher?.dataDepartment
          ?.length;
        i++
      ) {
        arrayDepartment.push({
          id: coursesAndFacultyAndDepartmentData
            ?.courseAndFacultyAndDepartmentAndMajorFromTeacher?.dataDepartment[
            i
          ]?.id,
        });
      }
      for (
        var i = 0;
        i <
        coursesAndFacultyAndDepartmentData
          ?.courseAndFacultyAndDepartmentAndMajorFromTeacher?.dataMajor?.length;
        i++
      ) {
        arrayMajor.push({
          id: coursesAndFacultyAndDepartmentData
            ?.courseAndFacultyAndDepartmentAndMajorFromTeacher?.dataMajor[i]
            ?.id,
        });
      }
      setFacultyArray(arrayFaculty);
      setDepartmentArray(arrayDepartment);
      setMajorArray(arrayMajor);
    }
  }, [coursesAndFacultyAndDepartmentData]);

  useEffect(() => {
    if (gradeCourseData?.grades?.data) {
      setGradeData(gradeCourseData?.grades?.data);
    }
  }, [gradeCourseData]);

  useEffect(() => {
    let grade = [];
    gradeData.map((x, index) => {
      grade.push(x.gradeType);
    });
    setGradeType(grade);

    var gradeCount = gradeData?.grades?.total;
    const countPages = [];
    for (var i = 1; i <= Math.ceil(gradeCount / PAGE_LIMIT); i++) {
      countPages.push(i);
      setCountPage(countPages);
    }

    const noNumbericGrade = gradeData?.filter(
      (item) => item?.numbericGrade === null || item?.numbericGrade === 0
    );
    if (noNumbericGrade?.length > 0) {
      // setIsDisableSendGrade(true)
    } else {
      setIsDisableSendGrade(false);
    }
  }, [gradeData]);
  // use effect
  useEffect(() => {
    if (!_.isEmpty(dataFromLocation)) {
      let _where = {
        course: dataFromLocation?.courseData?.id,
        numberTest: 1,
        gredeStatus_in: "APROVED",
        faculty: factId,
        department: departmentId,
        major: majorId,
        yearLevel: parseInt(selectedYear),
        semester: parseInt(selectSemester),
      };
      if (classroomId) _where = { ..._where, classRoom: classroomId };
      loadGradeCourseData({
        variables: {
          where: _where,
          // sendingGradeStatus_in: ["TEACHER_SCORING", "TEACHER_REJECTED"]
        },
      });
    }
  }, [
    factId,
    departmentId,
    majorId,
    classroomId,
    selectedYear,
    selectSemester,
    dataFromLocation,
  ]);

  useEffect(() => {
    if (facultyData) {
      setDataFaculties(facultyData?.faculties);
    }
  }, [facultyData]);

  useEffect(() => {
    if (departmentsData) {
      setDataDepartments(departmentsData?.departments);
    }
  }, [departmentsData]);

  useEffect(() => {
    if (majorData) setDataMajors(majorData?.majors?.data);
  }, [majorData]);

  useEffect(() => {
    if (classroomData) setDataClassrooms(classroomData?.classRooms?.data);
  }, [classroomData]);

  useEffect(() => {
    if (factId) {
      loadDataDepartment({
        variables: { where: { faculty: factId } },
      });
    }
  }, [factId]);

  useEffect(() => {
    if (departmentId) {
      loadMajors({
        variables: { where: { department: departmentId } },
      });
    }
  }, [departmentId]);

  useEffect(() => {
    if (majorId) {
      let _where = { major: majorId };
      if (selectedYear !== "")
        _where = { ..._where, year: selectedYear?.toString() };
      loadClassroom({ variables: { where: _where } });
    }
  }, [majorId, selectedYear]);

  const messageSuccess = (e) => {
    Notiflix.Notify.init({ position: "right-bottom" });
    Notiflix.Notify.success(e);
  };

  const messageError = (e) => {
    Notiflix.Notify.init({ position: "right-bottom" });
    Notiflix.Notify.success(e);
  };

  const sendingLoad = (e) => {
    Notiflix.Loading.standard(e);
  };

  const alertSuccess = (e) => {
    return Notiflix.Report.success("ສຳເລັດ", e, "ອອກ", function () {
      window.location.reload();
    });
  };

  const alertWarning = (e) => {
    return Notiflix.Report.warning("ບໍ່ສຳເລັດ", e, "ອອກ", function () {
      window.location.reload();
    });
  };

  const gradeTypeName = (name) => {
    let status = "";
    switch (name) {
      case "ຄະແນນຂື້ນຫ້ອງ":
        status = "scoreOnclass";
        break;
      case "ຄະແນນກິດຈະກຳ":
        status = "scoreOnPaticipate";
        break;
      case "ຄະແນນເສັງກາງພາກ":
        status = "scoreOnMidTerm";
        break;
      case "ຄະແນນເສັງທ້າຍພາກ":
        status = "scoreOnFinal";
        break;
    }
    return status;
  };

  const _grade = (index, id) => {
    setIndexGrade(index);
    setIdGrade(id);
    setConfirmGrdae(!confirmGrade);
  };

  // select faculty ad get value
  const _selectFaculty = async (e) => {
    setCheckFacultyEmpty(false);
    let _facultyId = e?.target?.value;
    setFactId(e.target.value);
    if (_facultyId === "") {
      setDataDepartments([]);
      setDataMajors([]);
      setMajorId("");
      setDataClassrooms([]);
      setClassroomId("");
    }
  };

  // select department
  const _onSelectedDepartment = async (e) => {
    setCheckDepartmentEmpty(false);
    let _departmentId = e?.target?.value;
    setDepartmentId(_departmentId);
    if (_departmentId === "") {
      setDataMajors([]);
      setMajorId("");
      setDataClassrooms([]);
      setClassroomId("");
    }
  };

  const _onSelectedMajor = async (e) => {
    setCheckMajorEmpty(false);
    setClassroomId("");
    let _majorId = e?.target?.value;
    setMajorId(_majorId);
    if (_majorId === "") {
      setDataClassrooms([]);
    }
  };
  const _onSelectedClassroom = async (e) => {
    setCheckClassroomEmpty(false);
    setClassroomId(e?.target?.value);
  };
  // select yaer
  const _onSelectedYear = (e) => {
    setClassroomId("");
    setCheckYearEmpty(false);
    setSelectedYear(e.target.value);
    if (e.target.value === "") {
      setDataClassrooms([]);
    }
  };
  // select unit
  const _onSelectedSemester = (e) => {
    setCheckSemesterEmpty(false);
    setSelectSemester(e.target.value);
  };

  const _showTheFacultyAccordingToTheTeacher = (id) => {
    const _showThe = facultyArray.filter((fac) => fac?.id === id);
    if (_showThe.length > 0) return false;
    else return true;
  };
  const _showTheDepartmentAccordingToTheTeacher = (id) => {
    const _showTheDepartment = departmentArray.filter((dep) => dep?.id === id);
    if (_showTheDepartment.length > 0) return false;
    else return true;
  };
  const _showTheMajorAccordingToTheTeacher = (id) => {
    const _showTheMajor = majorArray.filter((major) => major?.id === id);
    if (_showTheMajor.length > 0) return false;
    else return true;
  };

  // get depName
  const _renderCourseName = (e) => {
    let result = courseData?.courses?.data.filter(
      (item, index) => item.id === e
    );
    if (result?.length > 0) return result[0].title;
    else return "";
  };

  // Grade function
  const _detectGrade = (gradeType, name) => {
    let score = gradeType.find((x) => x.name === name)?.score;
    return score;
  };

  const _reloadGradeData = () => {
    let _where = {
      course: dataFromLocation?.courseData?.id,
      numberTest: 1,
      gredeStatus_in: "APROVED",
      faculty: factId,
      department: departmentId,
      major: majorId,
      yearLevel: parseInt(selectedYear),
      semester: parseInt(selectSemester),
    };
    if (classroomId) _where = { ..._where, classRoom: classroomId };
    loadGradeCourseData({
      variables: {
        where: _where,
      },
    });
  };

  const _saveOneGrade = async (grade) => {
    for (var i = 0; i < grade.gradeType.length; i++) {
      delete grade.gradeType[i].__typename;
    }
    try {
      await updateGrade({
        variables: {
          data: {
            gredeStatus: "APROVED",
            gradeType: grade.gradeType,
          },
          where: { id: grade.id },
        },
      })
        .then(() => {
          messageSuccess("ຢືນຢັນແລ້ວ");
          _reloadGradeData();
        })
        .catch(() => {
          messageError("ບໍ່ສາມາດແກ້ໄຂໄດ້");
        });
    } catch (error) {
      messageError("ບໍ່ສາມາດແກ້ໄຂໄດ້");
    }
  };

  async function _updateAllGrade() {
    try {
      setConfirmAllGrade(false);
      setIsLoading(true);
      gradeData.map(async (grade, index) => {
        await _grade(index, grade.id);
        await _saveOneGrade(grade);
      });
      setCheckStatus([]);
      setWhenClick([]);

      let _where = {
        course: dataFromLocation?.courseData?.id,
        numberTest: 1,
        gredeStatus_in: "APROVED",
        faculty: factId,
        department: departmentId,
        major: majorId,
        yearLevel: parseInt(selectedYear),
        semester: parseInt(selectSemester),
      };
      if (classroomId) _where = { ..._where, classRoom: classroomId };
      await loadGradeCourseData({
        variables: {
          where: _where,
        },
      });
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  }

  const _sendGrade = async () => {
    if (factId === "") setCheckFacultyEmpty(true);
    if (departmentId === "") setCheckDepartmentEmpty(true);
    if (majorId === "") setCheckMajorEmpty(true);
    if (classroomId === "") setCheckClassroomEmpty(true);
    if (selectedYear === "") setCheckYearEmpty(true);
    if (selectSemester === "") setCheckSemesterEmpty(true);

    if (
      factId === "" ||
      departmentId === "" ||
      majorId === "" ||
      classroomId === "" ||
      selectedYear === "" ||
      selectSemester === ""
    )
      return;
    try {
      await sendGradeCourse({
        variables: {
          where: {
            course: dataFromLocation?.courseData?.id,
            faculty: factId,
            department: departmentId,
            major: majorId,
            classRoom: classroomId,
            yearLevel: parseInt(selectedYear),
            assessmentYear: accessmentRedux,
            semester: selectSemester.toString(),
          },
          data: {
            sendingGradeStatus: "TEACHER_SUBMITED",
          },
        },
      })
        .then(() => {
          alertSuccess("ການສົ່ງຄະແນນຂອງທ່ານສຳເລັດແລ້ວ");
          window.location.reload();
        })
        .catch((err) => {
          alertWarning("ການສົ່ງຄະແນນຂອງທ່ານລົ້ມແຫຼວ");
        });
    } catch (err) {
      alertWarning("ການສົ່ງຄະແນນຂອງທ່ານລົ້ມແຫຼວ");
    }
  };

  const _totalGrade = (grades) => {
    let result = 0;
    grades.map((_type, _index) => {
      result += parseInt(_type?.score ?? 0);
    });
    return result;
  };

  const renderTooltip = (props, score) => (
    <Tooltip {...props}>ຄະແນນສູງສຸດແມ່ນ {score}</Tooltip>
  );

  if (isLoading) return <Loading />;
  return (
    <div>
      <div>
        {loading ? (
          <Loading />
        ) : (
          <>
            <CustomContainer>
              <div
                className="col-sm-4"
                style={{ display: "block", marginTop: 5, padding: 0 }}
              >
                <span
                  style={{
                    display: "inline-block",
                    fontSize: 24,
                    color: "#057CAE",
                    fontWeight: "bold",
                  }}
                >
                  ວິຊາ {_renderCourseName(dataFromLocation?.courseData?.id)}
                </span>
              </div>
              <Row style={{ marginBottom: 30 }}>
                <Col sm={3}>
                  <label>ຄະນະ</label>
                  <Form.Control
                    as="select"
                    style={{ cursor: "pointer" }}
                    value={factId}
                    // onChange={(e) => _selectFaculty(e)}
                    disabled
                  >
                    <option value="">ທັງໝົດ</option>
                    {dataFaculties?.map((item, index) => {
                      return (
                        <option
                          disabled={_showTheFacultyAccordingToTheTeacher(
                            item?.id
                          )}
                          key={index}
                          value={item.id}
                        >
                          {item.name}
                        </option>
                      );
                    })}
                  </Form.Control>
                  <p
                    style={{ color: "red" }}
                    hidden={checkFacultyEmpty ? false : true}
                  >
                    ກະລຸນາເລືອກຄະນະ
                  </p>
                </Col>
                <Col sm={3}>
                  <label>ພາກວິຊາ</label>
                  <Form.Control
                    as="select"
                    style={{ cursor: "pointer" }}
                    value={departmentId}
                    // onChange={(e) => _onSelectedDepartment(e)}
                    disabled
                  >
                    <option value="">ທັງໝົດ</option>
                    {dataDepartments?.map((item, index) => {
                      return (
                        <option
                          disabled={_showTheDepartmentAccordingToTheTeacher(
                            item?.id
                          )}
                          key={index}
                          value={item.id}
                        >
                          {item.name}
                        </option>
                      );
                    })}
                  </Form.Control>
                  <p
                    style={{ color: "red" }}
                    hidden={checkDepartmentEmpty ? false : true}
                  >
                    ກະລຸນາເລືອກພາກວິຊາ
                  </p>
                </Col>
                <Col sm={3}>
                  <label>ສາຂາວິຊາ</label>
                  <Form.Control
                    as="select"
                    style={{ cursor: "pointer" }}
                    value={majorId}
                    // onChange={(e) => _onSelectedMajor(e)}
                    disabled
                  >
                    <option value="">ທັງໝົດ</option>
                    {dataMajors?.map((item, index) => {
                      return (
                        <option
                          disabled={_showTheMajorAccordingToTheTeacher(
                            item?.id
                          )}
                          key={index}
                          value={item.id}
                        >
                          {item.name}
                        </option>
                      );
                    })}
                  </Form.Control>
                  <p
                    style={{ color: "red" }}
                    hidden={checkMajorEmpty ? false : true}
                  >
                    ກະລຸນາເລືອກສາຂາ
                  </p>
                </Col>
                <Col sm={3}>
                  <label>ປີຮຽນ</label>
                  <Form.Control
                    as="select"
                    style={{ cursor: "pointer" }}
                    value={selectedYear}
                    // onChange={(e) => _onSelectedYear(e)}
                    disabled
                  >
                    <option value={""}>ທັງໝົດ</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                  </Form.Control>
                  <p
                    style={{ color: "red" }}
                    hidden={checkYearEmpty ? false : true}
                  >
                    ກະລຸນາເລືອກປີຮຽນ
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm={3}>
                  <label>ພາກຮຽນ</label>
                  <Form.Control
                    as="select"
                    style={{ cursor: "pointer" }}
                    value={selectSemester}
                    // onChange={(e) => _onSelectedSemester(e)}
                    disabled
                  >
                    <option value={""}>ທັງໝົດ</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                  </Form.Control>
                  <p
                    style={{ color: "red" }}
                    hidden={checkSemesterEmpty ? false : true}
                  >
                    ກະລຸນາເລືອກພາກຮຽນ
                  </p>
                </Col>
                <Col sm={3}>
                  <label>ຫ້ອງນັກສຶກສາ</label>
                  <Form.Control
                    as="select"
                    style={{ cursor: "pointer" }}
                    value={classroomId}
                    onChange={(e) => _onSelectedClassroom(e)}
                  >
                    <option value="">ທັງໝົດ</option>
                    {dataClassrooms?.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </Form.Control>
                  <p
                    style={{ color: "red" }}
                    hidden={checkClassroomEmpty ? false : true}
                  >
                    ກະລຸນາເລືອກຫ້ອງນັກສຶກສາ
                  </p>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs={12}>
                  <span className="text-secondary">
                    ໝາຍເຫດ: ກະລຸນາເລືອກຫ້ອງນັກສຶກສາກ່ອນສົ່ງຄະແນນ
                  </span>
                </Col>
              </Row>
            </CustomContainer>
            <CustomContainer>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  // marginTop: 20,
                  marginBottom: 10,
                }}
              >
                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip-disabled">
                      ຢືນຢັນການແກ້ໄຂຄະແນນທັງໝົດ
                    </Tooltip>
                  }
                >
                  <span className="d-inline-block">
                    <Button
                      type="submit"
                      onClick={() => {
                        if (dataUser?.role != "ADMIN") {
                          setConfirmAllGrade(!confirmAllGrade);
                        }
                      }}
                      className="btn btn-info"
                      style={{ width: "190px", marginRight: 20 }}
                    >
                      ຢືນຢັນທັງໝົດ
                    </Button>
                  </span>
                </OverlayTrigger>
                &nbsp;&nbsp;&nbsp;
                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip-disabled">
                      ສົ່ງຄະແນນໄປຫາພາກວິຊາ{" "}
                    </Tooltip>
                  }
                >
                  <span className="d-inline-block">
                    <Button
                      type="submit"
                      disabled={isDisableSendGrade}
                      onClick={() => {
                        setConfirmSentGrade(!confirmSentGrade);
                      }}
                      className="btn btn-success"
                      style={{ width: "190px" }}
                    >
                      ສົ່ງຄະແນນ
                    </Button>
                  </span>
                </OverlayTrigger>
              </div>

              <div>ທັງຫມົດ {gradeData?.length} ຄົນ</div>
              {/* Table list */}
              {gradeData && (
                <Formik
                  initialValues={{}}
                  onSubmit={(values, { setSubmitting }) => {}}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    resetForm,
                  }) => (
                    <div>
                      <table
                        className="table table-bordered "
                        style={{ width: "100%" }}
                      >
                        <thead>
                          <TableHeader>
                            <th style={{ width: "5%" }}>ລຳດັບ</th>
                            <th style={{ width: "18%" }}>ຊື່ ແລະ ນາມສະກຸນ</th>
                            {gradeType[0]?.map((item, index) => {
                              return <th key={index}>{item?.name}</th>;
                            })}
                            <th width="8%">ຄະແນນລວມ</th>
                            <th style={{ width: "5%" }}>ເກຣດ</th>
                            <th style={{ width: "19%" }}>ຈັດການ</th>
                          </TableHeader>
                        </thead>
                        <tbody>
                          {loading ? (
                            <Loading />
                          ) : (
                            gradeData?.map((grade, index) => {
                              return (
                                <tr
                                  key={index}
                                  style={{ backgroundColor: "#f0f0ff" }}
                                >
                                  <td className="center">
                                    {(parseInt(pageNambers) - 1) * PAGE_LIMIT +
                                      index +
                                      1}
                                  </td>
                                  <td>
                                    {!grade?.student?.firstNameL ||
                                    grade?.student?.firstNameL === ""
                                      ? setGenders(grade?.student?.gender) +
                                        " " +
                                        grade?.student?.firstNameE +
                                        " " +
                                        grade?.student?.lastNameE
                                      : setGenders(grade?.student?.gender) +
                                        " " +
                                        grade?.student?.firstNameL +
                                        " " +
                                        grade?.student?.lastNameL}
                                    {/* {setGenders(grade?.student?.gender) ?? "-"}{" "}
                                    &nbsp;
                                    {grade?.student?.firstNameL ??
                                      "-"} &nbsp;{" "}
                                    {grade?.student?.lastNameL ?? "-"} */}
                                  </td>
                                  {grade?.gradeType.map((_type, _index) => {
                                    return (
                                      <td key={_index}>
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={renderTooltip(
                                            "",
                                            _type?.maxScore
                                          )}
                                        >
                                          <Form.Control
                                            max={_type?.maxScore}
                                            min={0}
                                            maxLength="2"
                                            className="center"
                                            type="text"
                                            name={gradeTypeName(_type?.name)}
                                            disabled={
                                              // dataUser?.role !== "ADMIN" ? true :
                                              // grade?.numbericGrade === null ? false :
                                              whenClick[index] === index - 1
                                                ? true
                                                : checkStatus[index] === index
                                                ? false
                                                : true
                                            }
                                            value={_type?.score ?? ""}
                                            // placeholder={_type?.maxScore}
                                            placeholder="0"
                                            isInvalid={
                                              gradeData[index].gradeType[_index]
                                                .score >
                                              gradeData[index].gradeType[_index]
                                                .maxScore
                                                ? true
                                                : false
                                            }
                                            onChange={(e) => {
                                              const rx_live =
                                                /^[+-]?\d*(?:[.,]\d*)?$/;

                                              if (
                                                rx_live.test(e.target.value)
                                              ) {
                                                let _total = 0;
                                                for (
                                                  var i = 0;
                                                  i <
                                                  gradeData[index].gradeType
                                                    .length;
                                                  i++
                                                ) {
                                                  if (
                                                    gradeData[index].gradeType[
                                                      i
                                                    ].name !==
                                                    gradeData[index].gradeType[
                                                      _index
                                                    ].name
                                                  ) {
                                                    _total +=
                                                      gradeData[index]
                                                        .gradeType[i].score;
                                                  }
                                                }

                                                let disable = [
                                                  ...isDisabledSendGrade,
                                                ];
                                                if (
                                                  parseInt(e.target.value) >
                                                  gradeData[index].gradeType[
                                                    _index
                                                  ].maxScore
                                                ) {
                                                  disable[_index] = true;
                                                } else {
                                                  disable[_index] = false;
                                                }
                                                setIsDisabledSendGrade(disable);
                                                if (e.target.value !== "") {
                                                  _total =
                                                    _total +
                                                    parseInt(e.target.value);
                                                  gradeData[index].gradeType[
                                                    _index
                                                  ].score = parseInt(
                                                    e.target.value
                                                  );
                                                } else {
                                                  _total = 0;
                                                  gradeData[index].gradeType[
                                                    _index
                                                  ].score = 0;
                                                }

                                                let conn = [...checkStatus];
                                                conn[index] = index;
                                                setCheckStatus(conn);
                                                let setwhen = [...whenClick];
                                                setwhen[index] = index;
                                                setWhenClick(setwhen);
                                              }
                                            }}
                                          />
                                        </OverlayTrigger>
                                      </td>
                                    );
                                  })}
                                  <td>
                                    <Form.Control
                                      disabled={true}
                                      className="center"
                                      type="text"
                                      placeholder="100"
                                      value={_totalGrade(
                                        grade?.gradeType ?? []
                                      )}
                                    />
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    <input
                                      type="text"
                                      disabled={true}
                                      className="w-100 grade-type"
                                      style={{ textAlign: "center" }}
                                      name={gradeData?.letterGrade}
                                      value={convertLetterGrade(
                                        _totalGrade(grade?.gradeType ?? [])
                                      )}
                                    />
                                  </td>

                                  <td style={{ textAlign: "center" }}>
                                    {grade?.sendingGradeStatus ===
                                      "TEACHER_SCORING" ||
                                    grade?.sendingGradeStatus ===
                                      "TEACHER_REJECTED" ? (
                                      whenClick[index] === index ? (
                                        <button
                                          className="btn btn-outline-success"
                                          disabled={
                                            isDisabledSendGrade.filter(
                                              (item) => item === true
                                            ).length > 0
                                              ? true
                                              : false
                                          }
                                          onClick={async (e) => {
                                            let conn = [...checkStatus];
                                            conn[index] = index - 1;
                                            setCheckStatus(conn);
                                            let setwhen = [...whenClick];
                                            setwhen[index] = index - 1;
                                            setWhenClick(setwhen);
                                            await _grade(index, grade.id);
                                            await _saveOneGrade(grade);
                                            resetForm({});
                                          }}
                                          style={{ fontWeight: "bold" }}
                                        >
                                          <i className="fa fa-check-circle"></i>
                                          &nbsp;&nbsp; ຢືນຢັນ
                                        </button>
                                      ) : (
                                        <OverlayTrigger
                                          overlay={
                                            <Tooltip id="tooltip-disabled">
                                              ແກ້ໄຂຄະແນນ
                                            </Tooltip>
                                          }
                                        >
                                          <button
                                            className="btn btn-outline-info"
                                            onClick={() => {
                                              let conn = [...checkStatus];
                                              conn[index] = index;
                                              setCheckStatus(conn);
                                              let setwhen = [...whenClick];
                                              setwhen[index] = index;
                                              setWhenClick(setwhen);
                                            }}
                                          >
                                            ແກ້ໄຂ
                                          </button>
                                        </OverlayTrigger>
                                      )
                                    ) : (
                                      <p style={{ color: "green" }}>
                                        <b>ສົ່ງຄະແນນແລ້ວ</b>
                                      </p>
                                    )}
                                  </td>
                                </tr>
                              );
                            })
                          )}

                          {/* })} */}
                        </tbody>
                      </table>
                      <div
                        className="col-md-12"
                        style={{ textAlign: "center" }}
                      >
                        {dataUser?.role === "ADMIN" ? (
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-disabled">
                                ຢືນຢັນການບັນທືກຄະແນນທັງໝົດ
                              </Tooltip>
                            }
                          >
                            <Button
                              type="submit"
                              onClick={() => {
                                setConfirmAllGrade(!confirmAllGrade);
                              }}
                              className="btn btn-info"
                              style={{ width: "200px" }}
                            >
                              ຢືນຢັນທັງໝົດ
                            </Button>
                          </OverlayTrigger>
                        ) : (
                          ""
                        )}
                      </div>

                      {/* <Pagination
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: 20,
                            }}
                          >
                            <Pagination.Prev onClick={_onPrevius} />
                            {countPage?.slice(0, 5).map((Item, index) => {
                              return (
                                <Pagination.Item
                                  key={"page" + index}
                                  active={Item === pageNambers}
                                  onClick={() =>
                                    history.push(`/giveGradeForStudent/${ index + 1 }`)
                                  }
                                >
                                  {Item}
                                </Pagination.Item>
                              );
                            })}
                            {countPage && countPage > 3 ? (
                              <Pagination.Item style={{ pointerEvents: "none" }}>
                                {"..."}
                              </Pagination.Item>
                            ) : (
                              " "
                            )}
                            {countPage && countPage > 3 ? (
                              <Pagination.Item
                                onClick={() =>
                                  history.push(`/giveGradeForStudent/${ countPage }`)
                                }
                              >
                                {countPage}
                              </Pagination.Item>
                            ) : (
                              " "
                            )}
                            <Pagination.Next onClick={_onNext} />
                          </Pagination> */}
                    </div>
                  )}
                </Formik>
              )}
            </CustomContainer>
          </>
        )}
      </div>
      {/* ) : (
        <CustomContainer>
          <Empty />
        </CustomContainer>
      )} */}

      <EditGrade
        show={showEdit}
        hide={() => setShowEdit(!showEdit)}
        data={editData}
      />

      <Modal
        show={confirmAllGrade}
        onHide={() => setConfirmAllGrade(!confirmAllGrade)}
      >
        <Modal.Header closeButton>
          <p>ຢືນຢັນການບັນທືກຄະແນນທັງໝົດ</p>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center" }}>
          <p>ທ່ານແນ່ໃຈແລ້ວບໍ່ ?</p>
          <div className="col-md-12" style={{ textAlign: "center" }}>
            <button
              type="submit"
              className="btn btn-outline-secondary"
              onClick={() => setConfirmAllGrade(!confirmAllGrade)}
            >
              ຍົກເລີກ
            </button>
            &nbsp;&nbsp; &nbsp;
            <button
              type="submit"
              onClick={async () => {
                await _updateAllGrade();
              }}
              className="btn btn-info"
            >
              ຢືນຢັນ
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={confirmSentGrade}
        onHide={() => setConfirmSentGrade(!confirmSentGrade)}
      >
        <Modal.Header closeButton>
          <p>ຢືນຢັນການສົ່ງຄະແນນ</p>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center" }}>
          <p>ທ່ານແນ່ໃຈແລ້ວບໍ່ວ່າຕ້ອງການສົ່ງຄະແນນວິຊານີ້ ?</p>
          <div className="col-md-12" style={{ textAlign: "center" }}>
            <button
              type="submit"
              className="btn btn-outline-secondary"
              onClick={() => setConfirmSentGrade(!confirmSentGrade)}
            >
              ຍົກເລີກ
            </button>
            &nbsp;&nbsp; &nbsp;
            <button
              type="submit"
              onClick={async () => {
                await _sendGrade();
                setConfirmSentGrade(!confirmSentGrade);
              }}
              className="btn btn-info"
            >
              ຢືນຢັນ
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default GiveGradeForStudentList;
