import React, { useState,useEffect } from "react";
import { Breadcrumb, Row, Col, Button } from "react-bootstrap";
import useReactRouter from "use-react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery, useMutation, useLazyQuery} from "@apollo/react-hooks";
// component ------------------------------------------------------------->
import CourseDocUpload from "./CourseDocUpload";
import CourseDocEdit from "./CourseDocEdit";
import CourseDocDeleteConfirm from "./CourseDocDeleteConfirm";
import { CustomContainer, Title, TableHeader, TableCell } from "../../common";
import Loading from "../../common/Loading";
import Empty from "./Empty";

// constance ------------------------------------------------------------->
import Consts from "../../consts";
import { COURSE_DOC, DELETE_FILE } from "../../apollo/course";
import {FILES} from "../../apollo/file"
import { formatDateTime } from "../../common/super";

const CourseDocList = () => {
  const { history, match } = useReactRouter();
  const id = match.params.id;


  // states ------------------------------------------------------------------------>
  const [fileCourseQuery, setFileCourseQuery] = useState([])
  const [courseDocUploadModal, setCourseDocUploadModal] = useState(false);
  const [courseDocEditModal, setCourseDocEditModal] = useState(false);
  const [courseDocDeleteConfirmModal, setCourseDocDeleteConfirmModal] =
    useState(false);
  const [fileId, setFileId] = useState();
  const [editFile, setEditFile] = useState();
  const [fileTitle, setFileTitle] = useState();
  // apollo ------------------------------------------------------------------------>
  const { loading, error, data } = useQuery(COURSE_DOC, {
    variables: { where: { id: id } },
  });


  const [loadFileCourse,{ data:fileCourseData, loading: fileCourseLoading}] = useLazyQuery(FILES,{  variables:{ where:{course: id, isDeleted: false}} })

  useEffect(()=>{
    loadFileCourse()
  },[])


  useEffect(()=>{
    if(fileCourseData){
      setFileCourseQuery(fileCourseData?.files?.data)
    }
  },[fileCourseData])


  const [deleteFile] = useMutation(DELETE_FILE);
  // functional ------------------------------------------------------------------->
  const _handlCourseDocUploadModalClose = () => setCourseDocUploadModal(false);
  const _handlCourseDocUploadModalShow = () => setCourseDocUploadModal(true);
  const _handlCourseDocEditModalClose = () => setCourseDocEditModal(false);
  const _handlCourseDocEditModalShow = () => setCourseDocEditModal(true);
  const _handlCourseDocDeleteConfirmModalClose = () =>
    setCourseDocDeleteConfirmModal(false);
  const _handlCourseDocDeleteConfirmModalShow = (data) => {
    setFileTitle(data.title);
    setFileId(data.id);
    setCourseDocDeleteConfirmModal(true);
  };

  const _courseDetail = (id) => {
    history.push(`/course-detail/id/${id}`);
  };
  const _uploadFile = () => {
    _handlCourseDocUploadModalShow();
  };
  const _editFile = (data) => {
    setEditFile(data);
    _handlCourseDocEditModalShow();
  };
  const _removeFile = async (id) => {
    setFileId(id);
    await deleteFile({
      variables: { where: { id: fileId } },
    });
    _handlCourseDocDeleteConfirmModalClose();
    window.location.reload();
  };
  // if (loading) return <Loading />;
  if (error) return <h1>GraphQL error</h1>;

  const course = data && data.course;



  // if (course) {
  // }
  return (
    <div>
      {/* Breadcrumb */}
      <Breadcrumb>
        <Breadcrumb.Item href="/course-list/1">ຈັດການວິຊາ</Breadcrumb.Item>
        <Breadcrumb.Item href="/course-list/1">ວິຊາທັງຫມົດ</Breadcrumb.Item>
        <Breadcrumb.Item active>ເອກະສານບົດສອນ</Breadcrumb.Item>
      </Breadcrumb>

      {/* Container */}
      <CustomContainer>
        {/* --------- Title and Button groups ----------- */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title text="ເອກະສານບົດສອນ" />

          {/* Button group */}
          <div>
            {/* ລາຍລະອຽດວິຊາ */}
            <button
              style={{
                backgroundColor: "#fff",
                color: Consts.PRIMARY_COLOR,
                width: 160,
                height: 40,
                border: `1px solid ${Consts.PRIMARY_COLOR}`,
                outline: "none",
                borderRadius: 0,
                marginRight: 20,
              }}
              onClick={() => _courseDetail(id)}
            >
              ລາຍລະອຽດວິຊາ
            </button>

            {/* ອັບໂຫລດບົດສອນ */}
            <button
              style={{
                backgroundColor: "#057CAE",
                color: "#fff",
                width: 160,
                height: 40,
                border: "1px solid #057CAE",
                outline: "none",
                borderRadius: 0,
              }}
              onClick={() => _uploadFile()}
            >
              <FontAwesomeIcon icon="download" style={{ fontSize: 16 }} />{" "}
              ອັບໂຫລດບົດສອນ
            </button>
          </div>
        </div>

        {/* -------- ຂໍ້ມູນວິຊາ ----------- */}
        <div style={{ marginTop: 10 }}>
          <div>ຂໍ້ມູນວິຊາ</div>
          {/* ------ detail box ------ */}
          <div
            style={{
              border: "1px solid #ddd",
              width: "60%",
              padding: 20,
              fontSize: 14,
              marginRight: "auto",
              marginLeft: "auto",
              marginTop: -10,
              paddingLeft: 80,
            }}
          >
            <Row>
              <Col>ຊື່ວິຊາ</Col>
              <Col
                style={{ color: Consts.FONT_COLOR_PRIMARY, fontWeight: "bold" }}
              >
                {course && course.title ? course.title : "-"}
              </Col>
            </Row>
            <div style={{ height: 10 }} />
            <Row>
              <Col>ລະຫັດວິຊາ</Col>
              <Col
                style={{ color: Consts.FONT_COLOR_PRIMARY, fontWeight: "bold" }}
              >
                {course && course.courseCode ? course.courseCode : "-"}
              </Col>
            </Row>
            <div style={{ height: 10 }} />
            <Row>
              <Col>ຈໍານວນຫນ່ວຍກິດ</Col>
              <Col
                style={{ color: Consts.FONT_COLOR_PRIMARY, fontWeight: "bold" }}
              >
                {course && course.unit ? course.unit : "-"}
              </Col>
            </Row>
          </div>
        </div>

        {/* ------ ເອກະສານທີ່ຖືກອັບໂຫລດ -------- */}
        <div style={{ marginTop: 20, marginBottom: 10 }}>
          ເອກະສານທີ່ຖືກອັບໂຫລດ
        </div>

        {/* ---------- table --------- */}
        <div>
          <table border="1" bordercolor="#fff" style={{ width: "100%" }}>
            <thead>
              <TableHeader>
                <th>ລຳດັບ</th>
                <th style={{ width: 250 }}>ຫົວຂໍ້</th>
                <th style={{ width: 300 }}>ໄຟລ</th>
                <th>ອັບເດດ</th>
                <th>ຈັດການ</th>
              </TableHeader>
            </thead>
            <tbody>
              {fileCourseLoading ? (
                <Loading />
              ) : fileCourseData && fileCourseData.files?.data.length>0 ? (
                fileCourseQuery.map((data, index) => {
                  return (
                    <tr
                      style={{
                        borderBottom: "2px solid #ffff",
                        textAlign: "center",
                      }}
                      key={index}
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        {data && data.title ? data.title : "-"}
                      </TableCell>
                      <TableCell>
                        <a href={data.file}>
                          {decodeURI(data.file.split("/")[4])}
                        </a>
                        {/* {data && data.file ? data.file.split("/")[4] : "-"} */}
                      </TableCell>
                      <TableCell>
                        {data && data.updatedAt
                          ? formatDateTime(data.updatedAt)
                          : "-"}
                      </TableCell>
                      <TableCell style={{ width: 150 }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-around",
                          }}
                        >
                          <div
                            onClick={() => _editFile(data)}
                            style={{ cursor: "pointer" }}
                          >
                            <Button
                              style={{
                                backgroundColor: "#fff",
                                textAlgin: "center",
                                border: "1px solid #eee",
                                margin: "3px 0px",
                              }}
                            >
                              <FontAwesomeIcon
                                icon={["fas", "pen"]}
                                color={Consts.PRIMARY_COLOR}
                              />{" "}
                            </Button>
                          </div>
                          <div
                            onClick={() =>
                              _handlCourseDocDeleteConfirmModalShow(data)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <Button
                              style={{
                                backgroundColor: "#fff",
                                textAlgin: "center",
                                border: "1px solid #eee",
                                margin: "3px 0px",
                              }}
                            >
                              <FontAwesomeIcon
                                icon={["fas", "trash"]}
                                color={Consts.COLOR_DELETE}
                              />
                            </Button>
                          </div>
                        </div>
                      </TableCell>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="5" >
                <Empty />
                </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* -------- Course doc upload modal ---------- */}
        <CourseDocUpload
          courseDocUploadModal={courseDocUploadModal}
          _handlCourseDocUploadModalClose={_handlCourseDocUploadModalClose}
          courseId={id}
          courses={course}
        />

        {/* -------- Course doc edit modal ---------- */}
        <CourseDocEdit
          courseDocEditModal={courseDocEditModal}
          _handlCourseDocEditModalClose={_handlCourseDocEditModalClose}
          // courseTitle={course.title}
          course={editFile}
        />

        {/* -------- Course delete confirm modal ---------- */}
        <CourseDocDeleteConfirm
          handleDeleteFile={_removeFile}
          fileTitle={fileTitle}
          courseDocDeleteConfirmModal={courseDocDeleteConfirmModal}
          _handlCourseDocDeleteConfirmModalClose={
            _handlCourseDocDeleteConfirmModalClose
          }
        />
      </CustomContainer>
    </div>
  );
};

export default CourseDocList;
