import React, { useState } from 'react';
import { GiveApartGrade } from './giveApartGrade';
import { GiveTotalGrade } from './giveTotalGrade';
import { GiveGrade } from './GiveGrade';

export const GiveGradeMainPage = () => {
    const [activeButton, setActiveButton] = useState('button1');

    const handleButtonClick = (buttonName) => {
        // Set the active button when clicked
        setActiveButton(buttonName);
    };

    return (
        <div className="">
            <ul className="nav nav-tabs">
                <li className="nav-item">
                    <button
                        className={`nav-link ${activeButton === 'button1' ? 'active' : ''}`}
                        onClick={() => handleButtonClick('button1')}
                    >
                        ໃຫ້ຄະເເນນແບບຍ່ອຍ
                    </button>
                </li>
                <li className="nav-item">
                    <button
                        className={`nav-link ${activeButton === 'button2' ? 'active' : ''}`}
                        onClick={() => handleButtonClick('button2')}
                    >
                        ໃຫ້ຄະເເນນແບບລວມ
                    </button>
                </li>
                <li className="nav-item">
                    <button
                        className={`nav-link ${activeButton === 'button3' ? 'active' : ''}`}
                        onClick={() => handleButtonClick('button3')}
                    >
                        ໃຫ້ຄະເເນນເປັນເກລດ
                    </button>
                </li>
            </ul>

            <div className="tab-content">
                <div className={`tab-pane fade ${activeButton === 'button1' ? 'show active' : ''}`}>
                    <GiveApartGrade />
                </div>
                <div className={`tab-pane fade ${activeButton === 'button2' ? 'show active' : ''}`}>
                    <GiveTotalGrade />
                </div>
                <div className={`tab-pane fade ${activeButton === 'button3' ? 'show active' : ''}`}>
                    <GiveGrade />
                </div>
            </div>
        </div>
    );
};
