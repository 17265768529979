import React, { useState, useEffect } from "react";
import Snackbar from "@material-ui/core/Snackbar";

export default function AlertMessage({ open, message, type }) {
  const isSuccess = type === "success";
 

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={3000} // Adjust the duration to 3000 milliseconds (3 seconds)
        message={message}
        ContentProps={{
          style: {
            backgroundColor: isSuccess ? "#48cae4" : "#ff595e",
            color: "#FFFFFF",
          },
        }}
      />
    </div>
  );
}
