import React, { useEffect, useState } from "react";
import { TextField } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { Button } from "@material-ui/core";
import axios from "axios";
import { USER_KEY } from "../../../consts/user";
import { NEW_API_URL, API_KEY } from "../../../common/constraint";

const AddRemarkModal = ({ open, onCamcel, studentId, onSuccess }) => {
  const [message, setMessage] = useState("");
  const user = localStorage.getItem(USER_KEY);
  const userObject = JSON.parse(user);
  const token = userObject?.accessToken;

  const addRemark = async (studentId) => {
    try {
      await axios.put(
        NEW_API_URL +
          `student/remark?message=${message}&studentId=${studentId}`,
        {
          headers: {
            api_key: API_KEY,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMessage("");
      onSuccess(true);
    } catch (err) {
      onSuccess(false);
    }
  };
  return (
    <Modal open={open}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh", // Adjust the height as needed
        }}
      >
        <div className="py-5 bg-white w-25 px-5 py-4 d-flex flex-column align-items-center">
          <div className="w-100">
            <TextField
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              fullWidth
              id="standard-basic"
              label="ຂໍ້ຄວາມຫມາຍເຫດ"
            />
          </div>
          <div className="d-flex justify-content-center mt-4 gap-3 w-100">
            <Button
              onClick={() => {
                addRemark(studentId);
              }}
              variant="contained"
              color="secondary"
            >
              ບັນທຶກ
            </Button>
            <Button onClick={onCamcel} variant="contained" color="primary">
              ຍົກເລີກ
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddRemarkModal;
