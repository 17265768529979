import { createSlice } from '@reduxjs/toolkit'

export const accessmentSlice = createSlice({
  name: 'accessmentYear',
  initialState: {
    value: "2021 - 2022",
  },
  reducers: {
    setAccessmentYear: (state, action) => {
      state.value = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setAccessmentYear } = accessmentSlice.actions


export default accessmentSlice.reducer