import React, { useState } from "react";
import { Form, Row, Col, Modal } from "react-bootstrap";
import useReactRouter from "use-react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation } from "@apollo/react-hooks";
import { API_KEY, NEW_API_URL } from "../../common/constraint";

// const ----------------------------------------------------------------->
import { USER_KEY } from "../../consts/user";
import { LOGIN_USER } from "../../apollo/user";
import Consts from "../../consts";
import Loading from "../../common/Loading";
import axios from "axios";
import { Button, Typography } from "@material-ui/core";

function Login() {
  const { history } = useReactRouter();

  // apollo -------------------------------------------------------------->
  const [loginUser] = useMutation(LOGIN_USER);
  // use ----------------------------------------------------------------->
  React.useEffect(() => {
    const user = JSON.parse(localStorage.getItem(USER_KEY));
    if (user) {
      history.push("/course-list/1");
    }
  }, []);
  // state ----------------------------------------------------------------->
  const [errStatus, setErrStatus] = useState(false);
  const [userIdLogin, setUserIdLogin] = useState("");
  const [passLogin, setPassLogin] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [helper, setHelper] = useState(false);
  const showHelper = () => setHelper(true);

  const _onEnterKey = (e) => {
    if (e.key === "Enter") {
      _onLoginUser();
    }
  };

  const _onLoginUser = async () => {
    setIsLoading(true);
    setErrStatus(false);
    try {
      const response = await axios.post(
        NEW_API_URL + `auth/login?userId=${userIdLogin}&password=${passLogin}`,
        {
          headers: {
            api_key: API_KEY,
          },
        }
      );

      const user = response.data;
      // Remove the JSON.stringify
      if (user.data?._id) {
        user.data.id = user.data?._id;
        delete user.data._id;
      }
      if (user.data?.department) {
        user.data.department.id = user.data.department?._id;
        delete user.data.department._id;
      }
      if (user.data?._id) {
        user.data.id = user.data._id;
        delete user.data._id;
      }
      if (user.data?.faculty) {
        user.data.faculty.id = user.data.faculty?._id;
        delete user.data.faculty._id;
      }

      localStorage.setItem(USER_KEY, JSON.stringify(user));

      if (
        response?.data?.data?.role === "TEACHER" ||
        response?.data?.data?.role === "DEP_ADMIN_TEACHER" ||
        response?.data?.data?.role === "FAC_ADMIN_TEACHER" ||
        response?.data?.data?.role === "ADMIN_TEACHER"
      ) {
       localStorage.setItem("editPassword", true);
        history.push("/course-list/1");
      } else {
        setErrStatus(true);
        setIsLoading(false);
      }
    } catch (err) {
      setErrStatus(true);
      setIsLoading(false);
    }
  };

  if (isLoading) return <Loading />;

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
      }}
      className="bg-white"
    >
      <div style={{ height: 30 }} />
      <div className="text-center m-0 p-0">
        <img
          alt="work right"
          style={{ width: 250 }}
          src="/assets/new_logo_nuol.png"
        />
      </div>
      <div className="d-flex justify-content-center">
        <div
          style={{
            backgroundColor: "#fff",
            paddingLeft: 80,
            paddingRight: 80,
          }}
        >
          <Typography className="mb-4" variant="h4">
            ມະຫາວິທະຍາໄລແຫ່ງຊາດ
          </Typography>

          <div className="">
            <Form.Group as={Row} controlId="formPlaintextEmail">
              <input
                className="newinput"
                type="text"
                name="userId"
                placeholder="ໄອດີ"
                value={userIdLogin}
                onChange={(e) => setUserIdLogin(e.target.value)}
              />
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextPassword">
              <input
                className="newinput"
                type="password"
                placeholder="ລະຫັດຜ່ານ"
                name="password"
                value={passLogin}
                onChange={(e) => setPassLogin(e.target.value)}
                onKeyDown={(e) => _onEnterKey(e)}
                tabIndex="0"
              />
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="2" />
              <Form.Label column sm="10">
                {errStatus ? (
                  <p style={{ color: "red", fontSize: 14, marginLeft: 24 }}>
                    * ໄອດີ ຫຼື ລະຫັດຜ່ານບໍ່ຖືກຕ້ອງ, ກະລຸນາລອງໃໝ່ອີກຄັ້ງ
                  </p>
                ) : (
                  ""
                )}
              </Form.Label>
            </Form.Group>
            <Button
              style={{
                paddingBottom: "10px",
                paddingTop: "10px",
                width: "100%",
                color: "white",
                backgroundColor: "#1982c4",
              }}
              onClick={_onLoginUser}
              variant="contained"
            >
              ເຂົ້າສູ່ລະບົບ
            </Button>
          </div>
          <div
            className="col-12 text-center"
            style={{ paddingRight: 28, marginTop: 18 }}
          >
            ມີ​ບັນ​ຫາ​ເຂົ້າ​ສູ່​ລະ​ບົບ?{" "}
            <span
              style={{ color: Consts.PRIMARY_COLOR, cursor: "pointer" }}
              onClick={() => showHelper(true)}
            >
              {" "}
              ຊ່ວຍ​ເຫຼືອ
            </span>
            <br />
          </div>
        </div>
      </div>
      <Modal show={helper} onHide={() => setHelper(false)} animation={false}>
        <Modal.Header
          style={{
            backgroundColor: "#057CAE",
            color: "white",
            justifyContent: "center",
            fontSize: 40,
            fontWeight: "bold",
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
          }}
        >
          <div>ຕິດ​ຕໍ່​ພວກ​ເຮົາ</div>
        </Modal.Header>
        <Modal.Body
          style={{
            color: "#707070",
            textAlign: "center",
            fontSize: 20,
            fontWeight: "bold",
          }}
        >
          <p>ຊ່ອງ​ທາງ​ໃນ​ການຕິດ​ຕໍ່​​ພວກ​ເຮົາ​</p>
          <p>ເບີ​ໂທ​ລະ​ສັບ:</p>
          <p>021 771 989</p>
          <p>ອີ​ເມວ:</p>
          <p>info.01slms@gmail.com</p>
          <img
            src="/assets/pngkit_call.png"
            style={{ width: 190, height: 121 }}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Login;
