import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";
import {
  Breadcrumb,
} from "react-bootstrap";
import "./util/index.css";
import Consts from "../../consts";
import {
  CustomContainer,
  TableHeader,
  TableCell,
  // SearchBox,
} from "../../common";
import * as _ from "lodash";
import {  useLazyQuery } from "@apollo/react-hooks";
import {
  GRADES,
  FACULTIES,
} from "../../apollo/grade/index";
import { formateDateSlash, setGenders } from "../../common/super";
import Routers from "../../consts/routes";
// import ExcelReader from './ExcelReader'
import Loading from "../../common/Loading";
import { useSelector } from 'react-redux'

function StudentListForGrade() {
  const { history,} = useReactRouter();
  const accessmentRedux = useSelector((state) => state.accessment.value);
  const [titles, setTitles] = useState(["ການເບິ່ງຄະແນນ", "ຄະແນນທັງໝົດ"]);
  const [isStudent, setIsStudent] = useState(false);
  const tab = (selected) => {
    if (selected === "student-list") {
      setTitles(["ການເບິ່ງຄະແນນ", "ຄະແນນນັກສືກສາ"]);
      if(titles?.length > 2){
        _isStudent()
      }
    } else {
      setTitles(["ການເບິ່ງຄະແນນ", "ຄະແນນທັງໝົດ"]);
    }
  };
  const onSearch = (name) => {
    setTitles([...titles, name]);
    setIsStudent(!isStudent);
  };
  const _isStudent = () => {
    setTitles(["ການເບິ່ງຄະແນນ", "ຄະແນນນັກສືກສາ"]);
    setIsStudent(!isStudent);
  };
  const [studentNumber, setStudentNumber] = useState();
  // Query faculties
  const [
    loadFaculties,
    { called: facultyCalled, loading: facultyLoading, data: facultyData },
  ] = useLazyQuery(FACULTIES);

  // States
  const [showSearchView, setShowSearchView] = useState(false);
  const [showAddConfirmModal, setShowAddConfirmModal] = useState(false);
  const [loadStatus, setLoadStatus] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [isYearClicked, setIsYearClicked] = useState(1);
  const [isTermClicked, setIsTermClicked] = useState(1);
  const [student, setStudent] = useState({});
  // const [grade, setGrade] = useState();
  const [
    loadUser,
    { data: dataUser, error: errStudent, loading: loadingStudent },
  ] = useLazyQuery(GRADES, {
    variables: {
      where: {
        student: studentNumber
      },
    },
  });

  const [loadGrade, { data, error, loading }] = useLazyQuery(GRADES, {
    variables: {
      where: {
        student: studentNumber,
        yearLevel: isYearClicked,
        semester: isTermClicked,
        assessmentYear: accessmentRedux
      },
    },
  });

  const _year = (y) => {
    setIsYearClicked(y);
    loadGrade();
  };

  const _term = (t) => {
    setIsTermClicked(t);
    loadGrade();
  };

  useEffect(() => {
    setStudent(dataUser?.grades?.data[0]);
  }, [dataUser]);
  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item
        >
          ການເບິ່ງຄະແນນ
        </Breadcrumb.Item>
        <Breadcrumb.Item active style={{ fontWeight: "bolder" }}>
          ຄະແນນທັງໝົດ
        </Breadcrumb.Item>
      </Breadcrumb>
      <div
        style={{
          display: "flex",
          // flexDirection: "row",
          // justifyContent:"between",
        }}
      >
          <div
            className="menuItemTap not-active-menuTap"
            onClick={() =>
              history.push(Routers.GRADE_LIST + Routers.PAGE_GINATION)
            }
          >
            ຄະແນນ
          </div>
        <div
          className="menuItemTap not-active-menuTap"
          onClick={() =>
            history.push(Routers.UPGRADE_LIST + Routers.PAGE_GINATION)
          }
        >
          ຄະແນນແກ້ເກຣດ
        </div>
        <div
          className="menuItemTap active-menuTap"
          onClick={() => history.push(Routers.STUDENT_FOR_GRADE)}
        >
          ນັກສຶກສາ
        </div>
      </div>
      <CustomContainer style={{ marginTop: 0 }}>
        <div style={{ display: "block" }}>
          <span
            style={{
              display: "inline-block",
              fontSize: 24,
              marginTop: 20,
              color: "#057CAE",
              fontWeight: "bold",
            }}
          >
            ຄະແນນນັກສຶກສາ
          </span>
        </div>
        {"\n"}
        {/* Table list */}
        <div style={{ display: isStudent === true ? "none" : "block" }}>
          <div className="row">
            <div className="col-md-2">
              <a
                style={{
                  margin: 0,
                  color: Consts.PRIMARY_COLOR,
                  fontWeight: "bold",
                  marginTop: 20,
                }}
              >
                ປ້ອນລະຫັດນັກສຶກສາ
              </a>
            </div>

            <div className="col-md-8">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="ລະຫັດນັກສຶກສາ"
                  value={studentNumber}
                  onChange={(e) => setStudentNumber(e.target.value)}
                // style={{ borderRadius: 20, textAlign: "center" }}
                />
                <span class="input-group-btn">
                  <button
                    type="button"
                    onClick={
                      studentNumber
                        ? () => {
                          onSearch(studentNumber);
                          loadGrade();
                          loadUser();
                        }
                        : {}
                    }
                    style={{
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                    }}
                    className="btn btn-info"
                  >
                    <i className="fa fa-search" /> ຄົ້ນຫາ
                  </button>
                </span>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <i
              className="fa fa-search"
              style={{
                marginTop: 100,
                fontSize: 100,
                color: "#8B8B8B",
                opacity: 0.5,
              }}
            />
            <p
              style={{
                fontSize: 30,
                fontWeight: "bolder",
                marginTop: 30,
                color: "#8B8B8B",
                opacity: 0.5,
              }}
            >
              ~ ປ້ອນເພື່ອຄົ້ນຫາ ~
            </p>
          </div>
        </div>
        <div style={{ display: isStudent ? "block" : "none" }}>
          {loading ? (
            <Loading />
          ) : (
            <div>
              <div style={{ marginTop: 15 }}>
                <a
                  style={{
                    borderBottom: `2px solid ${Consts.PRIMARY_COLOR}`,
                    color: "#212529",
                    fontWeight: "bolder",
                    fontSize: 20,
                  }}
                >
                  ຜົນການຄົ້ນຫາ:
                </a>
                <a>
                  {setGenders(student?.student?.gender)}
                  &nbsp;
                  {student?.student?.firstNameL ?? "-"} /{" "}
                  {student?.student?.lastNameL ?? "-"}
                </a>
              </div>
              <div style={{ marginTop: 20, marginLeft: 15 }}>
                <div className="row" style={{ marginBottom: 10 }}>
                  <div className="col-3 styleLeft">ຊື່ ແລະ ນາມສະກຸນ</div>
                  <div className="col-3 styleRight">
                    {setGenders(student?.student?.gender)}
                    &nbsp;
                    {student?.student?.firstNameL ?? "-"} /{" "}
                    {student?.student?.lastNameL ?? "-"}
                  </div>
                </div>
                <div className="row" style={{ marginBottom: 10 }}>
                  <div className="col-3 styleLeft">ອັບເດດລ່າສຸດໂດຍ</div>
                  <div className="col-3 styleRight">
                    {dataUser?.grades?.data[0]?.updatedBy ?? "-"}
                  </div>
                </div>
                <div className="row" style={{ marginBottom: 10 }}>
                  <div className="col-3 styleLeft">ເວລາອັບເດດລ່າສຸດ</div>
                  <div className="col-3 styleRight">
                    {formateDateSlash(student?.student?.updatetedAt)}
                  </div>
                </div>
              </div>

              <div style={{ marginTop: 20 }}>
                <div className="row">
                  <div className="col-1 head">ປີຮຽນ</div>
                  <div
                    onClick={() => _year(1)}
                    className={`col-1 year-term ${isYearClicked === 1 ? "active-menu" : "menu-Item"
                      }`}
                  >
                    ປີ1
                  </div>
                  <div
                    onClick={() => _year(2)}
                    className={`col-1 year-term ${isYearClicked === 2 ? "active-menu" : "menu-Item"
                      }`}
                  >
                    ປີ2
                  </div>
                  <div
                    onClick={() => _year(3)}
                    className={`col-1 year-term ${isYearClicked === 3 ? "active-menu" : "menu-Item"
                      }`}
                  >
                    ປີ3
                  </div>
                  <div
                    onClick={() => _year(4)}
                    className={`col-1 year-term ${isYearClicked === 4 ? "active-menu" : "menu-Item"
                      }`}
                  >
                    ປີ4
                  </div>
                  <div
                    onClick={() => _year(5)}
                    className={`col-1 year-term ${isYearClicked === 5 ? "active-menu" : "menu-Item"
                      }`}
                  >
                    ປີ5
                  </div>
                </div>
              </div>
              <div style={{ marginTop: 20 }}>
                <div className="row">
                  <div className="col-1 head">ເທີມ</div>
                  <div
                    onClick={() => _term(1)}
                    className={`col-1 year-term ${isTermClicked === 1 ? "active-menu" : "menu-Item"
                      }`}
                  >
                    ເທີມ1
                  </div>
                  <div
                    onClick={() => _term(2)}
                    className={`col-1 year-term ${isTermClicked === 2 ? "active-menu" : "menu-Item"
                      }`}
                  >
                    ເທີມ2
                  </div>
                </div>
              </div>
              <div>
                {/* {(loading || called) && ""} */}
                <table
                  border="1"
                  bordercolor="#fff"
                  style={{ width: 500, marginTop: 20 }}
                >
                  <thead>
                    <TableHeader>
                      <th>ຊື່ວິຊາ</th>
                      <th>ຄັ້ງທີ່ສອບເສັງ</th>
                      <th>ຄະແນນ</th>
                      <th>Grade</th>
                    </TableHeader>
                  </thead>
                  <tbody>
                    {data?.grades?.data?.map((x, index) => {
                      return (
                        <tr
                          style={{
                            borderBottom: "2px solid #ffff",
                            textAlign: "center",
                          }}
                          key={index}
                        >
                          <TableCell>{x?.course?.title ?? "-"}</TableCell>
                          <TableCell>{x?.numberTest ?? "1"}</TableCell>
                          <TableCell>{x?.numbericGrade ?? "-"}</TableCell>
                          <TableCell>{x?.letterGrade ?? "-"}</TableCell>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </CustomContainer>
    </div>
  );
}

export default StudentListForGrade;
