import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";
import { Breadcrumb, OverlayTrigger, Tooltip, Col,Form,Row} from "react-bootstrap";
import { useLazyQuery } from "@apollo/react-hooks";
import { USER_KEY } from "../../consts/user";
import _ from "lodash";
// component ----------------------------------------------------------------------->
import RegisterSearch from "./RegisterSearch";
import RegisterModal from "./RegisterModal";
import Loading from "../../common/Loading";
// consts -------------------------------------------------------------------------->
import Consts from "../../consts";
import { CustomContainer, SearchBar, Title, TableHeader, TableCell, } from "../../common";
import { GRADES_REQUESTING } from "../../apollo/grade";
import { Tabs } from "react-bootstrap";
import { Tab } from "react-bootstrap";
// import RegisterSearch from "./registerSearch";
const RegisterList = () => {
  const { history, match, location } = useReactRouter();
  // states ------------------------------------------------------------->
  const [showSearchView, setShowSearchView] = useState(false);
  const [isShowUpgrade, setIsShowUpgrade] = useState(false);
  const [studentCode, setStudentCode] = useState("");

  const [upgradeData, setUpgradeData] = useState([]);
  const [totals, setTotals] = useState(0);
  const [upgradeDatadetail, setUpgradeDataDetail] = useState({});
  const [studentId, setStudentId] = useState();
  // apollo ------------------------------------------------------------->

  const courseId = match?.params?.id;
  // var student = { student: { userId: student } };
  const [loadData, { loading, data }] = useLazyQuery(GRADES_REQUESTING);


  // use ---------------------------------------------------------------->
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem(USER_KEY));
    // if (user) { setUserLoginData(user?.data); }


    loadData({
      variables: {
        where: {
          numberTest: 1,
          gredeStatus: "REQUESTING",
          course:courseId,
            // teacher_some: { id: user?.data?.id },
          // student: { userId_contains: studentId ? studentId : "" },
        },
      },
    });
  }, []);


  useEffect(()=> {
    if(studentCode){
      loadData({
        variables: {
          where: {
            numberTest: 1,
            gredeStatus: "REQUESTING",
            course:courseId,
              // teacher_some: { id: user?.data?.id },
             student: studentCode ,
          },
        },
      });
    }
    else{
      loadData({
        variables: {
          where: {
            numberTest: 1,
            gredeStatus: "REQUESTING",
            course:courseId,
          },
        },
      });
    }
  },[studentCode])



  useEffect(() => {
    if (data?.grades?.data) {
      setUpgradeData(data.grades.data);
      setTotals(data.grades.total);

    }
  }, [data]);
  // Search
  const _onSearch = async (value) => {
    setStudentId(value.createdID);
  };

  // if (loading) return <Loading />;

  return (
    <div>
      {/* Breadcrumb */}

      <Breadcrumb>
        <Breadcrumb.Item onClick={() => history.push("/course-list/1")}>
          ວິຊາທີສອນ
        </Breadcrumb.Item>
        <Breadcrumb.Item onClick={() => history.push("/course-detail/id/" + location.state.id)}  >

          ລາຍລະອຽດວິຊາ
        </Breadcrumb.Item>
        <Breadcrumb.Item active style={{ fontWeight: "bolder" }}>
          ຈຳນວນຜູ້ລົງທະບຽນ
        </Breadcrumb.Item>
      </Breadcrumb>

      <CustomContainer>
  
        <Title text={"ລາຍຊື່ນັກສຶກສາທີ່ລົງທະບຽນຮຽນ"} />

        {/* custom search button */}
        {/* <SearchBar
          title="ປ້ອນ ID ນັກສຶກສາ"
          onClick={() => setShowSearchView(!showSearchView)}
        /> */}
        <Col md={12} style={{ marginBottom: 20, marginTop: 10 }}>
                        <Form.Group
                            as={Row}
                            style={{
                                margin: 0,
                                marginTop: 10,
                                marginBottom: 10,
                                alignItems: "center",
                                fontSize: 16,
                            }}
                        >
                            <Form.Label column sm="1" className="text-left">
                                ຄົ້ນຫາ
                            </Form.Label>
                            <Col md="11">
                                <Form.Control
                                    style={{ borderRadius: 50 }}
                                    placeholder="ປ້ອນໄອດີລະຫັດ ນັກສຶກສາ"
                                    type="text"
                                    onChange={(e) => setStudentCode(e.target.value)}
                                />
                            </Col>
                        </Form.Group>
                    </Col>

        {/* <Tabs defaultActiveKey="second">
        <Tab eventKey="first" title="ຫ້ອງ wc1">
        </Tab>
        <Tab eventKey="second" title="ຫ້ອງ wc2">
        </Tab>
        <Tab eventKey="third" title="ຫ້ອງ cs1">
        </Tab>
        <Tab eventKey="four" title="ຫ້ອງ cs2">
        </Tab>
        <Tab eventKey="five" title="ຫ້ອງ cs3">
        </Tab>
        <Tab eventKey="sixt" title="ຫ້ອງ cpr1">
        </Tab>
        <Tab eventKey="seven" title="ຫ້ອງ cpr1">
        </Tab>
        <Tab eventKey="eight" title="ຫ້ອງ cpr1">
        </Tab>
        <Tab eventKey="nine" title="ຫ້ອງ cpr1">
        </Tab>
      </Tabs> */}
        <div
          style={{
            marginTop: 24,
            marginBottom: 8,
            fontSize: 16,
            color: Consts.FONT_COLOR_SECONDARY,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          ທັງຫມົດ {totals} ຄົນ
        </div>

        {/* Table list */}
        <div>
          <table border="1" bordercolor="#fff" style={{ width: "100%" }}>
            <thead>
              <TableHeader style={{ backgroundColor: "#057CAE" }}>
                <th style={{ width: 70 }}>ລຳດັບ</th>
                <th>ລະຫັດນັກສຶກສາ</th>
                <th>ຊື່ນັກສຶກສາ</th>
                <th>ນັກສຶກສາປີ</th>
                <th>ພາກຮຽນ</th>
                <th>ສົກຮຽນ</th>
                <th>ຊື່ວິຊາທີ່ລົງທະບຽນ</th>
                {/* <th>ຊື່ອາຈານປະຈຳວິຊາ</th>
                <th>ເກດເກົ່າ</th> */}
                <th style={{ width: 200 }}>ຈັດການ</th>
              </TableHeader>
            </thead>
            <tbody>
              {loading ? (
                <Loading />
              ) : (
                upgradeData?.map((grade, index) => {
                  return (
                    <tr
                      style={{
                        border: "2px solid white",
                      }}
                      key={index}
                    >
                      <TableCell
                        style={{
                          backgroundColor: "#EEEEEE",
                          textAlign: "center",
                        }}
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell style={{ backgroundColor: "#EEEEEE" }}>
                        {grade?.student?.userId}
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        {grade?.student?.firstNameL}
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: "#EEEEEE",
                          textAlign: "center",
                        }}
                      >
                        {grade?.student?.yearLevel}
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: "#EEEEEE",
                          textAlign: "center",
                        }}
                      >
                        {grade?.semester}
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: "#EEEEEE",
                          textAlign: "center",
                        }}
                      >
                        {grade?.student?.assessmentYear}
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: "#EEEEEE",
                          textAlign: "center",
                        }}
                      >
                        {grade?.course?.title}
                      </TableCell>

                      <TableCell
                        style={{
                          width: 200,
                          backgroundColor: "#EEEEEE",
                          textAlign: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            // flexDirection: "row",
                            justifyContent: "space-around",
                            width: 200,
                          }}
                        >
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-disabled">
                                ຍອມຮັບເອົານັກສຶກສາເຂົ້າຮຽນ
                              </Tooltip>
                            }
                          >
                            <button
                              onClick={() => {
                                setUpgradeDataDetail(grade);
                                setIsShowUpgrade(!isShowUpgrade);
                              }}
                              style={{
                                width: 100,
                                height: 30,
                                borderRadius: 3,
                                border: "1px solid #137DAE",
                                // outline: "none",
                                backgroundColor: "white",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              ລົງທະບຽນ
                            </button>
                          </OverlayTrigger>
                        </div>
                      </TableCell>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </CustomContainer>
      {/* Search Modal */}
      <RegisterSearch
        showSearchView={showSearchView}
        _handleSearchViewClose={() => setShowSearchView(!showSearchView)}
        onSearch={(value) => _onSearch(value)}
      />
      <RegisterModal
        isShowUpgrade={isShowUpgrade}
        _handleUpgradeClose={() => setIsShowUpgrade(!isShowUpgrade)}
        data={upgradeDatadetail}
      />
    </div>
  );
};

export default RegisterList;
