import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, Form, Button, Col, Row } from "react-bootstrap";
import { FormControl, TextField } from "@material-ui/core";
import { UPDATE_USER_PASSWORD } from "../../apollo/user";
import { useMutation } from "@apollo/react-hooks";
import { USER_KEY } from "../../consts/user";

function ChangePasswordModal({ onClose, show }) {
  const user = localStorage.getItem(USER_KEY);
  const [updateUserPassword] = useMutation(UPDATE_USER_PASSWORD);
  const userObject = JSON.parse(user);

  const [password, setPassword] = useState();
  const _onEdit = async () => {
    if (password) {
      try {
        const res = await updateUserPassword({
          variables: {
            data: {
              // Assuming UserUpdatePassword expects a field like "password"
              password: password,
            },
            where: {
              // Assuming CustomUserWhereUniqueInput expects an ID field
              userId: userObject?.data?.userId,
            },
          },
        });
        if (res?.data) {
          localStorage.removeItem("editPassword");
          onClose();
        }
      } catch (err) {}
    }
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={onClose}
        size="sm"
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header style={{ borderBottom: 0 }}>
          <Modal.Title style={{ textAlign: "center", width: "100%" }}>
            ປ່ຽນລະຫັດຜ່ານ
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormControl fullWidth>
            <TextField
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              placeholder="ລະຫັດຜ່ານໃຫມ່"
              fullWidth
            />
          </FormControl>
        </Modal.Body>
        <Modal.Footer>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div>
              <button
                className="btn px-3 mr-3 btn-danger"
                onClick={() => {
                  _onEdit();
                }}
                variant={"outline-dark"}
              >
                ຢືນຢັນ
              </button>
            </div>
            <div>
              <button
                className="btn px-3 btn-primary"
                onClick={() => {
                  onClose();
                }}
                variant={"outline-dark"}
              >
                ຍົກເລີກ
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

ChangePasswordModal.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ChangePasswordModal;
