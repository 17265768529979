import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useReactRouter from "use-react-router";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { MOODLE_URL } from "../../consts";
function ButtonDetail({ courseId, moodleCourseId }) {
  const { history } = useReactRouter();
  const _onCourseDetail = (id) => {
    history.push(`/course-detail/id/${id}`);
    // window.location.reload(true)
  };
  const _onDocumentFile = (id) => {
    history.push(`/course-doc-list/${id}`);
    // window.location.reload(true)
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        width: 200,
      }}
    >
      <OverlayTrigger
        overlay={<Tooltip id="tooltip-disabled">ເບິ່ງເອກະສານບົດສອນ</Tooltip>}
      >
        <button
          onClick={() => {
            _onDocumentFile(courseId);
          }}
          style={{
            width: 80,
            height: 30,
            borderRadius: 3,
            border: "1px solid #ddd",
            outline: "none",
            backgroundColor: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <i style={{ color: "#057CAE" }} className="fa fa-file" />
        </button>
      </OverlayTrigger>
      <OverlayTrigger
        overlay={<Tooltip id="tooltip-disabled">ເບິ່ງລາຍລະອຽດຂອງວິຊາ</Tooltip>}
      >
        <button
          onClick={() => _onCourseDetail(courseId)}
          style={{
            width: 60,
            height: 30,
            borderRadius: 3,
            border: "1px solid #ddd",
            outline: "none",
            backgroundColor: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FontAwesomeIcon icon={["fa", "eye"]} color="#057CAE" />
        </button>
      </OverlayTrigger>
      {/* <OverlayTrigger
        overlay={<Tooltip id="tooltip-disabled">ເບິ່ງລາຍລະອຽດຂອງວິຊາ</Tooltip>}
      >
        <a
          href={`${MOODLE_URL}/user/index.php?id=${moodleCourseId}`}
          target="_blank"
        >
          <button
            onClick={() => _onCourseDetail(courseId)}
            style={{
              width: 60,
              height: 30,
              borderRadius: 3,
              border: "1px solid #ddd",
              outline: "none",
              backgroundColor: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              style={{ width: 23, height: 23 }}
              src="/assets/moodleyes.png"
            />{" "}
          </button>
        </a>
      </OverlayTrigger> */}
    </div>
  );
}

export default ButtonDetail;
