import React, { useState } from "react";
import { Navbar, Nav, NavDropdown, Modal, Button, Form } from "react-bootstrap";
import useReactRouter from "use-react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import "./navBar.css";
import { useSelector, useDispatch } from "react-redux";
import { setAccessmentYear } from "../redux/accessmentSlice";
// component -------------------------------------------------------->
import Profile from "../pages/profile/Profile";
import ChangePasswordModal from "../pages/profile/ChangePassword";

// const ------------------------------------------------------------>
import { USER_KEY } from "../consts/user";
import { USER } from "../apollo/user";
import { useQuery } from "@apollo/react-hooks";
import Consts from "../consts";

export default function NavBar({ handleShowUserProfile }) {
  const dispatch = useDispatch();
  const [callStdId, setCallStdId] = useState();

  const { history } = useReactRouter();
  const [userLoginData, setUserLoginData] = React.useState();
  const [showProfileModal, setShowProfileModal] = React.useState();
  const [showChangepassword, setShowPasswordModal] = useState(false);
  const [showConfirmLogouModal, setShowConfirmLogoutModal] =
    React.useState(false);
  // apollo ------------------------------------------>
  const { data } = useQuery(USER, {
    variables: { where: { id: userLoginData && userLoginData.id } },
  });
  React.useEffect(() => {
    const user = JSON.parse(localStorage.getItem(USER_KEY));
    if (user) {
      setUserLoginData(user.data);
    }
    let year = [];
    const NOW = new Date().getFullYear();
    const checkMonth = new Date().getMonth() + 1;
    if (
      checkMonth === 8 ||
      checkMonth === 9 ||
      checkMonth === 10 ||
      checkMonth === 11 ||
      checkMonth === 12
    ) {
      for (var i = NOW; i >= 2014; i--) {
        year.push(i + " - " + (parseInt(i) + 1));
      }
    } else {
      for (var i = NOW; i >= 2014; i--) {
        year.push(i - 1 + " - " + parseInt(i));
      }
    }

    setCallStdId(year);
    dispatch(setAccessmentYear(year[0]));
  }, []);

  const _onConfirmLogout = async () => {
    await localStorage.clear();
    history.push("/");
  };
  // modal
  const _handleShowProfileModal = () => setShowProfileModal(true);
  const _handleCloseProfileModal = () => setShowProfileModal(false);
  const _onClickLogout = () => setShowConfirmLogoutModal(true);

  const onSelectAccessmentYear = (e) => {
    dispatch(setAccessmentYear(e.target.value));
  };
  return (
    <div>
      <Navbar fixed="top" style={{ height: 50 }} bg="white">
        <Navbar.Collapse className="justify-content-end">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: 50,
              paddingTop: 10,
              alignItems: "flex-end",
              justifyContent: "center",
            }}
          >
            <span style={{ color: "grey" }}>{Consts.VERSION}</span>
          </div>
          <div style={{ width: 70 }} />
          <div style={{ width: 300, height: 50 }}>
            <Form.Control
              as="select"
              style={{
                border: "none",
                backgroundColor: "#f1f1f1f1",
                cursor: "pointer",
                marginTop: 10,
              }}
              onChange={(e) => onSelectAccessmentYear(e)}
            >
              {callStdId?.map((year, index) => (
                <option key={index} value={year}>
                  {year}
                </option>
              ))}
            </Form.Control>
          </div>
          <Nav>
            <NavDropdown
              title={
                userLoginData && userLoginData.firstname
                  ? userLoginData.firstname +
                    " " +
                    (userLoginData.lastname ? userLoginData.lastname : "")
                  : "ຊື່ຜູ້ໃຊ້"
              }
              id="dropdown-menu-align-right"
              alignRight
            >
              <NavDropdown.Item onClick={_handleShowProfileModal}>
                <FontAwesomeIcon style={{ color: "#057CAE" }} icon={faUser} />{" "}
                ຂໍ້ມູນຜູ້ໃຊ້
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => setShowPasswordModal(true)}>
                <i
                  style={{ color: "#057CAE" }}
                  class="fa fa-key"
                  aria-hidden="true"
                ></i>
                <FontAwesomeIcon /> ປ່ຽນລະຫັດຜ່ານ
              </NavDropdown.Item>
              <NavDropdown.Item onClick={_onClickLogout}>
                <FontAwesomeIcon
                  style={{ color: "#057CAE" }}
                  icon={faSignOutAlt}
                />{" "}
                ອອກຈາກລະບົບ
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <Profile
        showUserProfile={showProfileModal}
        _handleShowUserProfile={_handleCloseProfileModal}
        data={data}
      />

      <ChangePasswordModal
        onClose={() => setShowPasswordModal(false)}
        show={showChangepassword}
      />

      <Modal
        show={showConfirmLogouModal}
        onHide={() => setShowConfirmLogoutModal(false)}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>ຕ້ອງການອອກຈາກລະບົບ ?</Modal.Header>
        <Modal.Body>
          <div className="mt-4">
            <Button
              className="mx-3"
              variant="outline-dark"
              onClick={() => setShowConfirmLogoutModal(false)}
            >
              ຍົກເລີກ
            </Button>
            <Button
              variant="primary"
              onClick={_onConfirmLogout}
              style={{ float: "right", backgroundColor: Consts.PRIMARY_COLOR }}
            >
              ອອກຈາກລະບົບ
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
