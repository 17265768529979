import React, { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import { IconButton, TextField, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { NEW_API_URL, API_KEY } from "../../common/constraint";
import axios from "axios";
import { USER_KEY } from "../../consts/user";

const ManageAccessDialog = ({ id, type, open, onCancel }) => {
  const [existPermissionList, setExistPermissionList] = useState([]);
  const user = localStorage.getItem(USER_KEY);
  const userObject = JSON.parse(user);
  const token = userObject?.accessToken;

  const [courseId, setCourseId] = useState("");
  const [classList, setClassList] = useState([]);

  const [formData, setFormData] = useState({
    faculty: userObject?.data?.faculty.id,
    department: "",
    major: "",
  });

  const fetchPermissionList = async () => {
    if (type === "FOLDER") {
      const response = await axios.get(NEW_API_URL + "filefolder/" + id, {
        headers: {
          api_key: API_KEY,
          Authorization: `Bearer ${token}`,
        },
      });

      setCourseId(response?.data?.course);
      setExistPermissionList(response.data?.classRoom || []);
    }
    if (type === "FILE") {
      const response = await axios.get(NEW_API_URL + "course/files/" + id, {
        headers: {
          api_key: API_KEY,
          Authorization: `Bearer ${token}`,
        },
      });

      setCourseId(response?.data?.course);
      setExistPermissionList(response.data?.classRoom || []);
    }
  };

  useEffect(() => {
    fetchPermissionList();
    fetchTeachedSubjectClassData(courseId)
  }, [type, open]);

  const removeFolderPermission = async (classId) => {
    if (type === "FOLDER") {
      await axios.patch(
        NEW_API_URL +
          "filefolder/permission/remove?folderId=" +
          id +
          "&classroomId=" +
          classId,
        {
          headers: {
            api_key: API_KEY,
            Authorization: `Bearer ${token}`,
          },
        }
      );
    }
    if (type === "FILE") {
      await axios.patch(
        NEW_API_URL + "course/files/permission/remove",
        {
          classroomId: classId,
          fileId: id,
        },
        {
          headers: {
            api_key: API_KEY,
            Authorization: `Bearer ${token}`,
          },
        }
      );
    }

    fetchPermissionList();
  };

  const fetchTeachedSubjectClassData = async (courseId) => {
    try {
      const res = await axios.get(
        NEW_API_URL +
          `teacher/class?teacherId=${userObject?.data?.id}&courseId=${courseId?._id}`,
        {
          headers: {
            api_key: API_KEY,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setClassList(res.data);
    } catch (err) {
      console.error("Error fetching class data:", err);
    }
  };

  const addPermission = async (classId) => {
    if (type === "FOLDER") {
      await axios.patch(
        NEW_API_URL + "filefolder/permission",
        {
          classroomIds: [classId],
          folderId: id,
        },
        {
          headers: {
            api_key: API_KEY,
            Authorization: `Bearer ${token}`,
          },
        }
      );
    }

    if (type === "FILE") {
      await axios.patch(
        NEW_API_URL + "course/files/permission",
        {
          classroomIds: [classId],
          fileId: id,
        },
        {
          headers: {
            api_key: API_KEY,
            Authorization: `Bearer ${token}`,
          },
        }
      );
    }
    fetchPermissionList();
  };

  useEffect(() => {
    fetchTeachedSubjectClassData(courseId);
  }, [courseId, id]);

  // check if class list is exist in in existing then set it to disable
  // cause it already added
  const isExist = (classId) => {
    return existPermissionList.some(
      (item) => item._id === (classId.id || classId._id)
    );
  };

  return (
    <Modal open={open}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <div
          style={{
            width: "300px",
          }}
          className="bg-white w-50 px-5 pt-5 pb-4 d-flex flex-column align-items-center"
        >
          <Typography variant="h5">ຈັດການສິດການເຂົ້າເຖີງ</Typography>
          <div className="item"></div>
          <div className="my-2 text-start w-100">
            <Typography variant="h6">
              ລາຍການຫ້ອງປັດຈຸບັນທີສາມາດເຂົ້າເຖີງ
            </Typography>
          </div>
          <div className="row w-100">
            {existPermissionList.length > 0 &&
              existPermissionList.map((_class, index) => (
                <div
                  className="class-container-1 col-lg-3 col-md-4 col-sm-6 mb-3"
                  key={index}
                >
                  <div className="d-flex align-items-center">
                    <div className="flex-grow-1">
                      <Typography>{_class?.name}</Typography>
                    </div>
                    <IconButton
                      onClick={() => {
                        removeFolderPermission(_class?._id);
                      }}
                    >
                      <img
                        width="24"
                        height="24"
                        src="https://img.icons8.com/material-rounded/24/filled-trash.png"
                        alt="filled-trash"
                      />
                    </IconButton>
                  </div>
                </div>
              ))}
          </div>
          <div
            className="text-start"
            style={{
              width: "100%",
            }}
          ></div>
          {classList.length > 0 && (
            <div className="text-start w-100 mt-2">
              <Typography variant="h6">
                {"ລາຍການຫ້ອງ (ກົດເພື່ອເພີ່ມ)"}
              </Typography>
            </div>
          )}
          <div className="row mt-3 w-100">
            {classList.length > 0 &&
              classList.map((_class, index) =>
                !isExist(_class) ? (
                  <div
                    onClick={() => {
                      addPermission(_class?.id || _class?._id);
                    }}
                    className="class-container col-lg-3 col-md-4 col-sm-6 mb-3"
                    key={index}
                  >
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1">
                        <Typography>{_class?.name}</Typography>
                      </div>
                    </div>
                  </div>
                ) : null
              )}
          </div>

          <div className="col mt-3 text-center">
            <Button
              onClick={() => {
                onCancel();
              }}
              variant="outlined"
              color="primary"
            >
              ປິດ
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ManageAccessDialog;
