import React from "react";

function CustomButton({ title, onClick, confirm, addIcon, width }) {
  return (
    <button
      style={{
        backgroundColor: confirm ? "#057CAE" : "#fff",
        color: confirm ? "#fff" : "#000",
        width: width || 140,
        height: 40,
        border: confirm ? "1px solid #057CAE" : "1px solid #057CAE",
        outline: "none",
      }}
      onClick={() => onClick()}
    >
      {addIcon && <i className="fa fa-plus" />} {title}
    </button>
  );
}

export default CustomButton;
