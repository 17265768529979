import Consts from "../../consts";

var noneSelect = {
    flexShrink: 0,
    width: 80,
    height: 50,
    backgroundColor: Consts.FONT_COLOR_WHITE,
    color: Consts.PRIMARY_COLOR,
    border: `2px solid ${Consts.PRIMARY_COLOR}`,
    margin: 5,
    borderRadius: 50 / 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor:'pointer'
}
var Selected = {
    flexShrink: 0,
    width: 150,
    height: 50,
    backgroundColor: Consts.PRIMARY_COLOR,
    color: Consts.FONT_COLOR_WHITE,
    border: `2px solid ${Consts.PRIMARY_COLOR}`,
    margin: 5,
    borderRadius: 50 / 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor:'pointer'
}
var buttonClicked = {
    backgroundColor: '#fff',
    color: '#000',
    borderBottom: '5px solid #057CAE',
    borderTop: '0px solid #fff',
    borderLeft: '0px solid #fff',
    borderRight: '0px solid #fff',
    fontSize: 18,
    fontWeight: 'bold'
}
var buttonClick = {
    backgroundColor: '#fff',
    border: '0px solid #fff',
    color: '#C7C7C7',
    fontSize: 18,
    fontWeight: 'bold'
}
var buttonStyle = {
    width: '50%',
    display: 'flex',
    flexDirection: 'row',
    height: 60,
    justifyContent: 'center',
    alignItems: 'center'
}
var innerLine = {
    outline: "1px solid #e6e6e6",
    outlineOffset: "-8px",
    border: '15px solid #fff',
    backgroundColor: '#fff',
};
const innerLineHave = {
    outline: "1px solid #e6e6e6",
    outlineOffset: "-8px",
    border: '15px solid #fff',
    cursor: 'pointer',
};
const timesBox = { 
    display: 'flex', 
    flexDirection: 'row', 
    width: '100%', 
    marginTop: 20, 
    minHeight: 90 
};
const times = { 
    display: 'flex', 
    flexDirection: 'column', 
    width: '15%', 
    justifyContent: 'center', 
    alignItems: 'center' 
};
const timesResult = { 
    display: 'flex', 
    flexDirection: 'column', 
    width: '85%', 
};

const courseList = {
    padding: 10, 
    width: "100%", 
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow: '2px 2px 6px #00000029', 
    borderRadius: 3,
    marginTop: 10
}

const Styles = {
    noneSelect: noneSelect,
    selected: Selected,
    buttonClicked: buttonClicked,
    buttonClick: buttonClick,
    buttonStyle: buttonStyle,
    innerLine: innerLine,
    innerLineHave: innerLineHave,
    timesBox: timesBox,
    times: times,
    timesResult: timesResult,
    courseList: courseList
}


export default Styles;