import React, { useEffect, useState } from "react";
import { Button, InputGroup, FormControl, Spinner } from "react-bootstrap";
import axios from "axios";
import ReactPaginate from "react-paginate";
import "./index.css";

export const ScopusPage = () => {
  const [keyword, setKeyword] = useState("");
  const [result, setResult] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const itemsPerPage = 10; // Number of results per page

  const onInputChange = (event) => {
    setKeyword(event.target.value);
  };

  const handlePageChange = (selectedPage) => {
    setPage(selectedPage.selected + 1);
  };

  const onSearch = async () => {
    try {
      setLoading(true);
      setResult([]);
      const start = (page - 1) * itemsPerPage;
      const response = await axios.get(
        `https://api.elsevier.com/content/search/scopus?query=${keyword}&count=${itemsPerPage}&start=${start}&apiKey=716138752e96bfba2e969d44c25eac3d`
      );

      if (response.data && response.data["search-results"]) {
        const searchResults = response.data["search-results"];
        const entries = searchResults.entry;
        setResult(entries);
        setLoading(false);
      } else {
        console.log("No search results found.");
      }
    } catch (error) {
      console.log("Error:");
      console.error(error);
    }
  };

  const goToScopusPage = (url) => {
    if (url) {
      window.open(url, "_blank");
    } else {
      console.error("Scopus URL not found in the response.");
    }
  };

  useEffect(() => {
    if (keyword) onSearch();
  }, [page]);

  return (
    <div className="container">
      <div className="row justify-content-center align-items-center">
        <div className="col-12 col-md-8 col-lg-8">
          <div className="d-flex align-items-center w-100 pl-5 ml-3">
            <img className="scopus-logo" src="/scopus.png" alt="Scopus Logo" />
          </div>
          <div className="d-flex align-items-center bg-white px-2 mt-3 scopus-search">
            <div className="d-flex align-items-center mr-2">
              <img
                onClick={onSearch}
                width="50"
                height="50"
                className="p-2"
                src="https://img.icons8.com/ios/50/search--v1.png"
                alt="search--v1"
              />
            </div>
            <InputGroup style={{ flex: 1 }}>
              <FormControl
                value={keyword}
                onChange={onInputChange}
                placeholder="ຄົ້ນຫາ"
                aria-label="Search"
              />
            </InputGroup>
          </div>
        </div>

        {result.length > 0 && (
          <>
            <div className="mt-4">
              {result.map((item, index) => (
                <div key={index} className="bg-white mb-3  py-4 px-3">
                  <div className="row">
                    <h5
                      onClick={() => goToScopusPage(item.link[2]["@href"])}
                      className="scopus-link"
                    >
                      {"Title: " + item["dc:title"]}
                    </h5>
                    <br></br>
                    <p> {"Author: " + item["dc:creator"]}</p>
                    <br></br>
                    <p> {"Publication: " + item["prism:publicationName"]}</p>
                    <br></br>
                    <p>
                      {" "}
                      {"Aggregation Type: " + item["prism:aggregationType"]}
                    </p>
                  </div>
                </div>
              ))}

              <div className="mt-3 mb-5 text-center d-flex justify-content-center w-100">
                <ReactPaginate
                  previousLabel={"ກັບຄຶນ"}
                  nextLabel={"ໄປຕໍ່"}
                  breakLabel={"..."}
                  pageCount={10}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageChange}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                  pageClassName={"custom-pagination-item"}
                />
              </div>
            </div>
          </>
        )}

        {loading && (
          <div className="text-center mt-5 pt-5">
            <div>
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
            <div className="mt-2">
              <p>Loading...</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
