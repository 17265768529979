import React, { useState, useCallback } from 'react'
import './courseAdd.css'
import useReactRouter from 'use-react-router'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import Select from 'react-select'
// import * as yup from 'yup';
import { Formik } from 'formik';
import { useDropzone } from 'react-dropzone'
import {
  Breadcrumb,
  Form,
  Row,
  Col,
} from 'react-bootstrap'

import Consts from '../../consts'
import CourseAddConfirm from './CourseAddConfirm'
import { CustomContainer, Title, CustomButton } from '../../common'

function CourseAdd() {
  const { history, location, match } = useReactRouter()

  // States
  const [showAddConfirmModal, setShowAddConfirmModal] = useState(false)
  const [formParam, setFormParam] = useState({})

  // Set states
  const _handleShowAddConfirmModalClose = () => setShowAddConfirmModal(false)
  const _handleShowAddConfirmModalShow = () => setShowAddConfirmModal(true)

  const _cancel = () => {
  }

  const _add = () => {
    _handleShowAddConfirmModalShow()
  }

  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <div>
      {/* Breadcrumb */}
      <Breadcrumb>
        <Breadcrumb.Item href='' onClick={() => history.push('/course-list')}>
          ຈັດການວິຊາ
        </Breadcrumb.Item>
        <Breadcrumb.Item active>ເພີ່ມວິຊາ</Breadcrumb.Item>
      </Breadcrumb>

      <CustomContainer>
        <Title text='ເພີ່ມວິຊາ' />
        <Formik
          initialValues={{
            title: '',
            courseCode: '',
            description: '',
            note: '',
            teacher: '',
          }}
          validate={values => {
          }}
          onSubmit={(values, { setSubmitting }) => {
            let paramQL = {
              data: {
                title: values.title,
                courseCode: values.courseCode,
                description: values.description,
                note: values.note,
                teacher: {
                  connect: {
                    userId: values.teacher,
                  }
                }
              }
            }
            setFormParam(paramQL)
            _add()
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
              <div>


                {/* Form container */}
                <div style={{ width: '80%', marginLeft: 'auto', marginRight: 'auto' }}>
                  {/* ---------- ຄະນະແລະພາກວິຊາ --------- */}
                  <div style={{ marginBottom: 10 }}>
                    <div>
                      <i
                        className='fa fa-caret-down'
                        aria-hidden='true'
                        style={{ marginRight: 5 }}
                      />
                      ຄະນະແລະພາກວິຊາ
            </div>
                    {/* ຄະນະ */}
                    <Form.Group
                      as={Row}
                      style={{
                        margin: 0,
                        marginBottom: 10,
                        paddingLeft: 20,
                        fontSize: 16
                      }}
                    >
                      <Form.Label column sm='4' className='text-left'>
                        ຄະນະ
                      </Form.Label>
                      <Col sm='8'>
                        <Form.Control as='select'>
                          <option>ວິທະຍາສາດທໍາມະຊາດ</option>
                          <option>ວິທະຍາສາດທໍາມະຊາດ</option>
                          <option>ວິທະຍາສາດທໍາມະຊາດ</option>
                          <option>ວິທະຍາສາດທໍາມະຊາດ</option>
                          <option>ວິທະຍາສາດທໍາມະຊາດ</option>
                        </Form.Control>
                      </Col>
                    </Form.Group>

                    {/* ພາກວິຊາ */}
                    <Form.Group
                      as={Row}
                      style={{
                        margin: 0,
                        marginBottom: 10,
                        paddingLeft: 20,
                        fontSize: 16
                      }}
                    >
                      <Form.Label column sm='4' className='text-left'>
                        ພາກວິຊາ
              </Form.Label>
                      <Col sm='8'>
                        <Form.Control as='select'>
                          <option>ວິທະຍາສາດຄອມພິວເຕີ</option>
                          <option>ວິທະຍາສາດຄອມພິວເຕີ</option>
                        </Form.Control>
                      </Col>
                    </Form.Group>
                  </div>

                  {/* ---------- ປີຮຽນແລະພາກຮຽນ --------- */}
                  <div style={{ marginBottom: 10 }}>
                    <div>
                      <i
                        className='fa fa-caret-down'
                        aria-hidden='true'
                        style={{ marginRight: 5 }}
                      />
                      ປີຮຽນແລະພາກຮຽນ
            </div>
                    {/* ປີຮຽນ */}
                    <Form.Group
                      as={Row}
                      style={{
                        margin: 0,
                        marginBottom: 10,
                        paddingLeft: 20,
                        fontSize: 16
                      }}
                    >
                      <Form.Label column sm='4' className='text-left'>
                        ປີຮຽນ
              </Form.Label>
                      <Col sm='8'>
                        <Form.Control as='select'>
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                        </Form.Control>
                      </Col>
                    </Form.Group>

                    {/* ພາກຮຽນ */}
                    <Form.Group
                      as={Row}
                      style={{
                        margin: 0,
                        marginBottom: 10,
                        paddingLeft: 20,
                        fontSize: 16
                      }}
                    >
                      <Form.Label column sm='4' className='text-left'>
                        ພາກຮຽນ</Form.Label>
                      <Col sm='8'>
                        <Form.Control as='select'>
                          <option>1</option>
                          <option>2</option>
                        </Form.Control>
                      </Col>
                    </Form.Group>
                  </div>

                  {/* ---------- ຂໍ້ມູນວິຊາ --------- */}
                  <div style={{ marginBottom: 10 }}>
                    <div>
                      <i
                        className='fa fa-caret-down'
                        aria-hidden='true'
                        style={{ marginRight: 5 }}
                      />
                      ຂໍ້ມູນວິຊາ
            </div>
                    {/* ຊື່ວິຊາ */}
                    <Form.Group
                      as={Row}
                      style={{
                        margin: 0,
                        marginBottom: 10,
                        paddingLeft: 20,
                        fontSize: 16
                      }}
                    >
                      <Form.Label column sm='4' className='text-left'>
                        ຊື່ວິຊາ</Form.Label>
                      <Col sm='8'>
                        <Form.Control type='text' placeholder='ກະລຸນາປ້ອນ' name="title"
                          value={values.title}
                          onChange={handleChange}
                          isInvalid={!!errors.title} />
                      </Col>
                    </Form.Group>

                    {/* ລະຫັດວິຊາ */}
                    <Form.Group
                      as={Row}
                      style={{
                        margin: 0,
                        marginBottom: 10,
                        paddingLeft: 20,
                        fontSize: 16
                      }}
                    >
                      <Form.Label column sm='4' className='text-left'>
                        ລະຫັດວິຊາ</Form.Label>
                      <Col sm='8'>
                        <Form.Control type='text' placeholder='ກະລຸນາປ້ອນ' name="courseCode"
                          value={values.courseCode}
                          onChange={handleChange}
                          isInvalid={!!errors.courseCode} />
                      </Col>
                    </Form.Group>

                    {/* ຈໍານວນຫນ່ວຍກິດ */}
                    <Form.Group
                      as={Row}
                      style={{
                        margin: 0,
                        marginBottom: 10,
                        paddingLeft: 20,
                        fontSize: 16
                      }}
                    >
                      <Form.Label column sm='4' className='text-left'>
                        ຈໍານວນຫນ່ວຍກິດ
              </Form.Label>
                      <Col sm='8'>
                        <Form.Control as='select'>
                          <option>1</option>
                          <option>2</option>
                        </Form.Control>
                      </Col>
                    </Form.Group>
                  </div>

                  {/* ---------- ຕາຕະລາງມື້ສອນ --------- */}
                  <div style={{ marginBottom: 10 }}>
                    <div>
                      <i
                        className='fa fa-caret-down'
                        aria-hidden='true'
                        style={{ marginRight: 5 }}
                      />
                      ຕາຕະລາງມື້ສອນ
            </div>
                    {/* ວັນ */}
                    <Form.Group
                      as={Row}
                      style={{
                        margin: 0,
                        marginBottom: 10,
                        paddingLeft: 20,
                        fontSize: 16
                      }}
                    >
                      <Form.Label column sm='4' className='text-left'>
                        ວັນ
              </Form.Label>
                      <Col sm='8'>
                        <Form.Control as='select'>
                          <option>Mon</option>
                          <option>Tue</option>
                          <option>Wen</option>
                          <option>Th</option>
                          <option>Fri</option>
                        </Form.Control>
                      </Col>
                    </Form.Group>

                    {/* ຊົ່ວໂມງ */}
                    <Form.Group
                      as={Row}
                      style={{
                        margin: 0,
                        marginBottom: 10,
                        paddingLeft: 20,
                        fontSize: 16
                      }}
                    >
                      <Form.Label column sm='4' className='text-left'>
                        ຊົ່ວໂມງ
              </Form.Label>
                      <Col sm='8'>
                        <Form.Control as='select'>
                          <option>1</option>
                          <option>2</option>
                        </Form.Control>
                      </Col>
                    </Form.Group>
                  </div>

                  {/* ---------- ອາຈານສິດສອນ --------- */}
                  <div style={{ marginBottom: 10 }}>
                    <div>
                      <i
                        className='fa fa-caret-down'
                        aria-hidden='true'
                        style={{ marginRight: 5 }}
                      />
                      ອາຈານສິດສອນ
            </div>
                    {/* ຊື່ອາຈານ */}
                    <Form.Group
                      as={Row}
                      style={{
                        margin: 0,
                        marginBottom: 10,
                        paddingLeft: 20,
                        fontSize: 16
                      }}
                    >
                      <Form.Label column sm='4' className='text-left'>
                        ຊື່ອາຈານ</Form.Label>
                      <Col sm='8'>
                        <Form.Control type='text' placeholder='ກະລຸນາປ້ອນ' name="teacher"
                          value={values.teacher}
                          onChange={handleChange}
                          isInvalid={!!errors.teacher} />
                      </Col>
                    </Form.Group>
                  </div>

                  {/* ---------- ຄໍາອະທິບາຍ --------- */}
                  <div style={{ marginBottom: 10 }}>
                    <div>
                      <i
                        className='fa fa-caret-down'
                        aria-hidden='true'
                        style={{ marginRight: 5 }}
                      />
                      ຄໍາອະທິບາຍ
            </div>
                    {/* ເນື້ອໃນຂອງວິຊາ */}
                    <Form.Group
                      as={Row}
                      style={{
                        margin: 0,
                        marginBottom: 10,
                        paddingLeft: 20,
                        fontSize: 16
                      }}
                    >
                      <Form.Label column sm='4' className='text-left'>
                        ເນື້ອໃນຂອງວິຊາ
              </Form.Label>
                      <Col sm='8'>
                        <Form.Control as='textarea' rows='3' name="description"
                          value={values.description}
                          onChange={handleChange}
                          isInvalid={!!errors.description} />
                      </Col>
                    </Form.Group>
                  </div>

                  {/* ---------- ອັບໂຫລດ --------- */}
                  <div style={{ marginBottom: 10 }}>
                    <div>
                      <i
                        className='fa fa-caret-down'
                        aria-hidden='true'
                        style={{ marginRight: 5 }}
                      />
                      ອັບໂຫລດ
            </div>
                    {/* ອັບໂຫລດໄຟລ */}
                    <Form.Group
                      as={Row}
                      style={{
                        margin: 0,
                        marginBottom: 10,
                        paddingLeft: 20,
                        fontSize: 16
                      }}
                    >
                      <Form.Label column sm='4' className='text-left'>
                        ອັບໂຫລດໄຟລ
              </Form.Label>
                      <Col sm='8'>
                        <div
                          {...getRootProps()}
                          style={{
                            height: 100,
                            border: '1px solid #ddd',
                            outline: 'none',
                            cursor: 'pointer',
                            textAlign: 'center'
                          }}
                        >
                          <input {...getInputProps()} />

                          <div
                            style={{
                              backgroundColor: '#f3f3f3',
                              marginLeft: 20,
                              marginTop: 20,
                              marginRight: 20,
                              textAlign: 'center'
                            }}
                          >
                            <img
                              style={{ width: 50, height: 50 }}
                              src='/assets/download.png'
                            />
                          </div>
                          <span>Drag and drop or Browse</span>
                        </div>
                      </Col>
                    </Form.Group>
                  </div>

                  {/* Buttons */}
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      textAlign: 'center',
                      justifyContent: 'center',
                      marginTop: 40,
                      marginBottom: 40
                    }}
                  >
                    <div style={{ marginRight: 80 }}>
                      <CustomButton title='ຍົກເລີກ' onClick={() => _cancel()} />
                    </div>

                    <CustomButton confirm title='ເພີ່ມວິຊາ' onClick={handleSubmit} />
                  </div>
                </div>

                {/* ------- AddConfirm Modal ------ */}
                <CourseAddConfirm
                  showAddConfirmModal={showAddConfirmModal}
                  _handleShowAddConfirmModalClose={_handleShowAddConfirmModalClose}
                  param={formParam}
                />

              </div>
            )}
        </Formik>
      </CustomContainer>
    </div>
  )
}

export default CourseAdd
