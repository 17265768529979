import React from 'react'
import { Breadcrumb } from "react-bootstrap";
import useReactRouter from "use-react-router";

export default function Emerald() {
  const { history } = useReactRouter();
  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item href="" onClick={() => history.push("/emerald")}>
        ຫ້ອງສະໝຸດທັນສະໄໝ
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="containerEmerald">
        <img className="imgEmerald" src="/assets/imageEmeral.jpg"  width="1000" height="300" />
        <div>
          <a href="https://www.emerald.com/insight/" referrerPolicy="no-referrer-when-downgrade" target="_blank" ><button className="buttonEmerald">ຄົ້ນຫາບົດຄົ້ນຄວ້າ</button></a>
          <div className="Emerald-content">
            <p className="p1" style={{width: 500, marginTop:20}} >Discover Journals, Books & Case Studies</p>
            <p className="p2" style={{width: 500, marginTop:50}}>ແຫຼ່ງຄວາມຮູ້ທີ່ທ່ານສາມາດຄົ້ນຫາບັນດາບົດຄົ້ນຄ້ວາ, ປື້ມ, ໄດ້ຫຼາກຫຼາຍ</p>
            <p className="p3" style={{width: 500, marginTop:100, fontSize:20}}><b>ຮ່ວມມືໂດຍ</b></p>
            <img style={{width: 240, height: 110, marginTop:190}}   src="/assets/emerald.png" alt="Cinque Terre" />
          </div>
        </div>
      </div>
    </div>
  )
}



